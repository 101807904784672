<div class="container__head">
    <img src="{{imgsrc}}" alt="{{alt}}"><!--logo-->
    <span>{{title}}</span><!--title-->
    <div *ngIf="title=='Processes'" class="more-info">
        <i (click)="opProcesses.show($event)" class="pi pi-info-circle"></i>
    </div>
    <div *ngIf="title=='Allies'" class="more-info">
        <i (click)="opAllies.show($event)" class="pi pi-info-circle"></i>
    </div>
</div>

<p-overlayPanel styleClass="dialog-processes-info" [dismissable]="true" #opProcesses>
    <ng-template pTemplate="content">
        <div class="info-content">
            <p >
                <span style="font-weight: bolder; text-shadow: 1px 0 #888888; color: black;">Informacion a tener en cuenta:</span>
            </p>
            <p>
                <span style="font-weight: bolder; text-shadow: 1px 0 #888888; color: black;">Sugar Free</span> Aseo sugar free en líneas de refinación y fabricación (Tiempo 16h) - Purga con 1 tonelada de licor y 400 Kg de Cobertura.
            </p>
            <p>
                <span style="font-weight: bolder; text-shadow: 1px 0 #888888; color: black;">Orgánico</span> Se debe realizar purga con cacao orgánico desde línea de tostión, generalmente se cargan 1500 Kg de licor orgánico como barredura dentro de la orden. Limpieza en línea Buhler.
            </p>
            <p>
                <span style="font-weight: bolder; text-shadow: 1px 0 #888888; color: black;">Dairy Free</span> Líneas que aplica: Buhler 1; Chocomaster; Sandvik; Creme600.
            </p>
            <p>
                <span style="font-weight: bolder; text-shadow: 1px 0 #888888; color: black;">Blancos</span> | Coberturas blancas reales se deben costear por líneas diferentes a Sandvik y Hacos1300. Preparación en Wiecon50 / Wineroto 2 e inyección Hacos100 / Cataluña (GR700).
            </p>
            <p>
                <span style="font-weight: bolder; text-shadow: 1px 0 #888888; color: black;">Sucedaneos</span> Se recomienda revisar con Ingeniero de Procesos de acuerdo con el caso, líneas de preparación e inyección diferentes a las genéricas para coberturas reales.
            </p>
        </div>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel styleClass="dialog-processes-info" [style]="{'max-width':'400px'}" [dismissable]="true" #opAllies>
    <ng-template pTemplate="content">
        <div class="info-content">
            <p>
                Remember, the costs are per kilo of finished product.
            </p>
        </div>
    </ng-template>
</p-overlayPanel>