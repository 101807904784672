import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { ExcessService } from '@core/services/home/excess/excess.service';
import { MacroeconomicsService } from '@core/services/home/macroeconomics/macroeconomics.service';
import { PackagingService } from '@core/services/home/packaging/packaging.service';
import { ProcessesService } from '@core/services/home/processes/processes.service';
import { RandDService } from '@core/services/home/rand-d/rand-d.service';
import { getProjectIdOnLocal, updateEditedStep } from '@core/utils';
import { lastValueFrom, tap } from 'rxjs';

@Component({
  selector: 'app-form-select-table-excess',
  templateUrl: './form-select-table.component.html',
  styleUrls: ['./form-select-table.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class FormSelectTableExcessComponent implements OnInit {
  /**
   * The table headers list.
   */
  @Input() public columns: any[];
  @Input() public type: string="packaging";
  @Input() packagingTotal: any;
  @Input() canUpdate = false;
  // @Input() rawMaterialTotal?: number;
  @Output() changePackagingValue = new EventEmitter<any>();

  /**
   * The row with the form.
   */
  public tableForm: FormGroup;

  /**
   * The dropdown list for the select field.
   */
  textList: any[]=[];
  projectId=""
  dolar = 0;
  euro = 0;

  /**
   * The dropdown list for the select field.
   */
  public psList: any[];

  materialsList:any=[]

  initial=true

  constructor(private formBuilder: FormBuilder,
    private packagingService:PackagingService,
    private processesService:ProcessesService,
    private r_andDService:RandDService,
    private excessService:ExcessService,
    private _decimalPipe: DecimalPipe,
    private macroeconomicsService: MacroeconomicsService,
    ) {
    this.columns = [];
    // this.textList = [
    // ];
    this.psList = [
      {
        label: 'Yes',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ];
    this.tableForm = this.formBuilder.group({
      form_array: this.formBuilder.array([
        // this.formBuilder.group(this.generateFormGroupFields())
      ])
    });
  }

  async ngOnInit() {
    this.getProjectId()
    await this.getDollarTRM()
    await this.setMaterialsList()
    if (this.type=='packaging') {
      await this.IsExcessPackagingListUpToDate()
      await this.setPackagingMaterials()
    } else {
      await this.IsExcessRawMaterialListUpToDate()
      await this.setRawMaterials()
    }
    this.initial = false
    
  }

  async getDollarTRM(){
    let macroeconomicsInfoOnLocal = this.macroeconomicsService.getMacroeconomicsValues()
    if (macroeconomicsInfoOnLocal==null) {
      let macroeconomicsInfoFromApi = await this.getMacroeconomicsInfo(this.projectId)
      if (macroeconomicsInfoFromApi!=null) {
        this.dolar = macroeconomicsInfoFromApi.dolar || 0
        this.euro = macroeconomicsInfoFromApi.euro || 0
      } 
    } else {
      this.dolar= +(macroeconomicsInfoOnLocal.currencies.dolar || 0)
      this.euro= +(macroeconomicsInfoOnLocal.currencies.euro || 0)
  
    }
  }
  
  async getMacroeconomicsInfo(projectId:any){
    return await lastValueFrom(this.macroeconomicsService.getMacroeconomicsInfo(projectId).pipe(
      (tap( response => {
  
      }
  
      ))
        ))
  }

  getProjectId(){
    this.projectId = getProjectIdOnLocal()
  }

  async setPackagingMaterials(){
    if(this.materialsList.length==0){
      //  this.addNewRow()
  
    } else {

      for (let index = 0; index < this.materialsList.length; index++) {
        await this.addNewRow()
        const element = this.materialsList[index];
        await this.setPackagingMaterialInfoInRow(index, element)
  
        }

      let excessValues = this.excessService.getExcessValuesOnLocalStorage()
      excessValues.packaging_list = this.materialsList
      this.excessService.saveExcessValuesOnLocalStorage(excessValues)
    }

  }

  async IsExcessPackagingListUpToDate(){
    let packagingValues = await this.getPackagingInfo(this.projectId) 
    let createPackagingList = packagingValues!=null?packagingValues.create_packaging:[]
    let materialsListBackup = this.materialsList
    for (let indexAPIList = 0; indexAPIList < createPackagingList.length; indexAPIList++) {
      const element = createPackagingList[indexAPIList];
      const found = materialsListBackup.find((obj:any) => {
        return obj.sapCode === element.sapCode;
      });
      
      // console.log(found); // 👉️ {id: 1, name: 'Alfred'}
      
      if (found !== undefined) {
        createPackagingList[indexAPIList].will_use = found.will_use??false
      } else {
        createPackagingList[indexAPIList].will_use = false
      }
    }

    this.materialsList = createPackagingList
    let excessValues = this.excessService.getExcessValuesOnLocalStorage()
    excessValues.packaging_list = this.materialsList
    this.excessService.saveExcessValuesOnLocalStorage(excessValues)
  }

  async IsExcessRawMaterialListUpToDate(){
    let rawMaterialsValues = await this.getRandDInfo(this.projectId) 
    let rawMaterialsList = []
    if (rawMaterialsValues!=null) {
    let materialsListBackup = this.materialsList
    for (let index = 0; index < rawMaterialsValues.materials_list.length; index++) {
      const element = rawMaterialsValues.materials_list[index];
      if(element.typeRawMaterial=="Materia Prima 2"){
        rawMaterialsList.push(element)
      }
    }
    for (let indexAPIList = 0; indexAPIList < rawMaterialsList.length; indexAPIList++) {
      const element = rawMaterialsList[indexAPIList];
      const found = materialsListBackup.find((obj:any) => {
        return obj.sapCode === element.sapCode;
      });
      
      // console.log(found); // 👉️ {id: 1, name: 'Alfred'}
      
      if (found !== undefined) {
        rawMaterialsList[indexAPIList].will_use = found.will_use??false
      } else {
        rawMaterialsList[indexAPIList].will_use = false
      }
    }

  }
    this.materialsList = rawMaterialsList
    let excessValues = this.excessService.getExcessValuesOnLocalStorage()
    excessValues.raw_materials = this.materialsList
    this.excessService.saveExcessValuesOnLocalStorage(excessValues)


  }

  // async getRawMaterialList(){
  //   let r_andDValues = this.r_andDService.getRand_DValuesOnLocalStorage()
  //   let rawMaterialsList = r_andDValues.materials_list
  //   for (let index = 0; index < rawMaterialsList.length; index++) {
  //     const element = rawMaterialsList[index];
  //     if(element.typeRawMaterial=="Materia Prima 2"){
  //       this.materialsList.push(element)
  //     }
  //   }
  //   let excessValues = this.excessService.getExcessValuesOnLocalStorage()
  //   excessValues.raw_materials = this.materialsList
  //   this.excessService.saveExcessValuesOnLocalStorage(excessValues)
  // }

  async setRawMaterials(){
    if(this.materialsList.length==0){
      //  this.addNewRow()
  
    } else {

      for (let index = 0; index < this.materialsList.length; index++) {
        await this.addNewRow()
        const element = this.materialsList[index];
        await this.setRawMaterialInfoInRow(index, element)
  
      }
      let excessValues = this.excessService.getExcessValuesOnLocalStorage()
      excessValues.raw_materials = this.materialsList
      this.excessService.saveExcessValuesOnLocalStorage(excessValues)
    }
  }

  async setRawMaterialInfoInRow(index:number, materialInfo:any){
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    formControls.patchValue({
      will_use: materialInfo.will_use!=null?materialInfo.will_use:false,
      code: materialInfo.sapCode??materialInfo.codigoSap,
      material_text: materialInfo.nombreMaterial|| materialInfo.nombre,
      moq: this._decimalPipe.transform(materialInfo.cantidad_min_reg_info||0, '1.0'),
      unit: materialInfo.ump,
      consumption_qty: this._decimalPipe.transform(materialInfo.consumption_qty, '1.0'),
      cost_per_umb: this._decimalPipe.transform(materialInfo.precio_neto, materialInfo.moneda=="USD"?"1.0-2":'1.0-0'),
      currency: materialInfo.moneda,
      });
      this.calculateScrap(index)
      this.calculateTotalScrap(index)
      if(formControls.value.will_use){
        this.changeTotalScrapsTotal(index)

      }
  }

  async setPackagingMaterialInfoInRow(index:number, materialInfo:any){
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    formControls.patchValue({
      will_use: materialInfo.will_use!=null?materialInfo.will_use:false,
      code: materialInfo.sapCode,
      material_text: materialInfo.rawMaterial||materialInfo.description,
      moq: this._decimalPipe.transform(materialInfo.moq||0, '1.0-0'),
      unit: materialInfo.ump||materialInfo.umb,
      consumption_qty: this._decimalPipe.transform(materialInfo.consumption_qty, '1.0-0'),
      cost_per_umb: materialInfo.moneda=="USD"?this._decimalPipe.transform((materialInfo.costUnd||0), '1.2-2'):this._decimalPipe.transform((materialInfo.costUnd||0), '1.0-0'),
      currency: materialInfo.moneda,
      });
      this.calculateScrap(index)
      this.calculateTotalScrap(index)
      if(formControls.value.will_use){
        this.changeTotalScrapsTotal(index)

      }
    }

    convertNumberWithCommasToNumber(quantity:any){
      if (quantity=='') return 0
      return parseFloat(quantity.replace(/,/g, ''))
     //  this._decimalPipe.transform(, '1.0')
     }

    calculateScrap(index:number){
      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]

      let currentMOQ = this.convertNumberWithCommasToNumber(formControls.value.moq||0)
      let currentConcumptionQty = this.convertNumberWithCommasToNumber(formControls.value.consumption_qty)
      let scrapResult = currentMOQ - currentConcumptionQty
      formControls.patchValue({
        scrap: this._decimalPipe.transform((scrapResult<0?0:scrapResult), '1.0'),
        });

    }

    calculateTotalScrap(index:number){
      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]

      let currentScrap = this.convertNumberWithCommasToNumber(formControls.value.scrap)
      let currentCost_per_umb = this.materialsList[index].costUnd||this.materialsList[index].precio_neto
      formControls.patchValue({
        total_scrap: this._decimalPipe.transform(((currentScrap * currentCost_per_umb)*(formControls.value.currency=="USD"?this.dolar:formControls.value.currency=="EUR"?this.euro:1)), '1.0-0')
        });

    }
  
  


  /**
   * Generates the form fields to be added to the form array.
   *
   * @private
   */
  public generateFormGroupFields(): Object {
    return {
      will_use: '',
      code: '',
      material_text: '',
      moq: '',
      unit: '',
      consumption_qty: '',
      scrap: '',
      total_scrap: '',
      cost_per_umb: '',
      currency: '',
    };
  }

  /**
   * Gets the form array.
   */
  public getFormArray(): FormArray {
    return this.tableForm.get('form_array') as FormArray;
  }

  /**
   * Gets the list of form arrays.
   *
   * @param row
   */
  public getFormArrayControls(row: any): string[] {
    return Object.keys(row.controls);
  }

  /**
   * Adds a new row to the table.
   */
  public addNewRow(): void {
    const formGroup = this.formBuilder.group(this.generateFormGroupFields());

    this.getFormArray().push(formGroup);
  }

  /**
   * Calculates the subtotal.
   */
  // TODO: Calculate this.
  public calculateSubtotal(): number {
    return 110;
  }

  /**
   * Removes the given index row from the table.
   *
   * @param index
   */
  public removeRow(index: number): void {
    this.getFormArray().controls.splice(index, 1);
  }


  changeTotalScrapsTotal(index:number): void {
    if (!this.initial  && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let willUseValue = formControls.value.will_use
    let totalScrap = this.convertNumberWithCommasToNumber(formControls.value.total_scrap)||0
    // console.log(totalScrap, "toal scrap")
    let excessValues = this.excessService.getExcessValuesOnLocalStorage()
    if (this.type=='packaging') {
      this.materialsList[index].will_use = willUseValue
      excessValues.packaging_list = this.materialsList
      this.excessService.saveExcessValuesOnLocalStorage(excessValues)
    } else {
      this.materialsList[index].will_use = willUseValue
      excessValues.raw_materials = this.materialsList
      this.excessService.saveExcessValuesOnLocalStorage(excessValues)

    }
    if (willUseValue) {
      this.changePackagingValue.emit({target:this.type, value:Math.round(totalScrap*1)});
      
    } else {
      this.changePackagingValue.emit({target:this.type, value:Math.round(totalScrap*-1)});
    }
  }

  async getExcessInfo(projectID:any){
    return await lastValueFrom(this.excessService.getExcessInfo(projectID).pipe(
      (tap( response => {
      }
  
      ))
        ))
  
  }

  async getRandDInfo(projectID:any){
    return await lastValueFrom(this.r_andDService.getRandDInfo(projectID).pipe(
      (tap( response => {
        
      }
  
      ))
        ))
  
  }
  async getPackagingInfo(projectID:any){
    return await lastValueFrom(this.packagingService.getPackagingInfo(projectID).pipe(
      (tap( response => {
        
      }
  
      ))
        ))
  
  }
  
  async getMaterialList(projectId:any){
    let excessInfoFromAPI = await this.getExcessInfo(projectId)

    if (excessInfoFromAPI!=null) {
      if ((this.type=="packaging" && excessInfoFromAPI.packaging_list==null)) {
        return []
      }
      if ((this.type=="raw_material" && excessInfoFromAPI.raw_materials==null)) {
        return []
      }
      return this.type=="packaging"?excessInfoFromAPI.packaging_list:excessInfoFromAPI.raw_materials
    } 
    return []
  }

  async setMaterialsList(){
    this.materialsList = await this.getMaterialList(this.projectId);
    
  }
}
