import { Component } from '@angular/core';
import { PackagingService } from '@core/services/home/packaging/packaging.service';
import { RandDService } from '@core/services/home/rand-d/rand-d.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-filter-materials',
  templateUrl: './filter-materials.component.html',
  styleUrls: ['./filter-materials.component.scss']
})
export class FilterMaterialsComponent {

  description: any="";

  name: any="";

  results: any= [];

  sapCodeSelected: any;

  typeConsult: any= "Ingrediente";

  typeRawMaterial: string = "";

  chocolateSelected: boolean = false;

  timeout: any = null;

  typeMaterials:any = []

  showSpinner = false

  constructor(
    private rand_dService: RandDService,
    private packagingService: PackagingService,
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.chocolateSelected = this.config.data.chocolateSelected
    this.typeConsult = this.config.data.consult??"Ingrediente"
    this.typeMaterials = [
      {label:'Materia Prima 1', value:"Materia Prima 1"},
      {label:'Materia Prima 2', value:"Materia Prima 2"},
      {label:'Chocolate', value:"Chocolate", disabled: this.chocolateSelected},
    ]
  }

  ngOnInit(): void {
    // this.getTypeConsult();
    this.typeRawMaterial="Materia Prima 1";

  }

  getTypeConsult() {
    // this.typeConsult = JSON.parse(localStorage.getItem('tipoConsulta') || '{}');
    // this.typeRawMaterial = JSON.parse(localStorage.getItem('tipoMateriaPrima') || '{}');
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.filterRawMaterials();
      }
    }, 1000);
  }

  filterRawMaterials() {
    this.results = [];
    this.showSpinner = true
    if (this.typeConsult === 'Empaque') {
      if(this.description!=""){
        this.packagingService.filterPackaging(this.description.toUpperCase()).subscribe(
          (data: any) => {
            this.results = data;
            this.showSpinner = false
            // console.log(this.results)
          },
          (error) => {
            this.showSpinner = false
          }
        );
      }
    } else {

      if(this.description!=""){
        this.rand_dService.filterRawMaterials(this.description.toUpperCase(),this.typeRawMaterial).subscribe(
        (data: any) => {
          this.results = data;
          if (this.typeRawMaterial=="Materia Prima 1") {
            this.results = this.results.reduce((unique: any, o: any) => {
              if (!unique.some((obj: any) => obj.componente === o.componente)) {
                unique.push(o);
              }
              this.showSpinner = false
              return unique;
            }, []);

          } 
          this.showSpinner = false
          //console.log(this.results)
        },
        (error) => {
          this.showSpinner = false
        }
      );
    } else {
      this.showSpinner = false
    }
    
  }


  }

  setSelectedFalse() {
    for (const element of this.results) {
      element.selected = false;
    }
  }

  selectResult(index: any) {
    this.setSelectedFalse();
    this.results[index].selected = true;

    if (this.typeConsult === 'Ingrediente') {
      if (this.typeRawMaterial === 'Materia Prima 1') {
        this.sapCodeSelected = this.results[index].componente;
      } else if (this.typeRawMaterial === 'Chocolate') {
        this.sapCodeSelected = this.results[index].productoTerminado;
        this.name = this.results[index].texto
      } else {
        this.sapCodeSelected = this.results[index].material;
      }
    } else {
      this.sapCodeSelected = this.results[index].codigoSap;
    }
  }

  confirm() {
    let result = this.sapCodeSelected;
    this.ref.close([result, this.typeRawMaterial, this.name])
  }

  closeModal() {
    this.ref.close([]);
  }
}
