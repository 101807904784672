<div class="form-table-container">
  <small *ngIf="showMaterialsError"
          class="input-error">
      There must be at least one item in table.
  </small>
  <small *ngIf="showPercentageError"
          class="input-error">
          Total percentage must be 100%.
  </small>
  <small *ngIf="showOverweightScrapsError"
          class="input-error">
          Overweight and Scraps fields must be filled with values higher than zero.
  </small>
    <div class="form-table">
      <table aria-describedby="form table">
        <thead>
          <tr>
            <th id="header-{{ header.name.toLowerCase() }}" scope="col" *ngFor="let header of columns">{{ header.name }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container [formGroup]="tableForm">
            <ng-container formArrayName="form_array">
              <tr [formGroupName]="iForm" *ngFor="let form of getFormArray().controls; let iForm = index;" id="row-{{ iForm }}">
                <td *ngIf="canFillScraps==false">
                  <div class="row-actions">

                      <img *ngIf="materialsList.length>0 && !materialsList[iForm].chocolate" src="assets/icons/trash-can.svg" width="17" height="17" alt="Remove" (click)="removeRow(iForm)">
                      <img *ngIf="materialsList.length>0 && !materialsList[iForm].chocolate && !(materialsList[iForm].typeRawMaterial == 'Chocolate')" src="assets/icons/magnifier.svg" width="27" height="27" alt="Search" (click)="showFilterPopUp(iForm)">
                      <img *ngIf="materialsList.length>0 && !materialsList[iForm].chocolate && materialsList[iForm].typeRawMaterial == 'Chocolate' && !materialsList[iForm].newRow" src="assets/icons/magnifier.svg" style="opacity:0.4;filter:alpha(opacity=100);" width="27" height="27" alt="Search">
                      <img *ngIf="materialsList.length>0 && !materialsList[iForm].chocolate && materialsList[iForm].typeRawMaterial == 'Chocolate' && materialsList[iForm].newRow" src="assets/icons/magnifier.svg" width="27" height="27" alt="Search" (click)="showFilterPopUp(iForm)">
                      <img *ngIf="materialsList.length>0 && materialsList[iForm].chocolate" src="assets/icons/trash-can.svg" style="opacity:0.4;filter:alpha(opacity=100);" width="17" height="17" alt="Remove">
                      <img *ngIf="materialsList.length>0 && materialsList[iForm].chocolate" src="assets/icons/magnifier.svg" style="opacity:0.4;filter:alpha(opacity=100);" width="27" height="27" alt="Search" >
                      <img *ngIf="materialsList.length==0" src="assets/icons/trash-can.svg" width="17" height="17" alt="Remove" (click)="removeRow(iForm)">
                      <img *ngIf="materialsList.length==0" src="assets/icons/magnifier.svg" width="27" height="27" alt="Search" (click)="showFilterPopUp(iForm)">
                  </div>
                </td>
                <td *ngFor="let property of getFormArrayControls(form); let i = index;">
                  <div>
                    <div *ngIf="materialsList.length==0">
                      <input [readonly]="true" type="text" pInputText placeholder="" [formControlName]="property" id="input-{{ property }}">
                    </div>
                    <div *ngIf="materialsList.length>0">
                      <input *ngIf="property!='percentage' && 
                      property!='overweight' && property!='scrap' && 
                      property != 'raw_material_type' && 
                      property != 'raw_material_type' && 
                      property != 'code' && 
                      property != 'category' &&
                      property != 'description' && 
                      property != 'supplier' &&
                      property != 'currency' && 
                      property != 'umb' &&
                      property != 'cost_per_kg' &&
                      property != '$_per_kg_formula' &&
                      property != 'moq'
                      " 
                      type="text" pInputText placeholder="" [readonly]="true" [formControlName]="property" id="input-{{ property }}">
                      <input *ngIf="
                      property == '$_per_kg_formula'
                      " 
                      pTooltip="This value includes the overweight cost." tooltipPosition="top"
                      type="text" pInputText placeholder="" [readonly]="true" [formControlName]="property" id="input-{{ property }}">
                      <input *ngIf="
                      (property == 'category' ||
                      property == 'description' ||
                      property == 'supplier' ||
                      property == 'currency' || 
                      property == 'umb' ||
                      property == 'cost_per_kg')
                      && (!materialsList[iForm].newRow || canFillScraps)
                      " 
                      type="text" pInputText placeholder="" [readonly]="true" [formControlName]="property" id="input-{{ property}}">
                      <input *ngIf="
                      (property == 'category' ||
                      property == 'description' ||
                      property == 'supplier' ||
                      property == 'currency' || 
                      property == 'umb')
                      && materialsList[iForm].newRow
                      && !canFillScraps
                      " 
                      class="white-background" 
                      (ngModelChange)="updateValuesInNewRow(iForm)"
                      type="text" pInputText placeholder="Enter info" [readonly]="false" [formControlName]="property" id="input-{{ property }}">
                      <input *ngIf="
                      (property == 'cost_per_kg')
                      && materialsList[iForm].newRow
                      " 
                      class="white-background" 
                      (ngModelChange)="updateValuesInNewRowAndCalculate(iForm)"
                      type="text" pInputText placeholder="Enter info" [readonly]="false" [formControlName]="property" id="input-{{ property }}">
                      <input *ngIf="property=='percentage' && materialsList[iForm].typeRawMaterial!='Chocolate' && materialsList[iForm].chocolate" [readonly]="true" pInputText placeholder="" [formControlName]="property" id="input-{{ property }}">
                      <input *ngIf="property=='percentage' && materialsList[iForm].typeRawMaterial!='Chocolate' && !materialsList[iForm].chocolate" [ngClass]="this.canFillScraps?'':'white-background'" appThreeDigitDecimaNumber [readonly]="this.canFillScraps" pInputText placeholder="Enter info" [formControlName]="property" (ngModelChange)="calculatePrices(iForm)" id="input-{{ property }}">
                      <input *ngIf="property=='percentage' && materialsList[iForm].typeRawMaterial=='Chocolate'" [ngClass]="this.canFillScraps?'':'white-background'" type="text" [min]="0" [max]="100" appThreeDigitDecimaNumber [readonly]="this.canFillScraps" pInputText placeholder="Enter info" [formControlName]="property" (ngModelChange)="calculatePrices(iForm)" id="input-{{ property }}">
                      
                      <input *ngIf="property=='overweight' && materialsList[iForm].typeRawMaterial!='Chocolate'" [ngClass]="this.canFillScraps?'white-background':''" [readonly]="!this.canFillScraps" appThreeDigitDecimaNumber type="text" [min]="0" pInputText [placeholder]="this.canFillScraps?'Enter info':''" [formControlName]="property" (ngModelChange)="addOverweight(iForm)" id="input-{{ property }}">
                      <input *ngIf="property=='overweight' && materialsList[iForm].typeRawMaterial=='Chocolate'"  [readonly]="true" type="text" pInputText placeholder="" [formControlName]="property" (ngModelChange)="addOverweight(iForm)" id="input-{{ property }}">
                      <input *ngIf="property=='scrap' && materialsList[iForm].typeRawMaterial!='Chocolate'" [ngClass]="this.canFillScraps?'white-background':''" [readonly]="!this.canFillScraps" appThreeDigitDecimaNumber type="text" [min]="0" pInputText [placeholder]="this.canFillScraps?'Enter info':''" [formControlName]="property" (ngModelChange)="addScrap(iForm)" id="input-{{ property }}" pTooltip="Kg de barredura o producto que se pierde, debe llenarlo el área de procesos." tooltipPosition="top">
                      <input *ngIf="property=='scrap' && materialsList[iForm].typeRawMaterial=='Chocolate'" [readonly]="true" type="text" pInputText placeholder="" [formControlName]="property" (ngModelChange)="addScrap(iForm)" id="input-{{ property }}">
                      <!-- <div class="container__dropdown"> -->
                        <p-dropdown
                        *ngIf="property === 'raw_material_type' && materialsList[iForm].newRow && !chocolateSelected && !canFillScraps"
                        pTooltip="{{materialsList[iForm].typeRawMaterial == 'Materia Prima 1'?'Liquor, Butter and cocoa powder.':
                        materialsList[iForm].typeRawMaterial == 'Materia Prima 2'?'Other ingredients, sugar, milk, etc...':
                        materialsList[iForm].typeRawMaterial == 'Chocolate'?'Select an existent formula, e.g. Sombra, Cocoa lecitinada.':'Select a material type.'}}" tooltipPosition="top"
                        [options]="materialOptions"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Select"
                        (ngModelChange)="refreshTypeRawMaterial(iForm)"
                        [formControlName]="property"
                        [overlayOptions]="{ mode: 'overlay', appendTo: 'body' }"
                        ></p-dropdown>
                        <p-dropdown
                        *ngIf="property === 'raw_material_type' && materialsList[iForm].newRow && chocolateSelected"
                        pTooltip="{{materialsList[iForm].typeRawMaterial == 'Materia Prima 1'?'Liquor, Butter and cocoa powder.':
                        materialsList[iForm].typeRawMaterial == 'Materia Prima 2'?'Other ingredients, sugar, milk, etc...':'Select a material type.'}}" tooltipPosition="top"
                        [options]="materialOptionsWithOutChocolate"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Select"
                        (ngModelChange)="refreshTypeRawMaterial(iForm)"
                        [formControlName]="property"
                        [overlayOptions]="{ mode: 'overlay', appendTo: 'body' }"
                        ></p-dropdown>
                      <!-- </div> -->
                      <!-- <input *ngIf="property === 'raw_material_type' && materialsList[iForm].newRow" type="text" pInputText placeholder="Enter info" [readonly]="true" [formControlName]="property" id="input-{{ property }}"> -->
                      <input *ngIf="property === 'raw_material_type' && (!materialsList[iForm].newRow || canFillScraps)" type="text" pInputText placeholder="Enter info" [readonly]="true" [formControlName]="property" id="input-{{ property }}">
                      <input *ngIf="property === 'code' && (!materialsList[iForm].newRow || canFillScraps)" type="text" pInputText placeholder="Enter info" [readonly]="true" [formControlName]="property" id="input-{{ property }}">
                      <input class="white-background" *ngIf="property === 'code' && materialsList[iForm].newRow && !canFillScraps" type="text" [maxlength]="7" pInputText placeholder="Enter info" (blur)="searchRawMaterialOnLostFocus(iForm)" (ngModelChange)="searchRawMaterial(iForm)" [formControlName]="property" id="input-{{ property }}">
                      <input class="white-background" *ngIf="property === 'moq' && materialsList[iForm].typeRawMaterial!='Chocolate' && materialsList[iForm].typeRawMaterial!='Materia Prima 1' && !canFillScraps" type="text" [maxlength]="7" pInputText placeholder="Enter info" (ngModelChange)="updateMOQ(iForm)" [formControlName]="property" id="input-{{ property }}">
                      
                    </div>
                  </div>
                </td>
              </tr>
              <tr></tr>
              <tr>
                <td [colSpan]="this.canFillScraps?4:5">
                  <strong>Subtotal</strong>
                  
                </td>
                <td>
                  <div>{{ totalPercentage }}</div>
                  
                </td>
                <td>
                  <div>{{ subtotal|number:'1.0':'en-US' }}</div>
                  
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td *ngIf="!this.canFillScraps"></td>
                <td *ngIf="!this.canFillScraps"></td>
                <td *ngIf="!this.canFillScraps"></td>
                <td *ngIf="!this.canFillScraps"></td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!canFillScraps" style="margin-top: 7px;"> <Span>* To enter a material without SAP code, please write “0” in the Code column</Span></div>
  <button *ngIf="!canFillScraps" class="add-row-button" (click)="addNewRow()">Add New Row</button>
  