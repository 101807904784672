import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessUnitsService } from '@core/services/home/business_units/business_units.service';
import { FoldersService } from '@core/services/home/folders/folders.service';
import { GetUserDetail, GetUserId } from '@core/utils';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel'; 
import { lastValueFrom, tap } from 'rxjs';

@Component({
  selector: 'app-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss'],
  providers: [MessageService, ConfirmationService]

})
export class ClientCardComponent {

  folder_id=""
  constructor(
    private router: Router,
    private businessUnitsService: BusinessUnitsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
    ){
      this.checkUserPermissions()
  }
  @Input()
  _id: string = '';
  @Input()
  business_unit: string = '';
  @Input()
  is_active: boolean = true;
  @Input()
  createdAt: string = '';
  @Input()
  name: string = '';

  canDeleteBusinessUnits=["nadie"]
  allowedToDeleteClient=false
  user_details:any={}
  success=true
  

  checkUserPermissions(){
    this.user_details = GetUserDetail()
    if (this.canDeleteBusinessUnits.includes(this.user_details.tipo)) {
      this.allowedToDeleteClient=true
    }
  }


  goToBusinessUnit(){
    let businessUnitValues = {
      _id: this._id,
      name: this.name
    }
    this.businessUnitsService.saveBusinessUnitValuesOnLocalStorage(businessUnitValues)
    this.router.navigate([`all-folders`,this._id, this.business_unit]);
    
  }
}
