import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MacroeconomicsService } from '@core/services/home/macroeconomics/macroeconomics.service';
import { PackagingService } from '@core/services/home/packaging/packaging.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterMaterialsComponent } from '../filter-materials/filter-materials.component';
import { lastValueFrom, tap } from 'rxjs';
import { getProjectIdOnLocal, updateEditedStep } from '@core/utils';
import { ProjectBriefService } from '@core/services/home/project-brief/projectBrief.service';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-create-packaging-table',
  templateUrl: './create-packaging-table.component.html',
  styleUrls: ['./create-packaging-table.component.scss']
})
export class CreatePackagingTableComponent implements OnInit{
 /**
   * The table headers list.
   */
 @Input() public columns: any[];

 @Input() canUpdate = false;

 @Output() updateExpiredItems = new EventEmitter<any>();

 @Output() updateTotalCost = new EventEmitter<any>();

 @Output() createPackagingTableDone = new EventEmitter<any>();

 dolarTRM=0
 euro=0
 /**
  * The row with the form.
  */
 public tableForm: FormGroup;
 currentIndex=0;
 ref: DynamicDialogRef | undefined;

 subtotal=0
 totalFormula=0
  showPackagingError = false
  showRequiredFieldsError = false
  initial=true
  gramajeProjectBackUp = 0
  unitsQuantityBackUp = 0
  sales_volume=0
 materialsList:any=[]
 materialsListInitialBackup:any=undefined
 constructor(private formBuilder: FormBuilder,
  public dialogService: DialogService,
  private macroeconomicsService: MacroeconomicsService,
  private packagingService: PackagingService,
  private projectBriefService: ProjectBriefService,
  private _decimalPipe: DecimalPipe,
    ) {
   this.columns = [];
   this.tableForm = this.formBuilder.group({
     form_array: this.formBuilder.array([
      //  this.formBuilder.group(this.generateFormGroupFields())
     ])
   });
 }

 projectId=""

 async ngOnInit() {
  this.getSalesVolume()
  this.getProjectId()
  await this.getDollarTRM()
  
 }

 async getDollarTRM(){
  let macroeconomicsInfoOnLocal = this.macroeconomicsService.getMacroeconomicsValues()
  if (macroeconomicsInfoOnLocal==null) {
    let macroeconomicsInfoFromApi = await this.getMacroeconomicsInfo(this.projectId)
    if (macroeconomicsInfoFromApi!=null) {
      this.dolarTRM = macroeconomicsInfoFromApi.dolar || 0
      this.euro = macroeconomicsInfoFromApi.euro || 0
    } 
  } else {
    this.dolarTRM= +(macroeconomicsInfoOnLocal.currencies.dolar || 0)
    this.euro= +(macroeconomicsInfoOnLocal.currencies.euro || 0)

  }
}

async getMacroeconomicsInfo(projectId:any){
  return await lastValueFrom(this.macroeconomicsService.getMacroeconomicsInfo(projectId).pipe(
    (tap( response => {

    }

    ))
      ))
}


 getSalesVolume(){
  this.sales_volume= (+this.projectBriefService.getCurrentProjectBriefValues().basic_info.sales_volume)??0
}

 getProjectId(){
  this.projectId = getProjectIdOnLocal()
}

 async getPackagingInfo(projectID:any){
  return await lastValueFrom(this.packagingService.getPackagingInfo(projectID).pipe(
    (tap( response => {
      
    }

    ))
      ))

}

async getMaterialList(projectId:any){
  let packagingInfoFromAPI = await this.getPackagingInfo(projectId)
  if (packagingInfoFromAPI==null) {
    return []
  }
  this.gramajeProjectBackUp = packagingInfoFromAPI.weight
  this.unitsQuantityBackUp = packagingInfoFromAPI.unitsQuantity
  if (packagingInfoFromAPI!=null) {
    return packagingInfoFromAPI.create_packaging
  } 
  return []
}

  async refreshTypeRawMaterial(index:number){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
  let formArray = this.getFormArray().controls;
  let formControls = formArray[index]
  let sapCode:string = formControls.value.code
  if (this.materialsList[index].newRow) {
    this.refreshNewRowValuesToMaterialList(index, formControls)
    let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
    packagingValues.create_packaging = this.materialsList;
    this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
    if (sapCode.length>6) {
      this.materialsList[index].newRow = false
      setTimeout( () => {
        this.getMaterialInfo(index, sapCode)}, 300);
        // this.getRawMaterialInfo(index, sapCode, typeRawMaterial)
      }
    }
  }

  updateMOQ(index:number){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let moq = +(formControls.value.moq||0)
    this.materialsList[index].moq = moq
    let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
    if (packagingValues==null) return;
    packagingValues.create_packaging = this.materialsList;
    this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
  }

  refreshObservations(index:number){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
  let formControls = formArray[index]
    let observations = formControls.value.observations
    this.materialsList[index].observations = observations
    let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
    if (packagingValues==null) return;
    packagingValues.create_packaging = this.materialsList;
    this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
  }

  refreshNewRowValuesToMaterialList(index:number, formControls:any){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    // let observations = formControls.value.observations
    let description = formControls.value.description
    let category = formControls.value.category
    let rawMaterial = formControls.value.view_material_info
    let formula = formControls.value.formula
    let currency = formControls.value.currency
    let gramaje = formControls.value.weight
    let costUnd = formControls.value.cost_per_umb
    // let scrap_percentage = formControls.value.scrap_percentage
    let umb = formControls.value.umb
    let area = formControls.value.area
 
    // this.materialsList[index].observations = observations
    this.materialsList[index].description = description
    this.materialsList[index].category = category
    this.materialsList[index].rawMaterial = rawMaterial
    this.materialsList[index].formula = formula
    this.materialsList[index].area = area
    this.materialsList[index].costUnd = costUnd!=""?(+costUnd):costUnd
    this.materialsList[index].gramaje = gramaje
    this.materialsList[index].moneda = currency
    this.materialsList[index].umb = umb
    // this.materialsList[index].scrap_percentage = scrap_percentage

    

  }

 async setMaterials(){
  let materialsListFromApi = await this.getMaterialList(this.projectId);
  if(materialsListFromApi.length==0 || materialsListFromApi==null){
     this.addNewRow()

  } else {
    this.materialsListInitialBackup = materialsListFromApi
    for (let index = 0; index < materialsListFromApi.length; index++) {
      await this.addNewRow()
      this.materialsList[index].newRow=false
      const element = materialsListFromApi[index];
      await this.getMaterialInfo(index, element.sapCode)
      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]
      formControls.patchValue({
        code: element.sapCode,
         formula: element.formula,
         scrap: element.scrap_percentage??0,
         moq: element.moq??"",
         area: element.area??"",
         weight: element.gramaje??"",
         observations: element.observations
        });
        this.materialsList[index].area = +(element.area||0)
        this.materialsList[index].gramaje = +(element.gramaje||0)
        this.materialsList[index].formula =+(element.formula||0)
        this.saveValuesOnLocalStorage()
        await this.calculatePrices(index)
        await this.addScrap(index)
      }

    }
    this.materialsListInitialBackup = undefined
    this.initial=false
    this.createPackagingTableDone.emit(false);
    // await this.updateCosts()
}
 

 showFilterPopUp(index:number) {
  this.materialsList[index].newRow=false
  this.currentIndex = index;
  this.ref = this.dialogService.open(FilterMaterialsComponent, {
      data: {
        consult:"Empaque"
      },
      showHeader: false,
      styleClass: 'filter-materials-popup-dialog',
      //header: 'Select a Product'
  });

  this.ref.onClose.subscribe(async (result: any) => {
    if (result.length>0) {
        //this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: product.name });
        await this.removeRow(index)
        await this.addNewRow()
        this.getMaterialInfo(index, result[0])


    } else {
      this.materialsList[index].newRow=true
    }
  })
}


 /**
  * Generates the form fields to be added to the form array.
  *
  * @private
  */
 public generateFormGroupFields(): Object {
   return {
     code: '',
     description: '',
     formula: '',
     weight: '',
     area: '',
     cost_per_kg: '0',
     category: '',
     umb: '',
     cost_per_umb: '0',
     moq: '',
     currency: '',
     scrap: '',
     additional_scrap: '',
     observations: '',
     consumption_qty: ''
   };
 }

 /**
  * Gets the form array.
  */
 public getFormArray(): FormArray {
   return this.tableForm.get('form_array') as FormArray;
 }

 /**
  * Gets the list of form arrays.
  *
  * @param row
  */
 public getFormArrayControls(row: any): string[] {
   return Object.keys(row.controls);
 }

 /**
  * Adds a new row to the table.
  */
 async addNewRow() {
  if (!this.initial && this.canUpdate) {
    updateEditedStep(true)
  }

  if (this.materialsList.length<6) {
    
   const formGroup = this.formBuilder.group(this.generateFormGroupFields());

   await this.materialsList.push({
     nombre_proveedor:"",
     valido_de:"",
     status_reg_info:"",
     dias_entrega_reg_info:"",
     cantidad_min_reg_info:0,
     reg_info:"",
     costUnd:0,
     por:"",
     inco1:"",
     inco2:"",
     factorKg:0,
     factor:0,
     cantidad_escala:0,
     importe_escala:0,
     ump:"",
     moneda:"",
     nombreMaterial:"",
     categoria:"",
     subcategoria:"",
     toolbox:true,
     typeRawMaterial:"",
     newRow:true,
     txt_gr_articulo:""
   })
    await this.getFormArray().push(formGroup);
    }
    this.checkMaterialsQuantity()
  }

 /**
  * Calculates the subtotal.
  */
 // TODO: Calculate this.
 public calculateSubtotal(): number {
   return 110;
 }

 /**
  * Removes the given index row from the table.
  *
  * @param index
  */
 async removeRow(index: number) {
  if (!this.initial && this.canUpdate) {
    updateEditedStep(true)
  }
  //  this.getFormArray().controls.splice(index, 1);

   if (this.materialsList.length>0) {
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let currentCostKgPrice = formControls.value.cost_per_kg!=0?this.convertNumberWithCommasToNumber(formControls.value.cost_per_kg):0
    let currentFormula = (+formControls.value.formula)??0
    this.subtotal=this.subtotal-currentCostKgPrice;
    // this.totalFormula=this.totalFormula-currentFormula;
    
    await this.getFormArray().controls.splice(index, 1);
    await this.materialsList.splice(index, 1)
    
    this.totalFormula= await this.calculateFormula();
    let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
    if (packagingValues) {
      packagingValues.create_packaging = this.materialsList;
      this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
      await this.updateCosts();
    }
    this.checkMaterialsQuantity()
    this.updateExpiredItems.emit();
    return;
  }
 }

 async calculateFormula(){
  if (!this.initial && this.canUpdate) {
    updateEditedStep(true)
  }
  let totalFormula = 1
  let formArray = this.getFormArray().controls;
  for (let index = 0; index < this.materialsList.length; index++) {
    const element = this.materialsList[index];
    // let formControls = formArray[index]
    let currentFormula = +(element.formula??1)
    totalFormula = totalFormula * (currentFormula==0?1:currentFormula)
  }

  return +totalFormula.toFixed(2)
 }

  getRecentAndMostExpensiveMaterial(materials: any, dolarPrice:number, euroPrice:number) {
    const currentDateTime = new Date()
    let precio_neto = 0
    let indexMaterial = null
    for (let index = 0; index < materials.length; index++) {
      const material = materials[index];

      var timestamp = Date.parse(material.valido_hasta+" 23:59:59");
      const valido_hasta = new Date(timestamp);
      const formatted_valido_de = material.valido_de.slice(0, 4) + "-" + material.valido_de.slice(4, 6) + "-" + material.valido_de.slice(6)
      timestamp = Date.parse(formatted_valido_de+" 00:00:00");
      const valido_de = new Date(timestamp);
      const precio_neto_material = (parseFloat(material.precio_neto) / parseFloat(material.por)) * (1 + (material.factor || 0));
      const currency = material.moneda
      const final_precio_neto = precio_neto_material*(currency=="USD"?dolarPrice:currency=="EUR"?euroPrice:1);
      if (currentDateTime>=valido_de && currentDateTime<=valido_hasta && precio_neto<final_precio_neto) {
        precio_neto = final_precio_neto
        indexMaterial = index
      }
      
    }

    if (indexMaterial!=null) return indexMaterial.toString()

    let mostRecentValidSince = Math.max.apply(
      Math,
      materials?.map(function (emp: any) {
        return emp.valido_de||"0";
      })
    );
    return mostRecentValidSince;
  }

  async getMaterialInfo(index:number, sapCode:string){
    let materialInfoFromAPI = await this.getMaterialInfoBySapCode(sapCode)
    let materialInfo = materialInfoFromAPI
    if(materialInfo.length>0){
        //This is for selecting the more expensive material
      // let registerId = ""
      // let mostRecentValidSince:any =this.getRecentAndMostExpensiveMaterial(materialInfo, this.dolarTRM, this.euro)
      // if (typeof mostRecentValidSince == "string") {
      //   materialInfo = [materialInfoFromAPI[+mostRecentValidSince]];
      // } else {
      //   materialInfo = materialInfo.filter((x: any) => x.valido_de == mostRecentValidSince);
      //   if (materialInfo.length > 0) {
      //     registerId = materialInfo[0].reg_info;
      //   }
      //   materialInfo = materialInfo.filter((x: any) => x.reg_info == registerId);
      // }

      // if (materialInfo.length==0) {
      //   materialInfo = materialInfoFromAPI
      // }
      this.materialsList[index].sapCode = sapCode;
      this.materialsList[index].rawMaterial = materialInfo[0].nombreMaterial;
      this.materialsList[index].supplier = materialInfo[0].provider || 'LUKER';
      this.materialsList[index].moneda = materialInfo[0].moneda || 'COP';
      this.materialsList[index].expired = materialInfo[0].status_reg_info;
      this.materialsList[index].costUnd =(parseFloat(materialInfo[0]?.precio_neto) / parseFloat(materialInfo[0]?.por)) * (1 + (materialInfo[0]?.factor || 0));
      this.materialsList[index].sapCode = sapCode
      this.materialsList[index].ump = materialInfo[0].ump
      this.materialsList[index].reg_info = materialInfo[0].reg_info
      this.materialsList[index].nombre_proveedor = materialInfo[0].nombre_proveedor
      this.materialsList[index].inco1 = materialInfo[0].inco1
      this.materialsList[index].inco2 = materialInfo[0].inco2
      this.materialsList[index].valido_de = materialInfo[0].valido_de
      this.materialsList[index].cantidad_min_reg_info = materialInfo[0].cantidad_min_reg_info
      this.materialsList[index].moq = materialInfo[0].cantidad_min_reg_info
      this.materialsList[index].valido_hasta = materialInfo[0].valido_hasta
      this.materialsList[index].categoria = materialInfo[0].txt_gr_articulo
      let getBySapCodeInfo:any = await this.getBySapCode(sapCode)
      if(getBySapCodeInfo[0]!=undefined){
        this.materialsList[index].gramaje = getBySapCodeInfo[0].gramajeEstructura || 0;
        this.materialsList[index].area = getBySapCodeInfo[0].area || 0;
      } else {
        this.materialsList[index].gramaje = 0;
        this.materialsList[index].area = 0;

      }
        if(this.materialsListInitialBackup!=undefined){
          this.materialsList[index].observations = this.materialsListInitialBackup[index].observations??""
          this.materialsList[index].moq = this.materialsListInitialBackup[index].moq??this.materialsListInitialBackup[index].cantidad_min_reg_info
          this.materialsList[index].costUnd = +(this.materialsListInitialBackup[index].costUnd||0)
          this.materialsList[index].moneda = this.materialsListInitialBackup[index].moneda
          // this.materialsList[index].scrap_percentage = this.materialsListInitialBackup[index].scrap_percentage??""
          // this.materialsList[index].formula = this.materialsListInitialBackup[index].formula??""
        }
        await this.saveValuesOnLocalStorage()
        let formArray = this.getFormArray().controls;
        let formControls = formArray[index]
        formControls.patchValue({
          code: this.materialsList[index].sapCode,
          description: this.materialsList[index].rawMaterial || this.materialsList[index].description,
        //  formula: this.materialsList[index].percentage,
         weight: this.materialsList[index].gramaje,
         area: this.materialsList[index].area,
         cost_per_kg: '0',
         category: this.materialsList[index].categoria,
         umb: this.materialsList[index].ump||this.materialsList[index].umb,
         cost_per_umb: this._decimalPipe.transform(this.materialsList[index].costUnd, this.materialsList[index].moneda=="COP"?'1.0-0':'1.2-2'),
         moq: this.materialsList[index].moq,
         currency: this.materialsList[index].moneda,
         scrap: this.materialsList[index].scrap_percentage,
        //  additional_scrap: [''],
         observations: this.materialsList[index].observations
        });
        this.updateMOQ(index);

      
    } else {
      if(this.materialsListInitialBackup!=undefined){
        this.materialsList[index].observations = this.materialsListInitialBackup[index].observations??""
        this.materialsList[index].rawMaterial = this.materialsListInitialBackup[index].rawMaterial??""
        this.materialsList[index].supplier = this.materialsListInitialBackup[index].supplier??""
        this.materialsList[index].moneda = this.materialsListInitialBackup[index].moneda??""
        this.materialsList[index].expired = this.materialsListInitialBackup[index].expired??""
        this.materialsList[index].costUnd = +(this.materialsListInitialBackup[index].costUnd||0)
        this.materialsList[index].sapCode = this.materialsListInitialBackup[index].sapCode??"NO CODE"
        this.materialsList[index].gramaje = +(this.materialsListInitialBackup[index].gramaje||0)
        this.materialsList[index].area = this.materialsListInitialBackup[index].area??""
        this.materialsList[index].observations = this.materialsListInitialBackup[index].observations??""
        this.materialsList[index].description = this.materialsListInitialBackup[index].description??""
        this.materialsList[index].category = this.materialsListInitialBackup[index].category??""
        this.materialsList[index].umb = (this.materialsListInitialBackup[index].ump||this.materialsListInitialBackup[index].umb)??""
        this.materialsList[index].reg_info = this.materialsListInitialBackup[index].reg_info??""
        this.materialsList[index].nombre_proveedor = this.materialsListInitialBackup[index].nombre_proveedor??""
        this.materialsList[index].inco1 = this.materialsListInitialBackup[index].inco1??""
        this.materialsList[index].inco2 = this.materialsListInitialBackup[index].inco2??""
        this.materialsList[index].valido_de = this.materialsListInitialBackup[index].valido_de??""
        this.materialsList[index].moq = this.materialsListInitialBackup[index].moq??""
        this.materialsList[index].valido_hasta = this.materialsListInitialBackup[index].valido_hasta??""
        this.materialsList[index].categoria = this.materialsListInitialBackup[index].categoria??""
        this.saveValuesOnLocalStorage()
        let formArray = this.getFormArray().controls;
        let formControls = formArray[index]
        formControls.patchValue({
        code: this.materialsList[index].sapCode,
        description:this.materialsList[index].rawMaterial || this.materialsList[index].description,
        weight: this.materialsList[index].gramaje,
        area: this.materialsList[index].area,
        cost_per_kg: '0',
        category: this.materialsList[index].categoria||this.materialsList[index].category,
        umb: this.materialsList[index].umb,
        cost_per_umb: this.materialsList[index].costUnd,
        moq: this.materialsList[index].moq,
        currency: this.materialsList[index].moneda,
        additional_scrap: '0',
        observations: this.materialsList[index].observations
        });
        this.updateMOQ(index);
      } else {
        this.materialsList[index].sapCode="NO COD"
        let formArray = this.getFormArray().controls;
        let formControls = formArray[index]
        formControls.patchValue(
          {
            code: "NO COD",
          }
          )
      }
      this.materialsList[index].newRow = true
    }

    this.updateExpiredItems.emit();
    
  }

  async getMaterialInfoBySapCode(sapCode:any){
      return await lastValueFrom(this.packagingService.getMaterialgBySapCode(sapCode).pipe(
        (
          tap( response => {
          })
        )
      ))
  }
  async getBySapCode(sapCode:any){
      return await lastValueFrom(this.packagingService.getBySapCode(sapCode).pipe(
        (
          tap( response => {
          })
        )
      ))
  }

  async getMOQMaterial(reg_info:any){
    return await lastValueFrom(this.packagingService.getExtraMaterialInfoBySapCode(reg_info).pipe(
      (tap( response => {
        
      }

      ))
        ))

  }

  async updateArea(index:number){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let area = (+formControls.value.area)??0
    this.materialsList[index].area = area
    this.saveValuesOnLocalStorage()
  }

  async updateWeight(index:number){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let weight = (+formControls.value.weight)??0
    this.materialsList[index].gramaje = weight
    this.saveValuesOnLocalStorage()
  }

  async calculatePrices(index:number){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let formula = (+formControls.value.formula)??0
    let formulaCalculo = formula;
    let currentcostKg = this.convertNumberWithCommasToNumber(formControls.value.cost_per_kg==null?"0":formControls.value.cost_per_kg.toString())
    let packagingValuesLocalStorage = this.packagingService.getPackagingValuesOnLocalStorage();
    if (packagingValuesLocalStorage==null) {
      return;
    } 
    let createPackagingList = packagingValuesLocalStorage.create_packaging
    let gramajeProject = this.initial==true?this.gramajeProjectBackUp:packagingValuesLocalStorage.basic_info.weight
    let gramajeMaterial = (+formControls.value.weight) ?? 0
    let unitsQuantity = this.initial==true?this.unitsQuantityBackUp:packagingValuesLocalStorage.basic_info.unitsQuantity
    let currentScrapPercentage = (this.materialsList[index].scrap_percentage)??0
    if (unitsQuantity==0) {
      unitsQuantity=this.sales_volume*1000/gramajeProject
    }
    let area = (+formControls.value.area)??0
    let scrap = formControls.value.scrap==undefined?0:((+formControls.value.scrap)??0)
    let costUnd = (+this.materialsList[index].costUnd)??0
    let initialFormula = 0
    let initial = false
    let consumptionQuantity=0
    let nTimesFormulaResult = formula
    if(this.materialsListInitialBackup!=undefined){
      initial=true
      initialFormula = this.materialsListInitialBackup[index].formula
    }
    // if (formula>100){
    //   setTimeout( () => {
    //     formControls.patchValue({
    //       formula: 100,
    //     });}, 500);
    //     formula = 100
    //   }
      // if(formula==0 && !initial){
      //   this.subtotal = this.subtotal - (currentcostKg??0)
      //   this.subtotal = this.subtotal<0?0:this.subtotal
      //   this.totalFormula = this.totalFormula=await this.calculateFormula();
      //   // this.totalFormula = this.totalFormula - (this.materialsList[index].formula??0)
      //   this.materialsList[index].formula = formula
      //   this.saveValuesOnLocalStorage()
      //   formControls.patchValue({
      //     cost_per_kg: 0,
      //     });
      // }
      // if (this.totalFormula>0 && !initial) {
      //   this.totalFormula = this.totalFormula - (this.materialsList[index].formula??0)
      // }
      if (index>0 && formulaCalculo!=0) {
        for (let indexLoop = 0; indexLoop < index; indexLoop++) {
          if (createPackagingList[indexLoop].formula==0 || createPackagingList[indexLoop].formula=="" || createPackagingList[indexLoop].formula==null || createPackagingList[indexLoop].formula==undefined) {
            formulaCalculo=0
            return;
          }
          formulaCalculo = formulaCalculo * createPackagingList[indexLoop].formula;
        }
      }
    if (gramajeProject>0 && gramajeMaterial>0 && area>0 && formula>0) {
      this.subtotal = this.subtotal - currentcostKg
      let costKg =((gramajeMaterial * area) / (gramajeProject * formulaCalculo)) * costUnd * (this.materialsList[index].moneda=="USD"?this.dolarTRM:this.materialsList[index].moneda=="EUR"?this.euro:1);
      costKg = Math.round(costKg)
      if(index-1>=0){
        for (let indexCreatePakagingList = index-1; indexCreatePakagingList >= 0 ; indexCreatePakagingList--) {
          const element = createPackagingList[indexCreatePakagingList];
          nTimesFormulaResult = element.formula * nTimesFormulaResult
        }
      }
      consumptionQuantity = ((unitsQuantity/(nTimesFormulaResult*1000/(area*gramajeMaterial)))*(1+(scrap/100)))
      const currentScrapPrice= ((currentScrapPercentage*costKg)/100)
      this.subtotal = this.subtotal + costKg + currentScrapPrice
      this.materialsList[index].costKg = costKg
      this.materialsList[index].formula = formula
      this.materialsList[index].consumption_qty = consumptionQuantity
      this.materialsList[index].priceKgWithScrap = costKg + currentScrapPrice
      // this.totalFormula=this.totalFormula+formula
      this.saveValuesOnLocalStorage()
      formControls.patchValue({
        cost_per_kg: this._decimalPipe.transform((costKg+currentScrapPrice), '1.0-0'),
        consumption_qty: this._decimalPipe.transform(consumptionQuantity, '1.0-0')
        });
        this.totalFormula=await this.calculateFormula();
    }
    if (formula==0){
      this.materialsList[index].formula = formula
      this.subtotal = this.subtotal - currentcostKg
      // this.subtotal = this.subtotal - (this.materialsList[index].costKg??0)
      this.materialsList[index].costKg = 0
      this.materialsList[index].consumption_qty = 0
      this.materialsList[index].priceKgWithScrap = 0
      formControls.patchValue({
        cost_per_kg: 0,
        consumption_qty: 0
        });
      this.totalFormula=await this.calculateFormula();
      this.saveValuesOnLocalStorage()
    }
    if (!initial || createPackagingList.length==(index+1)) {
      this.updateTotalCost.emit();
    }
  }

  async saveValuesOnLocalStorage(){
    let packagingValues = await this.packagingService.getPackagingValuesOnLocalStorage();
    if (packagingValues==null) return;
    packagingValues.create_packaging = this.materialsList;
    this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  async addScrap(index:any){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let costKg = Math.round(this.materialsList[index].costKg??0)
    let previousCostWithoutScrap = (this.materialsList[index].costKg)??0
    let previousScrapPercentage = (this.materialsList[index].scrap_percentage)??0
    let previousScrapPrice = Math.round((previousScrapPercentage*previousCostWithoutScrap)/100)
    let scrapPercentage = (+formControls.value.scrap)??0
    let materialFormula = (+formControls.value.formula)??0
    if(costKg>0){
      let scrapPrice = Math.round((scrapPercentage*costKg)/100)
      this.materialsList[index].scrap_percentage = scrapPercentage
      let priceKgWithScrap = costKg + Math.round(scrapPrice)
      this.materialsList[index].priceKgWithScrap =  priceKgWithScrap
      formControls.patchValue({
        additional_scrap: this._decimalPipe.transform(Math.round(scrapPrice),'1.0-0'),
        cost_per_kg: this._decimalPipe.transform(priceKgWithScrap, '1.0-0')
      })
      let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
      packagingValues.create_packaging = this.materialsList;
      this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
      this.subtotal = this.subtotal - (previousCostWithoutScrap + previousScrapPrice) + priceKgWithScrap

    } else {
      this.materialsList[index].priceKgWithScrap = 0
      let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
      if (packagingValues==null) return;
      packagingValues.create_packaging = this.materialsList;
      this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
    }
  }

  searchMaterialOnLostFocus(index:number) {
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }

    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let sapCode:string = formControls.value.code
    if (this.materialsList[index].newRow) {
      this.refreshNewRowValuesToMaterialList(index, formControls)
      let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
      packagingValues.create_packaging = this.materialsList;
      this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
      
      if (sapCode.length>0 && this.materialsList[index].newRow) {
        this.materialsList[index].newRow = false
        setTimeout( () => {
          this.getMaterialInfo(index, sapCode)}, 300);
          // this.getRawMaterialInfo(index, sapCode, typeRawMaterial)
        }
      }
}

async updateCosts(){
  if (!this.initial && this.canUpdate) {
    updateEditedStep(true)
  }
  let formArray = this.getFormArray().controls;
  let packagingValuesLocalStorage = this.packagingService.getPackagingValuesOnLocalStorage();
  let createPackagingList = packagingValuesLocalStorage.create_packaging
  for (let index = 0; index < createPackagingList.length; index++) {
    const element = createPackagingList[index];
    let currentScrapPercentage = (element.scrap_percentage)??0
    let formControls = formArray[index]
    if (formControls==undefined) return
    let formula = (+formControls.value.formula)??0
    let formulaCalculo = formula
    let currentcostKg = this.convertNumberWithCommasToNumber(formControls.value.cost_per_kg!=null?formControls.value.cost_per_kg.toString():"0")
    let gramajeProject = this.initial==true?this.gramajeProjectBackUp:packagingValuesLocalStorage.basic_info.weight
    let gramajeMaterial = (+formControls.value.weight) ?? 0
    let unitsQuantity = this.initial==true?this.unitsQuantityBackUp:packagingValuesLocalStorage.basic_info.unitsQuantity
    if (unitsQuantity==0) {
      unitsQuantity=(this.sales_volume*1000/gramajeProject)
    }
    let area = (+formControls.value.area)??0
    let scrap = formControls.value.scrap==undefined?0:((+formControls.value.scrap)??0)
    let costUnd = (+(this.materialsList[index].costUnd??0))
    let initialFormula = 0
    let initial = false
    let consumptionQuantity=0
    let nTimesFormulaResult = formula
    if(this.materialsListInitialBackup!=undefined){
      initial=true
      initialFormula = this.materialsListInitialBackup[index].formula
    }
    if (index>0 && formulaCalculo!=0) {
      for (let indexLoop = 0; indexLoop < index; indexLoop++) {
        if (createPackagingList[indexLoop].formula==0 || createPackagingList[indexLoop].formula=="" || createPackagingList[indexLoop].formula==null || createPackagingList[indexLoop].formula==undefined) {
          formulaCalculo=0
          return;
        }
        formulaCalculo = formulaCalculo * createPackagingList[indexLoop].formula;
      }
    }

    if (gramajeProject>0 && gramajeMaterial>0 && area>0 && formula>0) {
      this.subtotal = this.subtotal - currentcostKg
      let costKg =((gramajeMaterial * area) / (gramajeProject * formulaCalculo)) * costUnd * (this.materialsList[index].moneda=="USD"?this.dolarTRM:this.materialsList[index].moneda=="EUR"?this.euro:1);
      costKg = Math.round(costKg)
      if(index-1>=0){
        for (let indexCreatePakagingList = index-1; indexCreatePakagingList >= 0 ; indexCreatePakagingList--) {
          const element = createPackagingList[indexCreatePakagingList];
          nTimesFormulaResult = element.formula * nTimesFormulaResult
        }
      }
      consumptionQuantity = ((unitsQuantity/(nTimesFormulaResult*1000/(area*gramajeMaterial)))*(1+(scrap/100)))
      const currentScrapPrice= Math.round((currentScrapPercentage*costKg)/100)
      this.subtotal = this.subtotal + costKg + currentScrapPrice
      this.materialsList[index].costKg = costKg
      this.materialsList[index].formula = formula
      this.materialsList[index].consumption_qty = consumptionQuantity
      this.materialsList[index].priceKgWithScrap = costKg + currentScrapPrice
      // this.totalFormula=this.totalFormula+formula
      this.saveValuesOnLocalStorage()
      formControls.patchValue({
        cost_per_kg: this._decimalPipe.transform((costKg + currentScrapPrice), '1.0-0'),
        consumption_qty: this._decimalPipe.transform(consumptionQuantity, '1.0-0')
        });
        this.totalFormula=await this.calculateFormula();
    }
    if (formula==0){
      this.materialsList[index].formula = formula
      this.subtotal = this.subtotal - currentcostKg
      // this.subtotal = this.subtotal - (this.materialsList[index].costKg??0)
      this.materialsList[index].costKg = 0
      this.materialsList[index].consumption_qty = 0
      this.materialsList[index].priceKgWithScrap = 0
      formControls.patchValue({
        cost_per_kg: 0,
        consumption_qty: 0
        });
      this.totalFormula=await this.calculateFormula();
      this.saveValuesOnLocalStorage()
    }
    if (!initial) {
      this.updateTotalCost.emit();
    }
  }

   
}

checkMaterialsQuantity(){
  if (this.initial) {
    return;
  }
  let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
  let materialsList = packagingValues.create_packaging 
  if (materialsList.length<1) {
    this.showPackagingError = true
  } else {
    this.showPackagingError = false
  }
}

async checkRequiredFields(){
  let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
  let materialsList = packagingValues.create_packaging 
  for (let material of materialsList) {
    const description = material.description || material.rawMaterial ||""
    const costUmb = material.costUnd
    const MOQ = +(material.moq||0)
    const Quantity = +(material.formula||0)
    const weight = +(material.gramaje||0)
    const area = +(material.area||0)
    if (description == "" || typeof costUmb == "string" || !(MOQ>0) || !(Quantity>0) || !(weight>0) || !(area>0)) {
      this.showRequiredFieldsError = true
      return false
    } 
  }
  this.showRequiredFieldsError = false
  return true
}

convertNumberWithCommasToNumber(quantity:string){
  return parseFloat(quantity.replace(/,/g, ''))
 //  this._decimalPipe.transform(, '1.0')
 }

 async autofillPackagingCombinations(packages:any){

  if (this.materialsList.length) {
    for (let index = (this.materialsList.length-1); index >= 0; index--) {
      this.removeRow(index);
    }
  }
  for (let index = 0; index < packages.length; index++) {
    if (index==6) {
      break;
    };
    await this.addNewRow()
    const element = packages[index];
    this.materialsList[index].newRow=false
    await this.getMaterialInfo(index, element.codigoSap)
    let formArray = await this.getFormArray().controls;
    let formControls = formArray[index]
    let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
    packagingValues.create_packaging = this.materialsList;
    formControls.patchValue({
      formula: +(element.unitsPerPackage??0),
    });
    this.materialsList[index].formula=(+(element.unitsPerPackage??0))
    this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
    await this.calculatePrices(index)
    }
 }

}
