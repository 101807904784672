import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stage-card',
  templateUrl: './stage-card.component.html',
  styleUrls: ['./stage-card.component.scss'],
})
export class StageCardComponent implements OnInit {
  @Input()
  state: string = '';
  @Input()
  route: string = '';
  @Input()
  stageName: string = '';
  @Input()
  id: string = '';
  @Input()
  svgPath: string = '';
  @Input()
  alt: string = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
    // const currentCard = this.id;
    // const currentRoute = this.router.url.split('/');
    // if (currentRoute.slice(0, -1).includes(currentCard)) {
    //   this.state = 'Completed';
    // } else {
    //   this.state = 'Incomplete';
    // }
    // if (currentCard == currentRoute[currentRoute.length - 1]) {
    //   this.state = 'In Progress';
    // }
  }
}
