import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-copy-link',
  templateUrl: './copy-link.component.html',
  styleUrls: ['./copy-link.component.scss']
})
export class CopyLinkComponent implements OnInit{

  @Input()
  _id: string = '';
  @Input()
  _id_2: string = '';
  @Input()
  query: String = "";

  @Input()
  linkType: string = '';

  link=""
  linkCopied=false
  constructor(private clipboard: Clipboard){
  }

  ngOnInit(): void {
    if (this.linkType=="folder") {
      this.link=`${window.location.origin}/inside-folder/${this._id}`
    } else if(this.linkType=="project"){
      this.link=`${window.location.origin}/project-brief/${this._id}`
    } else if(this.linkType=="business_unit"){
      this.link=`${window.location.origin}/clients/${this._id}`
    } else if(this.linkType=="group"){
      this.link=`${window.location.origin}/business-units${this.query}`
    } else if(this.linkType=="client"){
      this.link=`${window.location.origin}/all-folders/${this._id}/${this._id_2}`
    } 
  }

  copyLinkToClipboard(): void {
    const sucessfull = this.clipboard.copy(this.link);
    this.linkCopied=sucessfull
}
}
