import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-view-projects-excesses',
  templateUrl: './view-projects-excesses.component.html',
  styleUrls: ['./view-projects-excesses.component.scss']
})
export class ViewProjectsExcessesComponent implements OnInit {

  rawMaterialList = []
  packagingList = []
  constructor(private config: DynamicDialogConfig){
    this.rawMaterialList = config.data.rawMaterials
    this.packagingList = config.data.packaging
  }

  ngOnInit(): void {
  }

}
