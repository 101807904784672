import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GetUserDetail } from '@core/utils';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss'],
  providers: [MessageService, ConfirmationService]

})
export class GroupCardComponent {

  folder_id=""
  constructor(
    private router: Router,
    ){
      this.checkUserPermissions()
  }
  @Input()
  _id: string = '';
  @Input()
  is_active: boolean = true;
  @Input()
  params: any = {};
  @Input()
  name: string = '';
  @Input()
  query: string = '';

  canDeleteBusinessUnits=["nadie"]
  // canDeleteBusinessUnits=["ADMIN", "SUPERADMIN"]
  allowedToDeleBusinessUnit=false
  user_details:any={}
  success=true

  checkUserPermissions(){
    this.user_details = GetUserDetail()
    if (this.canDeleteBusinessUnits.includes(this.user_details.tipo)) {
      this.allowedToDeleBusinessUnit=true
    }
  }

  goToGroup(){
    this.router.navigate([`business_units`], { queryParams: this.params});
  }
}
