<!-- Table with PrimeNG (With duplication bug) -->
<!--<p-table [value]="getFormArray().controls" [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-sm">-->
<!--  <ng-template pTemplate="header">-->
<!--    <tr>-->
<!--      <th scope="col" *ngFor="let header of tableHeaders">{{ header.name }}</th>-->
<!--    </tr>-->
<!--  </ng-template>-->
<!--  <ng-template pTemplate="body" let-row [formGroup]="tableForm">-->
<!--    <ng-container formArrayName="form_array">-->
<!--      <tr [formGroupName]="i" *ngFor="let form of getFormArray().controls; let i = index;" id="row-{{ i }}">-->
<!--        <td>-->
<!--          <div class="row-actions">-->
<!--            <img src="assets/icons/trash-can.svg" width="14" height="14" alt="Remove">-->
<!--            <img src="assets/icons/magnifier.svg" width="24" height="24" alt="Search">-->
<!--          </div>-->
<!--        </td>-->
<!--        <td *ngFor="let property of getFormArrayControls(form); let i = index;">-->
<!--          <input type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">-->
<!--        </td>-->
<!--      </tr>-->
<!--    </ng-container>-->
<!--  </ng-template>-->
<!--</p-table>-->

<!-- Native table -->
<div class="form-table-container">
  <div class="form-table">
    <table aria-describedby="form table">
      <thead>
        <tr>
          <th scope="col" *ngFor="let header of columns">{{ header.name }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container [formGroup]="tableForm">
          <ng-container formArrayName="form_array">
            <tr [formGroupName]="i" *ngFor="let form of getFormArray().controls; let i = index;" id="row-{{ i }}">
              <td>
                <div class="row-actions">
                  <img src="assets/icons/trash-can.svg" width="14" height="14" alt="Remove" (click)="removeRow(i)">
                  <img src="assets/icons/magnifier.svg" width="24" height="24" alt="Search">
                </div>
              </td>
              <td *ngFor="let property of getFormArrayControls(form); let i = index;">
                <div>
                  <input type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
    <div class="form-table-subtotal">
      <div>
        <strong>Subtotal</strong>
      </div>
      <div>{{ calculateSubtotal() }}</div>
    </div>
  </div>
</div>
<button class="add-row-button" (click)="addNewRow()">Add New Row</button>
