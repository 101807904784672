import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectBriefService } from '@core/services/home/project-brief/projectBrief.service';
import { saveCurrentVersionOnLocal, saveProjectIdOnLocal } from '@core/utils';
import { lastValueFrom, tap } from 'rxjs';

@Component({
  selector: 'app-project-redirect',
  templateUrl: './project-redirect.component.html',
  styleUrls: ['./project-redirect.component.scss']
})
export class ProjectRedirectComponent {

  projectId=""
  constructor(
    private route:ActivatedRoute, 
    private router: Router,
    private projectBriefService:ProjectBriefService){
    this.projectId=this.route.snapshot.paramMap.get("projectId")||""
  }

  async ngOnInit() {
    await this.getProjectBriefInfoFromApi()
    this.projectId=this.route.snapshot.paramMap.get("projectId")||""
    saveProjectIdOnLocal(this.projectId)
    this.router.navigate([`home/project-brief`]);
  }

  //necesito un endpoint que me traiga la cantidad de versiones de un projectBrief

  async getProjectBriefInfoFromApi(){
    return await lastValueFrom(this.projectBriefService.getProjectBrief(this.projectId).pipe(
      (tap( response => {
        saveCurrentVersionOnLocal({current_version:response.version, total_versions:response.version})
      
    }

      ))
        ))

  }

}
