import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stage-card-mobile',
  templateUrl: './stage-card-mobile.component.html',
  styleUrls: ['./stage-card-mobile.component.scss'],
})
export class StageCardMobileComponent implements OnInit {
  @Input()
  state: string = '';
  @Input()
  route: string = '';
  @Input()
  stageName: string = '';
  @Input()
  id: string = '';
  @Input()
  svgPath: string = '';
  @Input()
  alt: string = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
  }
}
