<div class="form-table-container">
  <small *ngIf="showProcessesError"
          class="input-error">
      There must be at least 1 item in table.
  </small>
  <div class="form-table">
    <table aria-describedby="form table">
      <thead>
        <tr>
          <th scope="col" *ngFor="let header of columns">{{ header.name }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container [formGroup]="tableForm">
          <ng-container formArrayName="form_array">
            <tr [formGroupName]="iForm" *ngFor="let form of getFormArray().controls; let iForm = index;" id="row-{{ iForm }}">
              <td>
                <div class="row-actions">
                  <!-- <div> -->
                    <img src="assets/icons/trash-can.svg" width="17" height="27" alt="Remove" (click)="removeRow(iForm)">
                    <!-- <img src="assets/icons/magnifier.svg" width="27" height="27" alt="Search" style="opacity:0.4;filter:alpha(opacity=100);"> -->

                  <!-- </div> -->
                </div>
              </td>
              <td *ngFor="let property of getFormArrayControls(form); let i = index;">
                <div>
                  <p-dropdown
                  (ngModelChange)="selectText(iForm)"
                    *ngIf="property === 'text'"
                    [options]="textList"
                    optionLabel="nombre"
                    placeholder="Select"
                    [formControlName]="property"
                    [filter]="true"
                    filterBy="nombre"
                    [overlayOptions]="{ mode: 'overlay', appendTo: 'body' }"
                  ></p-dropdown>
                  <p-dropdown
                  (ngModelChange)="calculatePrices(iForm)"
                    *ngIf="property === 'ps'"
                    [options]="psList"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select"
                    [formControlName]="property"
                    [overlayOptions]="{ mode: 'overlay', appendTo: 'body' }"
                  ></p-dropdown>
                  <input
                    *ngIf="!(property === 'ps') && !(property === 'text') && !(property === 'kg_h') && !(property === 'people_number')"
                    type="text"
                    pInputText
                    [readonly]="true"
                    placeholder="Data"
                    [formControlName]="property"
                    id="input-{{ property + '-' + i }}"
                    
                  >
                  <input
                    *ngIf="property === 'kg_h' || property === 'people_number'"
                    type="text"
                    pInputText
                    placeholder="Enter info"
                    [formControlName]="property"
                    id="input-{{ property + '-' + i }}"
                    class="white-background" (ngModelChange)="calculatePrices(iForm)"
                    appTwoDigitDecimaNumber
                  >
                </div>
              </td>
            </tr>
            <tr class="form-subtotal">
              <td colspan="6"><div>Subtotal</div></td>
              <td><div>{{subtotal_all|number:'1.0':'en-US'}}</div></td>
              <td><div>{{subtotal_mod|number:'1.0':'en-US'}}</div></td>
              <td><div>{{subtotal_mmtto|number:'1.0':'en-US'}}</div></td>
              <td><div>{{subtotal_energy|number:'1.0':'en-US'}}</div></td>
              <td><div>{{subtotal_gas|number:'1.0':'en-US'}}</div></td>
              <td><div>{{subtotal_depreciation|number:'1.0':'en-US'}}</div></td>
              <td><div>{{subtotal_cif|number:'1.0':'en-US'}}</div></td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<button class="add-row-button" (click)="addNewRow()">Add New Row</button>