import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent {
  /**
   * The table headers list.
   */
  @Input() public columns: any[];

  /**
   * The table rows list.
   */
  @Input() tableData: any[];
  // @Output() tableDataUpdate = new EventEmitter<any>();

  @Input() forMacroeconomics=true;
  // @Output() tableDataUpdate = new EventEmitter<any>();

  constructor() {
    this.columns = [];
    this.tableData = [];
  }

  /**
   * Gets the row data properties to be displayed in the table.
   *
   * @param row The object to get the properties from.
   */
  public getRowDataProperties(row: any): string[] {
    return Object.keys(row);
  }

  dataValue(value:any){
    if (isNaN(value)) {
      return 0
    }
    return value
  }
}
