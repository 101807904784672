<!-- Table with PrimeNG (With duplication bug) -->
<!--<p-table [value]="getFormArray().controls" [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-sm">-->
<!--  <ng-template pTemplate="header">-->
<!--    <tr>-->
<!--      <th scope="col" *ngFor="let header of tableHeaders">{{ header.name }}</th>-->
<!--    </tr>-->
<!--  </ng-template>-->
<!--  <ng-template pTemplate="body" let-row [formGroup]="tableForm">-->
<!--    <ng-container formArrayName="form_array">-->
<!--      <tr [formGroupName]="i" *ngFor="let form of getFormArray().controls; let i = index;" id="row-{{ i }}">-->
<!--        <td>-->
<!--          <div class="row-actions">-->
<!--            <img src="assets/icons/trash-can.svg" width="14" height="14" alt="Remove">-->
<!--            <img src="assets/icons/magnifier.svg" width="24" height="24" alt="Search">-->
<!--          </div>-->
<!--        </td>-->
<!--        <td *ngFor="let property of getFormArrayControls(form); let i = index;">-->
<!--          <input type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">-->
<!--        </td>-->
<!--      </tr>-->
<!--    </ng-container>-->
<!--  </ng-template>-->
<!--</p-table>-->

<!-- Native table -->
<div class="form-table-container">
  <small *ngIf="showPackagingError" class="input-error">
      There must be at least 3 items in table.
  </small>
  <div class="form-table">
    <table aria-describedby="form table">
      <thead>
        <tr>
          <th scope="col" *ngFor="let header of columns">{{ header.name }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container [formGroup]="tableForm">
          <ng-container formArrayName="form_array">
            <tr [formGroupName]="iForm" *ngFor="let form of getFormArray().controls; let iForm = index;" id="row-{{ iForm }}">
              <td>
                <div class="row-actions">
                  <img src="assets/icons/trash-can.svg" width="17" height="17" alt="Remove" (click)="removeRow(iForm)">
                  <img src="assets/icons/magnifier.svg" width="27" height="27" alt="Search" (click)="showFilterPopUp(iForm)">
                </div>
              </td>
              <td *ngFor="let property of getFormArrayControls(form); let i = index;">
                <div *ngIf="materialsList.length>0" >
                  <input *ngIf="property!='formula' && 
                  property != 'code' && 
                  property != 'description' && 
                  property !='weight_kg' && 
                  property != 'category' &&
                  property != 'umb' &&
                  property !='cost_per_umb' && 
                  property !='moq' && 
                  property != 'currency' && 
                  property !='scrap' && 
                  property != 'observations'" type="text" [readonly]="true" pInputText [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input *ngIf="(
                  property == 'description' ||
                  property == 'category' ||
                  property == 'umb' ||
                  property =='cost_per_umb' ||
                  property == 'currency') && !materialsList[iForm].newRow" type="text" [readonly]="true" pInputText [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input *ngIf="property=='code' && materialsList[iForm].newRow" type="text" class="white-background" (blur)="searchMaterialOnLostFocus(iForm)" (ngModelChange)="refreshTypeRawMaterial(iForm)" (keypress)="keyPressNumbers($event)" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input *ngIf="property=='code' && !materialsList[iForm].newRow" type="text" [readonly]="true" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input *ngIf="property=='description' && materialsList[iForm].newRow" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input *ngIf="property=='weight_kg'" class="white-background" (ngModelChange)="calculatePrices(iForm)" (keypress)="keyPressNumbers($event)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input *ngIf="property=='category' && materialsList[iForm].newRow" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input *ngIf="property=='umb' && materialsList[iForm].newRow" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input *ngIf="property=='cost_per_umb' && materialsList[iForm].newRow" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" (ngModelChange)="calculatePrices(iForm)"  (keypress)="keyPressNumbers($event)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input *ngIf="property=='moq'" class="white-background" (ngModelChange)="updateMOQ(iForm)" appOnlyNumber type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input *ngIf="property=='currency' && materialsList[iForm].newRow" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" (ngModelChange)="calculatePrices(iForm)"  type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input *ngIf="property=='scrap'" type="text" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" pInputText placeholder="Enter info" (ngModelChange)="addScrap(iForm)" (keypress)="keyPressNumbers($event)" [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input *ngIf="property=='observations'" type="text" class="white-background" (ngModelChange)="refreshObservations(iForm)" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                  
                </div>
                <div *ngIf="materialsList.length==0" >
                  <input [readonly]="true" type="text" pInputText placeholder="" [formControlName]="property" id="input-{{ property + '' + i }}">
                </div>




                <!-- <div>
                  <input type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                  <input type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                </div> -->
              </td>
            </tr>
            <tr></tr>
              <tr>
                <td><strong>Subtotal</strong></td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> <div>{{ subtotal|number:'1.0':'en-US' }}</div></td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
              </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
    <!-- <div class="form-table-subtotal">
      <table>
        <tr>
          <td>
            <strong>Subtotal</strong>
            
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div>{{ subtotal|number:'1.0':'en-US' }}</div>
            
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div> -->
  </div>
</div>
<div style="margin-top: 7px;"> <Span>* To enter a material without SAP code, please write “0” in the Code column</Span></div>
<button class="add-row-button" (click)="addNewRow()">Add New Row</button>
