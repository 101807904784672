import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GetUserDetail, GetUserId, RemoveProjectData, saveCurrentModuleIndex, saveCurrentVersionOnLocal, saveProjectBriefIdOnLocal, saveProjectIdOnLocal } from '@core/utils';
import { Accordion } from 'primeng/accordion';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateVersionPopupComponent } from '../create-version-popup/create-version-popup.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom, tap } from 'rxjs';
import { FoldersService } from '@core/services/home/folders/folders.service';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
  providers: [DialogService,MessageService, ConfirmationService]
})
export class ProjectCardComponent implements OnInit{

  @Input()
  _id: string = '';
  @Input()
  projectBriefId: string = '';
  @Input()
  version = 1;
  @Input()
  is_active: boolean = true;
  @Input()
  created_at: string = '';
  @Input()
  deadline: string = '';
  @Input()
  name: string = '';
  @Input()
  sale_price: number = 0;
  @Input()
  price_per_kilo: number = 0;
  @Input()
  qty_kg: number = 0;
  @Input()
  process_info: any = [];
  @Input()
  quantity=0
  @Input()
  macroeconomics={
    status:false,
    user:""
  }
  @Input()
  r_and_d={
    status:false,
    user:""
  }
  @Input()
  packaging={
    status:false,
    user:""
  }
  @Input()
  processes={
    status:false,
    user:""
  }
  @Input()
  allies={
    status:false,
    user:""
  }
  @Input()
  excess={
    status:false,
    user:""
  }
  @Input()
  cost_feasibility={
    status:false,
    user:""
  }
  @Input()
  logistics_expenses={
    status:false,
    user:""
  }

  success=true

  viewProcess=false;

  canDeleteProjects=["ADMIN", "SUPERADMIN", "RYD", "EMPAQUES", "PROCESOS"]
  canCreateNewVersions=["ADMIN", "SUPERADMIN","COMERCIAL", "RYD", "EMPAQUES", "PROCESOS"]
  canDuplicateProjects=["ADMIN", "SUPERADMIN","COMERCIAL", "RYD", "EMPAQUES", "PROCESOS"]
  allowedToDeleProjects=false
  allowedToCreateNewVersions=false
  allowedToDuplicateProjects=false

  user_details:any={}
  @ViewChild('accordion') accordion!: Accordion;

  constructor(private router: Router,
    public dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private foldersService: FoldersService,
    ){
    this.checkUserPermissions()
  }

  ngOnInit(): void {
    // console.log(this.created_at)
    this.process_info = [
      {
        process:"Macroeconomics",
        status:this.macroeconomics.status,
        assigned_to:''
      },
      {
        process:"R&D",
        status:this.r_and_d.status,
        assigned_to:this.r_and_d.user
      },
      {
        process:"Packaging",
        status:this.packaging.status,
        assigned_to:this.packaging.user
      },
      {
        process:"Processes",
        status:this.processes.status,
        assigned_to:this.processes.user
      },
      {
        process:"Allies",
        status:this.allies.status,
        assigned_to:''
      },
      {
        process:"Excess",
        status:this.excess.status,
        assigned_to:''
      },
      {
        process:"Logistics Expenses",
        status:this.logistics_expenses.status,
        assigned_to:this.logistics_expenses.user
      },
      {
        process:"Cost Feasibility",
        status:this.cost_feasibility.status,
        assigned_to:''
      }
    ]
  }

  ref: DynamicDialogRef | undefined;

  checkUserPermissions(){
    this.user_details = GetUserDetail()
    if (this.canDeleteProjects.includes(this.user_details.tipo)) {
      this.allowedToDeleProjects=true
    }
    if (this.canCreateNewVersions.includes(this.user_details.tipo)) {
      this.allowedToCreateNewVersions=true
    }
    if (this.canDuplicateProjects.includes(this.user_details.tipo)) {
      this.allowedToDuplicateProjects=true
    }
  }

  createNewVersion() {
    this.ref = this.dialogService.open(CreateVersionPopupComponent, 
    { 
      data: {
        projectBriefId: this.projectBriefId,
      },
      header: 'Create Version',
      width: "90%",
      styleClass: 'create-version-dialog',
      style: {
      
      
    },
    contentStyle: { 
       "border-bottom-left-radius": '16px',
       "border-bottom-right-radius": '16px'
    }, 
    });

    this.ref.onClose.subscribe(async (result: any) => {
      if(result.success){
        window.location.reload();
      } else {
        this.success=false
        this.changesUpdatesPopUp(result.message)
      }
      
    })
  }

  async duplicateProject() {

    this.confirmationService.confirm({
      accept: async () => {
        this.success=true
        await this.duplicateProjectApi()
        this.changesUpdatesPopUp("Project could not be duplicated.")
        
      },
      key: 'duplicateProject'
  });
    
  }

  showLinkPopUp(){

  }

  goToProject(){
    RemoveProjectData()
    saveProjectBriefIdOnLocal(this.projectBriefId)
    saveCurrentVersionOnLocal({current_version:this.version, total_versions:this.version})
    saveProjectIdOnLocal(this._id)
    saveCurrentModuleIndex(0)
    this.router.navigate([`project-brief`,this._id]);
    
  }

  async deleteProject(){
  try {
    await lastValueFrom(this.foldersService.deleteProject(this.projectBriefId, {user: this.user_details._id}).pipe(
      (tap( response => {
      }, error => {
        this.success=false
      }
    ))
    ))
  } catch (error) {
    this.success=false
  }

  }

  async duplicateProjectApi(){
  try {
    await lastValueFrom(this.foldersService.duplicateProject(this.projectBriefId, {user: this.user_details._id}).pipe(
      (tap( response => {
      }, error => {
        this.success=false
      }
    ))
    ))
  } catch (error) {
    this.success=false
  }

  }

  showProcess(){
    this.viewProcess=!this.viewProcess
    // console.log("process", this.viewProcess)
  }
  clickTrash(){
    this.confirmationService.confirm({
      accept: async () => {
        this.success=true
        await this.deleteProject()
        this.changesUpdatesPopUp("Project could not be deleted.")
      },
      key: 'deleteProject'
  });
  }

  changesUpdatesPopUp(message:string){
    setTimeout( () => {
      if (this.success) {
        window.location.reload();
      } else {
        this.messageService.add({ severity: 'success', summary: 'Fail', detail: message });
      }
    }, 300);
  }

}
