import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
// import { getMacroeconomicsValuesOnLocal, saveMacroeconomicsValuesOnLocal } from '@core/utils/local-storage-data';
import { HttpClient } from '@angular/common/http';
import { getAlliesValuesOnLocal, saveAlliesValuesOnLocal } from '@core/utils/local-storage-data';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class AlliesService {
  constructor(private http: HttpClient,) {
    
  }

  saveAllies(data:any, params:any): Observable<any>  {
    return this.http.post(`${baseUrl}/api/allies`, data, {params: params});
  }

  updateAllies(data:any, projectID:any, params:any): Observable<any>  {
    return this.http.put(`${baseUrl}/api/allies/${projectID}`, data, {params: params});
  }


  getAlliesValuesOnLocalStorage(){
    return getAlliesValuesOnLocal();
  }
  saveAlliesValuesOnLocalStorage(alliesValue:any){
    return saveAlliesValuesOnLocal(alliesValue);
  }

  getAlliesInfo(projectId:any):  Observable<any> {
    return this.http.get(`${baseUrl}/api/allies/${projectId}`);

    // let emptymock = new BehaviorSubject<any>(
    //   {}
    // )
    //   return emptymock.asObservable()
  }

  saveAlliesVersionInfo(versionId:any, data:any){
    return this.http.put(`${baseUrl}/api/project_brief_version/${versionId}`, data);
  }

}
