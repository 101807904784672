import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { PackagingService } from '@core/services/home/packaging/packaging.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterMaterialsComponent } from '../filter-materials/filter-materials.component';
import { lastValueFrom, tap } from 'rxjs';
import { getProjectIdOnLocal, updateEditedStep } from '@core/utils';
import { MacroeconomicsService } from '@core/services/home/macroeconomics/macroeconomics.service';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-form-table-for-bulking',
  templateUrl: './form-table-for-bulking.component.html',
  styleUrls: ['./form-table-for-bulking.component.scss']
})
export class FormTableForBulkingComponent implements OnInit {
  /**
   * The table headers list.
   */
  @Input() public columns: any[];

  @Input() canUpdate = false;

  @Output() updateExpiredItems = new EventEmitter<any>();

 @Output() bulkPreparationTableDone = new EventEmitter<any>();

  dolarTRM=0
  euro=0
  /**
   * The row with the form.
   */
  public tableForm: FormGroup;
  currentIndex=0;
  ref: DynamicDialogRef | undefined;

  initial = true
  showPackagingError = false

  materialsListInitialBackup:any=undefined
  subtotal=0
  totalPercentage=0
  materialsList:any=[]
  projectId=""

  constructor(
    private formBuilder: FormBuilder,
    private packagingService: PackagingService,
    public dialogService: DialogService,
    private macroeconomicsService: MacroeconomicsService,
    private _decimalPipe: DecimalPipe,
    ) {
    this.columns = [];
    this.tableForm = this.formBuilder.group({
      form_array: this.formBuilder.array([
        
      ])
    });
  }

  async ngOnInit() {
    this.getProjectId()
    await this.getDollarTRM()
    this.setMaterials()
    
   }

   async getDollarTRM(){
    let macroeconomicsInfoOnLocal = this.macroeconomicsService.getMacroeconomicsValues()
    if (macroeconomicsInfoOnLocal==null) {
      let macroeconomicsInfoFromApi = await this.getMacroeconomicsInfo(this.projectId)
      if (macroeconomicsInfoFromApi!=null) {
        this.dolarTRM = macroeconomicsInfoFromApi.dolar || 0
        this.euro = macroeconomicsInfoFromApi.euro || 0
      } 
    } else {
      this.dolarTRM= +(macroeconomicsInfoOnLocal.currencies.dolar || 0)
      this.euro= +(macroeconomicsInfoOnLocal.currencies.euro || 0)
    }
  }
  
  async getMacroeconomicsInfo(projectId:any){
    return await lastValueFrom(this.macroeconomicsService.getMacroeconomicsInfo(projectId).pipe(
      (tap( response => {
  
      }
  
      ))
        ))
  }
  

   getProjectId(){
    this.projectId = getProjectIdOnLocal()
  }
  
   async getPackagingInfo(projectID:any){
    return await lastValueFrom(this.packagingService.getPackagingInfo(projectID).pipe(
      (tap( response => {
        
      }
  
      ))
        ))
  
  }
  
  async getMaterialList(projectId:any){
    let packagingInfoFromAPI = await this.getPackagingInfo(projectId)
    if (packagingInfoFromAPI!=null) {
      return packagingInfoFromAPI.for_bulk_preparation
    } 
    return []
  }
  
    async refreshTypeRawMaterial(index:number){
      if (!this.initial && this.canUpdate) {
        updateEditedStep(true)
      }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let sapCode:string = formControls.value.code
      if (this.materialsList[index].newRow) {
        this.refreshNewRowValuesToMaterialList(index, formControls)
        let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
        packagingValues.for_bulk_preparation = this.materialsList;
        this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
        
        if (sapCode!=undefined) {
          if (sapCode.length>6) {
            this.materialsList[index].newRow = false
            setTimeout( () => {
              this.getMaterialInfo(index, sapCode)}, 300);
              // this.getRawMaterialInfo(index, sapCode, typeRawMaterial)
          }
        }
      }
    }

    updateMOQ(index:number){
      if (!this.initial && this.canUpdate) {
        updateEditedStep(true)
      }
      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]
      let moq = formControls.value.moq
      this.materialsList[index].moq = moq
      let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
      if (packagingValues==null) return;
      packagingValues.for_bulk_preparation = this.materialsList;
      this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
    }
    
    refreshObservations(index:number){
      if (!this.initial && this.canUpdate) {
        updateEditedStep(true)
      }
      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]
      let observations = formControls.value.observations
      this.materialsList[index].observations = observations
      let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
      if (packagingValues==null) return;
      packagingValues.for_bulk_preparation = this.materialsList;
      this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
    }
  
    refreshNewRowValuesToMaterialList(index:number, formControls:any){
      if (!this.initial && this.canUpdate) {
        updateEditedStep(true)
      }
      // let observations = formControls.value.observations
      let description = formControls.value.description
      let category = formControls.value.category
      let rawMaterial = formControls.value.view_material_info
      let weight_kg = formControls.value.weight_kg
      let currency = formControls.value.currency
      let costUnd = formControls.value.cost_per_umb
      // let scrap_percentage = formControls.value.scrap_percentage
      let umb = formControls.value.umb
   
      // this.materialsList[index].observations = observations
      this.materialsList[index].description = description
      this.materialsList[index].category = category
      this.materialsList[index].rawMaterial = rawMaterial
      this.materialsList[index].weight_kg = weight_kg
      this.materialsList[index].costUnd = costUnd
      this.materialsList[index].moneda = currency
      this.materialsList[index].umb = umb
      // this.materialsList[index].scrap_percentage = scrap_percentage
  
      
  
    }
  
   async setMaterials(){
    let materialsListFromApi = await this.getMaterialList(this.projectId);
    if(materialsListFromApi.length==0 || materialsListFromApi==null){
       this.addNewRow()
  
    } else {
      this.materialsListInitialBackup = materialsListFromApi
      // console.log(this.materialsListInitialBackup)
      for (let index = 0; index < materialsListFromApi.length; index++) {
        await this.addNewRow()
        this.materialsList[index].newRow=false
        const element = materialsListFromApi[index];
        await this.getMaterialInfo(index, element.sapCode)
  
        let formArray = this.getFormArray().controls;
        let formControls = formArray[index]
        formControls.patchValue({
          code: element.sapCode,
           weight_kg: element.weight_kg,
           scrap: element.scrap_percentage,
           moq: element.moq??"",
           observations: element.observations
          });
          await this.calculatePrices(index)
          await this.addScrap(index)
        }
    }
    this.materialsListInitialBackup = undefined
    this.initial = false
    this.bulkPreparationTableDone.emit(false);

  }
   
  
   showFilterPopUp(index:number) {
    this.materialsList[index].newRow=false
    this.currentIndex = index;
    this.ref = this.dialogService.open(FilterMaterialsComponent, {
        data: {
          consult:"Empaque"
        },
        showHeader: false,
        styleClass: 'filter-materials-popup-dialog',
        //header: 'Select a Product'
    });
  
    this.ref.onClose.subscribe(async (result: any) => {
      if (result.length>0) {
          //this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: product.name });
          await this.removeRow(index)
          await this.addNewRow()
          this.getMaterialInfo(index, result[0])
  
  
      } else {
        this.materialsList[index].newRow=true
      }
    })
  }
  
  
   /**
    * Generates the form fields to be added to the form array.
    *
    * @private
    */
   public generateFormGroupFields(): Object {
     return {
       code: '',
       description: '',
       umb: '',
       weight_kg: '',
       cost_per_kg: '0',
       category: '',
       cost_per_umb: '0',
       moq: '',
       currency: '',
       scrap: '',
       additional_scrap: '',
       share: '',
       observations: ''
     };
   }
  
   /**
    * Gets the form array.
    */
   public getFormArray(): FormArray {
     return this.tableForm.get('form_array') as FormArray;
   }
  
   /**
    * Gets the list of form arrays.
    *
    * @param row
    */
   public getFormArrayControls(row: any): string[] {
     return Object.keys(row.controls);
   }
  
   /**
    * Adds a new row to the table.
    */
   async addNewRow() {
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
  
    if (this.materialsList.length<6) {
      
     const formGroup = this.formBuilder.group(this.generateFormGroupFields());
  
      await this.getFormArray().push(formGroup);
      await this.materialsList.push({
        nombre_proveedor:"",
        valido_de:"",
        status_reg_info:"",
        dias_entrega_reg_info:"",
        cantidad_min_reg_info:0,
        precio_neto:0,
        reg_info:"",
        por:"",
        inco1:"",
        inco2:"",
        factorKg:0,
        factor:0,
        cantidad_escala:0,
        importe_escala:0,
        ump:"",
        moneda:"",
        moq:"",
        nombreMaterial:"",
        categoria:"",
        subcategoria:"",
        toolbox:true,
        typeRawMaterial:"",
        newRow:true,
        txt_gr_articulo:""
      })
      // console.log('tableForm', this.tableForm.value);
      // console.log(this.getFormArray().controls)
      }
    // this.checkMaterialsQuantity()

    }
  
   /**
    * Removes the given index row from the table.
    *
    * @param index
    */
   public async removeRow(index: number) {
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    //  this.getFormArray().controls.splice(index, 1);
  
     if (this.materialsList.length>0) {
      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]
      let currentCostKgPrice = this.convertNumberWithCommasToNumber(formControls.value.cost_per_kg)
      this.subtotal=this.subtotal-currentCostKgPrice;
  
        await this.getFormArray().controls.splice(index, 1);
        await this.materialsList.splice(index, 1)
  
        // console.log(this.getFormArray().controls)
        // console.log(this.materialsList)
    
      let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
      if (packagingValues) {
        packagingValues.for_bulk_preparation = this.materialsList;
        this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
      }
      // if(this.materialsListInitialBackup!=undefined){
      //   this.materialsListInitialBackup=this.materialsList
  
      // }
    // this.checkMaterialsQuantity()
      this.updateExpiredItems.emit();
      return;
    }
  
    // await this.getFormArray().controls.splice(index, 1);
    // await this.materialsList.splice(index, 1)
    // let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    // rAndDValues.materials_list = this.materialsList;
    // this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
  
   }
  
    async getMaterialInfo(index:number, sapCode:string){
  
      let materialInfo = await this.getMaterialInfoBySapCode(sapCode)
      if(materialInfo.length>0){
        this.materialsList[index].newRow = false
        this.materialsList[index].rawMaterial = materialInfo[0].nombreMaterial;
        this.materialsList[index].supplier = materialInfo[0].provider || 'LUKER';
        this.materialsList[index].moneda = materialInfo[0].moneda || 'COP';
        this.materialsList[index].expired = materialInfo[0].status_reg_info;
        this.materialsList[index].costUnd =(parseFloat(materialInfo[0]?.precio_neto) / parseFloat(materialInfo[0]?.por)) * (1 + (materialInfo[0]?.factor || 0));
        this.materialsList[index].sapCode = sapCode
        this.materialsList[index].ump = materialInfo[0].ump || materialInfo[0].umb
        this.materialsList[index].reg_info = materialInfo[0].reg_info
        this.materialsList[index].nombre_proveedor = materialInfo[0].nombre_proveedor
        this.materialsList[index].inco1 = materialInfo[0].inco1
        this.materialsList[index].inco2 = materialInfo[0].inco2
        this.materialsList[index].valido_de = materialInfo[0].valido_de
        this.materialsList[index].valido_hasta = materialInfo[0].valido_hasta
        this.materialsList[index].moq = materialInfo[0].cantidad_min_reg_info
        this.materialsList[index].cantidad_min_reg_info = materialInfo[0].cantidad_min_reg_info
        this.materialsList[index].categoria = materialInfo[0].txt_gr_articulo
        if(this.materialsListInitialBackup!=undefined){
          this.materialsList[index].observations = this.materialsListInitialBackup[index].observations??""
          this.materialsList[index].moq = this.materialsListInitialBackup[index].moq??this.materialsListInitialBackup[index].cantidad_min_reg_info
          // this.materialsList[index].scrap_percentage = this.materialsListInitialBackup[index].scrap_percentage??""
          // this.materialsList[index].formula = this.materialsListInitialBackup[index].formula??""
        }
        await this.saveValuesOnLocalStorage()
        let formArray = this.getFormArray().controls;
        let formControls = formArray[index]
        formControls.patchValue({
        code: this.materialsList[index].sapCode,
         description: this.materialsList[index].rawMaterial,
         cost_per_kg: '0',
         category: this.materialsList[index].categoria,
         umb: this.materialsList[index].ump,
         cost_per_umb: this._decimalPipe.transform(this.materialsList[index].costUnd, this.materialsList[index].moneda=="COP"?'1.0-0':'1.2-2'),
         moq: this.materialsList[index].moq,
         currency: this.materialsList[index].moneda,
         scrap: this.materialsList[index].scrap_percentage,
         observations: this.materialsList[index].observations
        });
        this.updateMOQ(index);
        
      } else {
        if(this.materialsListInitialBackup!=undefined){
          this.materialsList[index].observations = this.materialsListInitialBackup[index].observations??""
          this.materialsList[index].rawMaterial = this.materialsListInitialBackup[index].rawMaterial??""
          this.materialsList[index].supplier = this.materialsListInitialBackup[index].supplier??""
          this.materialsList[index].moneda = this.materialsListInitialBackup[index].moneda??""
          this.materialsList[index].expired = this.materialsListInitialBackup[index].expired??""
          this.materialsList[index].costUnd = this.materialsListInitialBackup[index].costUnd??""
          this.materialsList[index].sapCode = this.materialsListInitialBackup[index].sapCode??"NO CODE"
          this.materialsList[index].weight_kg = this.materialsListInitialBackup[index].weight_kg??""
          this.materialsList[index].area = this.materialsListInitialBackup[index].area??""
          this.materialsList[index].observations = this.materialsListInitialBackup[index].observations??""
          this.materialsList[index].description = this.materialsListInitialBackup[index].description??""
          this.materialsList[index].category = this.materialsListInitialBackup[index].category??""
          this.materialsList[index].umb = (this.materialsListInitialBackup[index].ump||this.materialsListInitialBackup[index].umb)??""
          this.materialsList[index].reg_info = this.materialsListInitialBackup[index].reg_info??""
          this.materialsList[index].nombre_proveedor = this.materialsListInitialBackup[index].nombre_proveedor??""
          this.materialsList[index].inco1 = this.materialsListInitialBackup[index].inco1??""
          this.materialsList[index].inco2 = this.materialsListInitialBackup[index].inco2??""
          this.materialsList[index].valido_de = this.materialsListInitialBackup[index].valido_de??""
          this.materialsList[index].moq = this.materialsListInitialBackup[index].moq??""
          this.materialsList[index].valido_hasta = this.materialsListInitialBackup[index].valido_hasta??""
          this.materialsList[index].categoria = this.materialsListInitialBackup[index].categoria??""
          this.saveValuesOnLocalStorage()
          let formArray = this.getFormArray().controls;
          let formControls = formArray[index]
          formControls.patchValue({
          code: this.materialsList[index].sapCode,
          description:this.materialsList[index].description,
          cost_per_kg: '0',
          category: this.materialsList[index].categoria||this.materialsList[index].category,
          umb: this.materialsList[index].umb,
          cost_per_umb: this.materialsList[index].costUnd,
          moq: this.materialsList[index].moq,
          currency: this.materialsList[index].moneda,
          additional_scrap: '',
          share: '',
          observations: this.materialsList[index].observations
          });
          this.updateMOQ(index);
        } else {
          this.materialsList[index].sapCode="NO COD"
          let formArray = this.getFormArray().controls;
          let formControls = formArray[index]
          formControls.patchValue(
            {
              code: "NO COD",
            }
            )
        }
        this.materialsList[index].newRow = true
      }

      this.updateExpiredItems.emit();
      
    }
  
    async getMaterialInfoBySapCode(sapCode:any){
        return await lastValueFrom(this.packagingService.getMaterialgBySapCode(sapCode).pipe(
          (
            tap( response => {
            // console.log("fin pipe materia prima 1 precios",response)
            })
          )
        ))
    }
    async getBySapCode(sapCode:any){
        return await lastValueFrom(this.packagingService.getBySapCode(sapCode).pipe(
          (
            tap( response => {
            // console.log("fin pipe materia prima 1 precios",response)
            })
          )
        ))
    }

    updateSharedValuesFromParent(){
      let formArray = this.getFormArray().controls;
      let packagingValuesLocalStorage = this.packagingService.getPackagingValuesOnLocalStorage();
      let createPackaginMaterials=packagingValuesLocalStorage.create_packaging??[]
      let bulkPreparationMaterials=packagingValuesLocalStorage.for_bulk_preparation??[]
      let cretePackagingTotal = 0
      
      for (let material of createPackaginMaterials ) {
        cretePackagingTotal = cretePackagingTotal + (material.costKg??0)
      }
      if(formArray.length==bulkPreparationMaterials.length){

        for (let indexFor = 0; indexFor < bulkPreparationMaterials.length; indexFor++) {
          const element = bulkPreparationMaterials[indexFor];
          let formControlsFor = formArray[indexFor]
        let newShareValue = (cretePackagingTotal+this.subtotal)>0?(element.costKg*100/(cretePackagingTotal+this.subtotal)):0
        bulkPreparationMaterials[indexFor].share = newShareValue
        formControlsFor.patchValue({
          share:  this._decimalPipe.transform(bulkPreparationMaterials[indexFor].share ?? 0, '1.2-2') + "%"
          });
          
      }
      }
    }
  
    async calculatePrices(index:number){
      if (!this.initial && this.canUpdate) {
        updateEditedStep(true)
      }
      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]
      let weight_kg = (+formControls.value.weight_kg)??0
      let currentcostKg = this.convertNumberWithCommasToNumber(formControls.value.cost_per_kg.toString())
      let packagingValuesLocalStorage = await this.packagingService.getPackagingValuesOnLocalStorage();
      if (packagingValuesLocalStorage==null) return;
      let createPackaginMaterials=packagingValuesLocalStorage.create_packaging??[]
      let currentScrapPercentage = (this.materialsList[index].scrap_percentage)??0
      let cretePackagingTotal = 0
      for (let material of createPackaginMaterials ) {
        cretePackagingTotal = cretePackagingTotal + (material.costKg??0)
      }
      let scrap = formControls.value.scrap==undefined?0:((+formControls.value.scrap)??0)
      let costUnd = (+this.materialsList[index].costUnd)??0
      let initialFormula = 0
      let initial = false
      // if(this.materialsListInitialBackup!=undefined){
      //   initial=true
      //   initialFormula = this.materialsListInitialBackup[index].formula
      // }
      if(weight_kg==0){
        this.subtotal = this.subtotal - currentcostKg
        this.materialsList[index].costKg = 0
        this.materialsList[index].weight_kg = weight_kg
        this.materialsList[index].share = 0
        this.materialsList[index].priceKgWithScrap = 0
        this.saveValuesOnLocalStorage()
        formControls.patchValue({
          cost_per_kg: 0,
          share: '0%'
          });
          for (let indexFor = 0; indexFor < this.materialsList.length; indexFor++) {
            const element = this.materialsList[indexFor];
            let formControlsFor = formArray[indexFor]
            let newShareValue = (cretePackagingTotal+this.subtotal)>0?(element.costKg*100/(cretePackagingTotal+this.subtotal)):0
            this.materialsList[indexFor].share = newShareValue
            formControlsFor.patchValue({
              share:  this._decimalPipe.transform(this.materialsList[indexFor].share ?? 0, '1.2-2') + "%"
              });
            
          }
      }
      if (weight_kg>0 && costUnd>0) {
        this.subtotal = this.subtotal - currentcostKg
        let costKg =(1 / weight_kg) * costUnd * (1 + (scrap/100)) * (this.materialsList[index].moneda=="USD"?this.dolarTRM:this.materialsList[index].moneda=="EUR"?this.euro:1);
        let costKgNotRound = costKg
        let costKgWithoutScrap =(1 / weight_kg) * costUnd * (this.materialsList[index].moneda=="USD"?this.dolarTRM:this.materialsList[index].moneda=="EUR"?this.euro:1);
        costKg = Math.round(costKg)
        this.materialsList[index].priceKgWithScrap = costKgNotRound
        this.subtotal = this.subtotal + costKg
        this.materialsList[index].costKg = costKgWithoutScrap
        this.materialsList[index].weight_kg = weight_kg
        this.materialsList[index].share = (cretePackagingTotal+this.subtotal)>0?(costKg*100/(cretePackagingTotal+this.subtotal)):0
        this.saveValuesOnLocalStorage()
        formControls.patchValue({
          cost_per_kg: this._decimalPipe.transform(costKg, '1.0-0'),
          share:  this._decimalPipe.transform(this.materialsList[index].share, '1.2-2') + "%"
          });
        for (let indexFor = 0; indexFor < this.materialsList.length; indexFor++) {
          const element = this.materialsList[indexFor];
          let formControlsFor = formArray[indexFor]
          let newShareValue = (cretePackagingTotal+this.subtotal)>0?(element.costKg*100/(cretePackagingTotal+this.subtotal)):0
          this.materialsList[indexFor].share = newShareValue
          formControlsFor.patchValue({
            share:  this._decimalPipe.transform(this.materialsList[indexFor].share ?? 0, '1.2-2') + "%"
            });
          
        }
      }
    }
  
    async saveValuesOnLocalStorage(){
      let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
      if(packagingValues==null) return;
      packagingValues.for_bulk_preparation = this.materialsList;
      this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
    }
  
    keyPressNumbers(event:any) {
      var charCode = (event.which) ? event.which : event.keyCode;
      // Only Numbers 0-9
      if ((charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    }
  
    async addScrap(index:any){
      if (!this.initial && this.canUpdate) {
        updateEditedStep(true)
      }
      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]
      let costKg = Math.round(this.materialsList[index].costKg??0)
      let scrapPercentage = formControls.value.scrap==undefined?0:((+formControls.value.scrap)??0)
      let weight_kg = (+formControls.value.weight_kg)??0
      let costUnd = (+this.materialsList[index].costUnd)??0
      if(costKg>0){
        let scrapPrice = Math.round((1 / weight_kg) * costUnd * scrapPercentage/100)
        this.materialsList[index].scrap_percentage = scrapPercentage
        formControls.patchValue({
          additional_scrap: this._decimalPipe.transform(Math.round(scrapPrice), '1.0-0'),
        })
        let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
        packagingValues.for_bulk_preparation = this.materialsList;
        this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
        this.calculatePrices(index)
      } else {
        this.materialsList[index].priceKgWithScrap = 0
        let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
        if (packagingValues==null) return;
        packagingValues.for_bulk_preparation = this.materialsList;
        this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
      }
    }

    searchMaterialOnLostFocus(index:number) {
      if (!this.initial && this.canUpdate) {
        updateEditedStep(true)
      }
      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]
      let sapCode:string = formControls.value.code
      if (this.materialsList[index].newRow) {
        this.refreshNewRowValuesToMaterialList(index, formControls)
        let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
        packagingValues.for_bulk_preparation = this.materialsList;
        this.packagingService.savePackagingValuesOnLocalStorage(packagingValues);
        
        if (sapCode!=undefined) {
          if (sapCode.length>0 && this.materialsList[index].newRow) {
            this.materialsList[index].newRow = false
            setTimeout( () => {
              this.getMaterialInfo(index, sapCode)}, 300);
              // this.getRawMaterialInfo(index, sapCode, typeRawMaterial)
            }
          }
        }
  }

  checkMaterialsQuantity(){
    let packagingValues = this.packagingService.getPackagingValuesOnLocalStorage();
    let for_bulk_preparation = packagingValues.for_bulk_preparation 
    if (this.initial) {
      return;
    } else if (for_bulk_preparation.length<3) {
      this.showPackagingError = true
    } else {
      this.showPackagingError = false
    }
  }

  convertNumberWithCommasToNumber(quantity:any){
    if (quantity=='') return 0
    return parseFloat(quantity.replace(/,/g, ''))
   //  this._decimalPipe.transform(, '1.0')
   }
  
}
