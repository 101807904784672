<div class="sticky-header">    
    <app-header profilePic="../../../../assets/images/layout/thumbnail.png"></app-header>
</div>
<div class="container">
    <div class="flex items-center container__head-left-side">
        <a (click)="goBackToFolder()">
            <svg width="8" height="12" viewBox="0 0 8 12"  xmlns="http://www.w3.org/2000/svg">
                <path d="M0.990431 5.46885L5.24043 1.21885C5.53418 0.925098 6.00918 0.925098 6.29981 1.21885L7.00606 1.9251C7.29981 2.21885 7.29981 2.69385 7.00606 2.98447L3.99668 6.0001L7.00918 9.0126C7.30293 9.30635 7.30293 9.78135 7.00918 10.072L6.30293 10.7813C6.00918 11.0751 5.53418 11.0751 5.24356 10.7813L0.993556 6.53135C0.696681 6.2376 0.696681 5.7626 0.990431 5.46885Z" fill="#000"/>
                </svg>
        </a> 
            <h2>
                Compare Versions
            </h2>
    </div>
    <span>Select the project and its versions that you’d like to compare. Remember that in this comparison you’ll see: <font color="#5B2D00">Manufacturing cost, Raw material and Packaging</font>.</span>

    <div class="container__project-versions">
        <div class="container__project">
            <label for="float-label">Project</label>
            <div>
                <p-dropdown (onChange)="getVersions()" [options]="projects" [(ngModel)]="selectedProject" placeholder="Select a Project" optionValue="projectBriefId" optionLabel="project_name"></p-dropdown>
            </div>
        </div>
        <div class="flex flex-wrap lg:flex-nowrap justify-between container__versions">
            <div class="w-full mb-3 container__version1">
                <div>
                    <label for="float-label">Version 1</label>
                    <p-dropdown (onChange)="updateFirstVersion()" [options]="versions" [(ngModel)]="firstVersionSelected" placeholder="Select one version" optionValue="_id" optionLabel="version"></p-dropdown>
                </div>
            </div>
            <div class="w-full mb-3 container__version2">
                <div>
                    <label for="float-label">Version 2</label>
                    <p-dropdown (onChange)="updateSecondVersion()" [options]="versions" [(ngModel)]="secondVersionSelected" placeholder="Select one version" optionValue="_id" optionLabel="version"></p-dropdown>
                </div>
            </div>
            <div class="w-full mb-3 container__version3">
                <div>
                    <label for="float-label">Version 3</label>
                    <p-dropdown (onChange)="updateThirdVersion()" [options]="versions" [(ngModel)]="thirdVersionSelected" placeholder="Select one version" optionValue="_id" optionLabel="version"></p-dropdown>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-wrap lg:flex-nowrap justify-between container__versions">

    </div>
    <div class="flex flex-wrap lg:flex-nowrap justify-between container__accordion-versions">
        <div class="w-full mb-3 container__version1">
            <div>
                <p>Version 1</p>
                <div class="container__manufacturing">
                    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
                        <p-accordionTab iconPos="end">
                            <ng-template pTemplate="header" >
                                <div class="flex justify-content-between">
                                    <table class="table__accordion-header">
                                        <tr>
                                            <td>
                                                Manufacturing
                                            </td>
                                            <td>
                                                {{manufacturingFirstVersion.total | number:'1.0':'en-US'}}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </ng-template>
                            <p-divider styleClass="divider-style" ></p-divider>
                            <table id="manufacturing">
                                <thead>
                                    <tr>
                                        <th>
                                            Item
                                        </th>
                                        <th>
                                            Cost
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Depreciation</td>
                                        <td>{{manufacturingFirstVersion.depreciation | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Energy</td>
                                        <td>{{manufacturingFirstVersion.energy | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Gas</td>
                                        <td>{{manufacturingFirstVersion.gas | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Maintance</td>
                                        <td>{{manufacturingFirstVersion.mmtto | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Direct Labour</td>
                                        <td>{{manufacturingFirstVersion.mod | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>MOI and other CIF</td>
                                        <td>{{manufacturingFirstVersion.cif | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>External Manufacturing</td>
                                        <td>{{manufacturingFirstVersion.external_manufacturing | number:'1.0':'en-US'}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table id="total">
                                <thead>
                                    <tr>
                                        <th>TOTAL COST</th>
                                        <th>{{manufacturingFirstVersion.total | number:'1.0':'en-US'}}</th>
                                    </tr>
                                </thead>
                            </table>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="container__raw-materials">
                    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
                        <p-accordionTab iconPos="end">
                            <ng-template pTemplate="header" >
                                <table class="table__accordion-header">
                                    <tr>
                                        <td>
                                            Raw Material
                                        </td>
                                        <td>
                                            {{(rawMaterialFirstVersion.total_material_1+rawMaterialFirstVersion.total_material_2) | number:'1.0':'en-US'}}
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <p-divider styleClass="divider-style" ></p-divider>
                            <div class="container__raw-materials-body">
                                <table id="raw-materials">
                                    <thead>
                                        <tr>
                                            <th>
                                                Material 1
                                            </th>
                                            <th>
                                                Porcentage
                                            </th>
                                            <th>
                                                Cost
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let material of rawMaterialFirstVersion.material_1" >
                                            <td>{{material.name}}</td>
                                            <td>{{material.percentage | number:'1.1-1':'en-US'}}%</td>
                                            <td>{{material.cost | number:'1.0':'en-US'}}</td>
                                        </tr>
                                        <tr style="height: 12px;"></tr>
                                        <tr>
                                            <td colspan="2">TOTAL COST</td>
                                            <td>{{rawMaterialFirstVersion.total_material_1 | number:'1.0':'en-US'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style="height: 20px;"></div>
                                <table id="raw-materials">
                                    <thead>
                                        <tr>
                                            <th>
                                                Material 2
                                            </th>
                                            <th>
                                                Porcentage
                                            </th>
                                            <th>
                                                Cost
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let material of rawMaterialFirstVersion.material_2" >
                                            <td>{{material.name}}</td>
                                            <td>{{material.percentage | number:'1.1-1':'en-US'}}%</td>
                                            <td>{{material.cost | number:'1.0':'en-US'}}</td>
                                        </tr>
                                        <tr style="height: 12px;"></tr>
                                        <tr>
                                            <td colspan="2">TOTAL COST</td>
                                            <td>{{rawMaterialFirstVersion.total_material_2 | number:'1.0':'en-US'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="container__packaging">
                    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
                        <p-accordionTab iconPos="end">
                            <ng-template pTemplate="header" >
                                <table class="table__accordion-header">
                                    <tr>
                                        <td>
                                            Packaging
                                        </td>
                                        <td>
                                            {{packagingFirstVersion.total | number:'1.0':'en-US'}}
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <p-divider styleClass="divider-style" ></p-divider>
                            <table id="packaging">
                                    <thead>
                                        <tr>
                                            <th>
                                                Item
                                            </th>
                                            <th>
                                                Qty
                                            </th>
                                            <th>
                                                Cost
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let material of packagingFirstVersion.materials" >
                                            <td>{{material.name}}</td>
                                            <td>{{material.quantity}}</td>
                                            <td>{{material.cost | number:'1.0':'en-US'}}</td>
                                        </tr>
                                        <tr style="height: 12px;"></tr>
                                        <tr>
                                            <td colspan="2">TOTAL COST</td>
                                            <td>{{packagingFirstVersion.total | number:'1.0':'en-US'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="container__sales-price">
                    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
                        <p-accordionTab iconPos="end">
                            <ng-template pTemplate="header" >
                                <table class="table__accordion-header">
                                    <tr>
                                        <td>
                                            Sales Price
                                        </td>
                                        <td>
                                            {{salesPriceFirstVersion.sale_price | number:'1.0':'en-US'}}
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <p-divider styleClass="divider-style" ></p-divider>
                            <div class="container__sales-price-body">
                                <table id="sales-price">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Sale Price
                                                </th>
                                                <th>
                                                    Total Cost
                                                </th>
                                                <th>
                                                    Margin
                                                </th>
                                                <th>
                                                    Margin(%)
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{salesPriceFirstVersion.sale_price|number:'1.0':'en-US'}}</td>
                                                <td>{{salesPriceFirstVersion.total_cost|number:'1.0':'en-US'}}</td>
                                                <td>{{salesPriceFirstVersion.margin|number:'1.0':'en-US'}}</td>
                                                <td>{{salesPriceFirstVersion.margin_precentage}}%</td>
                                            </tr>
                                        
                                        </tbody>
                                </table>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>
        </div>
        <div class="w-full mb-3 container__version2">
            <div>
                <p>Version 2</p>
                <div class="container__manufacturing">
                    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
                        <p-accordionTab iconPos="end">
                            <ng-template pTemplate="header" >
                                <table class="table__accordion-header">
                                    <tr>
                                        <td>
                                            Manufacturing
                                        </td>
                                        <td>
                                            {{manufacturingSecondVersion.total | number:'1.0':'en-US'}}
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <p-divider styleClass="divider-style" ></p-divider>
                            <table id="manufacturing">
                                <thead>
                                    <tr>
                                        <th>
                                            Item
                                        </th>
                                        <th>
                                            Cost
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Depreciation</td>
                                        <td>{{manufacturingSecondVersion.depreciation | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Energy</td>
                                        <td>{{manufacturingSecondVersion.energy | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Gas</td>
                                        <td>{{manufacturingSecondVersion.gas | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Maintance</td>
                                        <td>{{manufacturingSecondVersion.mmtto | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Direct Labour</td>
                                        <td>{{manufacturingSecondVersion.mod | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>MOI and other CIF</td>
                                        <td>{{manufacturingSecondVersion.cif | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>External Manufacturing</td>
                                        <td>{{manufacturingSecondVersion.external_manufacturing | number:'1.0':'en-US'}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table id="total">
                                <thead>
                                    <tr>
                                        <th>TOTAL COST</th>
                                        <th>{{manufacturingSecondVersion.total | number:'1.0':'en-US'}}</th>
                                    </tr>
                                </thead>
                            </table>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="container__raw-materials">
                    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
                        <p-accordionTab iconPos="end">
                            <ng-template pTemplate="header" >
                                <table class="table__accordion-header">
                                    <tr>
                                        <td>
                                            Raw Material
                                        </td>
                                        <td>
                                            {{(rawMaterialSecondVersion.total_material_1+rawMaterialSecondVersion.total_material_2) | number:'1.0':'en-US'}}
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <p-divider styleClass="divider-style" ></p-divider>
                            <div class="container__raw-materials-body">
                                <table id="raw-materials">
                                    <thead>
                                        <tr>
                                            <th>
                                                Material 1
                                            </th>
                                            <th>
                                                Porcentage
                                            </th>
                                            <th>
                                                Cost
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let material of rawMaterialSecondVersion.material_1" >
                                            <td>{{material.name}}</td>
                                            <td>{{material.percentage | number:'1.1-1':'en-US'}}%</td>
                                            <td>{{material.cost | number:'1.0':'en-US'}}</td>
                                        </tr>
                                        <tr style="height: 12px;"></tr>
                                        <tr>
                                            <td colspan="2">TOTAL COST</td>
                                            <td>{{rawMaterialSecondVersion.total_material_1 | number:'1.0':'en-US'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style="height: 20px;"></div>
                                <table id="raw-materials">
                                    <thead>
                                        <tr>
                                            <th>
                                                Material 2
                                            </th>
                                            <th>
                                                Porcentage
                                            </th>
                                            <th>
                                                Cost
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let material of rawMaterialSecondVersion.material_2" >
                                            <td>{{material.name}}</td>
                                            <td>{{material.percentage | number:'1.1-1':'en-US'}}%</td>
                                            <td>{{material.cost | number:'1.0':'en-US'}}</td>
                                        </tr>
                                        <tr style="height: 12px;"></tr>
                                        <tr>
                                            <td colspan="2">TOTAL COST</td>
                                            <td>{{rawMaterialSecondVersion.total_material_2 | number:'1.0':'en-US'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="container__packaging">
                    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
                        <p-accordionTab iconPos="end">
                            <ng-template pTemplate="header" >
                                <table class="table__accordion-header">
                                    <tr>
                                        <td>
                                            Packaging
                                        </td>
                                        <td>
                                            {{packagingSecondVersion.total | number:'1.0':'en-US'}}
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <p-divider styleClass="divider-style" ></p-divider>
                            <table id="packaging">
                                    <thead>
                                        <tr>
                                            <th>
                                                Item
                                            </th>
                                            <th>
                                                Qty
                                            </th>
                                            <th>
                                                Cost
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let material of packagingSecondVersion.materials" >
                                            <td>{{material.name}}</td>
                                            <td>{{material.quantity}}</td>
                                            <td>{{material.cost | number:'1.0':'en-US'}}</td>
                                        </tr>
                                        <tr style="height: 12px;"></tr>
                                        <tr>
                                            <td colspan="2">TOTAL COST</td>
                                            <td>{{packagingSecondVersion.total | number:'1.0':'en-US'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="container__sales-price">
                    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
                        <p-accordionTab iconPos="end">
                            <ng-template pTemplate="header" >
                                <table class="table__accordion-header">
                                    <tr>
                                        <td>
                                            Sales Price
                                        </td>
                                        <td>
                                            {{salesPriceSecondVersion.sale_price | number:'1.0':'en-US'}}
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <p-divider styleClass="divider-style" ></p-divider>
                            <div class="container__sales-price-body">
                                <table id="sales-price">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Sale Price
                                                </th>
                                                <th>
                                                    Total Cost
                                                </th>
                                                <th>
                                                    Margin
                                                </th>
                                                <th>
                                                    Margin(%)
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{salesPriceSecondVersion.sale_price|number:'1.0':'en-US'}}</td>
                                                <td>{{salesPriceSecondVersion.total_cost|number:'1.0':'en-US'}}</td>
                                                <td>{{salesPriceSecondVersion.margin|number:'1.0':'en-US'}}</td>
                                                <td>{{salesPriceSecondVersion.margin_precentage}}%</td>
                                            </tr>
                                        
                                        </tbody>
                                </table>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>  
            </div>
        </div>
        <div class="w-full mb-3 container__version3">
            <div>
                <p>Version 3</p>
                <div class="container__manufacturing">
                    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
                        <p-accordionTab iconPos="end">
                            <ng-template pTemplate="header" >
                                <table class="table__accordion-header">
                                    <tr>
                                        <td>
                                            Manufacturing
                                        </td>
                                        <td>
                                            {{manufacturingThirdVersion.total | number:'1.0':'en-US'}}
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <p-divider styleClass="divider-style" ></p-divider>
                            <table id="manufacturing">
                                <thead>
                                    <tr>
                                        <th>
                                            Item
                                        </th>
                                        <th>
                                            Cost
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Depreciation</td>
                                        <td>{{manufacturingThirdVersion.depreciation | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Energy</td>
                                        <td>{{manufacturingThirdVersion.energy | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Gas</td>
                                        <td>{{manufacturingThirdVersion.gas | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Maintance</td>
                                        <td>{{manufacturingThirdVersion.mmtto | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Direct Labour</td>
                                        <td>{{manufacturingThirdVersion.mod | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>MOI and other CIF</td>
                                        <td>{{manufacturingThirdVersion.cif | number:'1.0':'en-US'}}</td>
                                    </tr>
                                    <tr>
                                        <td>External Manufacturing</td>
                                        <td>{{manufacturingThirdVersion.external_manufacturing | number:'1.0':'en-US'}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table id="total">
                                <thead>
                                    <tr>
                                        <th>TOTAL COST</th>
                                        <th>{{manufacturingThirdVersion.total | number:'1.0':'en-US'}}</th>
                                    </tr>
                                </thead>
                            </table>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="container__raw-materials">
                    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
                        <p-accordionTab iconPos="end">
                            <ng-template pTemplate="header" >
                                <table class="table__accordion-header">
                                    <tr>
                                        <td>
                                            Raw Material
                                        </td>
                                        <td>
                                            {{(rawMaterialThirdVersion.total_material_1+rawMaterialThirdVersion.total_material_2) | number:'1.0':'en-US'}}
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <p-divider styleClass="divider-style" ></p-divider>
                            <div class="container__raw-materials-body">
                                <table id="raw-materials">
                                    <thead>
                                        <tr>
                                            <th>
                                                Material 1
                                            </th>
                                            <th>
                                                Porcentage
                                            </th>
                                            <th>
                                                Cost
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let material of rawMaterialThirdVersion.material_1" >
                                            <td>{{material.name}}</td>
                                            <td>{{material.percentage | number:'1.1-1':'en-US'}}%</td>
                                            <td>{{material.cost | number:'1.0':'en-US'}}</td>
                                        </tr>
                                        <tr style="height: 12px;"></tr>
                                        <tr>
                                            <td colspan="2">TOTAL COST</td>
                                            <td>{{rawMaterialThirdVersion.total_material_1 | number:'1.0':'en-US'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style="height: 20px;"></div>
                                <table id="raw-materials">
                                    <thead>
                                        <tr>
                                            <th>
                                                Material 2
                                            </th>
                                            <th>
                                                Porcentage
                                            </th>
                                            <th>
                                                Cost
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let material of rawMaterialThirdVersion.material_2" >
                                            <td>{{material.name}}</td>
                                            <td>{{material.percentage | number:'1.1-1':'en-US'}}%</td>
                                            <td>{{material.cost | number:'1.0':'en-US'}}</td>
                                        </tr>
                                        <tr style="height: 12px;"></tr>
                                        <tr>
                                            <td colspan="2">TOTAL COST</td>
                                            <td>{{rawMaterialThirdVersion.total_material_1 | number:'1.0':'en-US'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="container__packaging">
                    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
                        <p-accordionTab iconPos="end">
                            <ng-template pTemplate="header" >
                                <table class="table__accordion-header">
                                    <tr>
                                        <td>
                                            Packaging
                                        </td>
                                        <td>
                                            {{packagingThirdVersion.total | number:'1.0':'en-US'}}
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <p-divider styleClass="divider-style" ></p-divider>
                            <table id="packaging">
                                    <thead>
                                        <tr>
                                            <th>
                                                Item
                                            </th>
                                            <th>
                                                Qty
                                            </th>
                                            <th>
                                                Cost
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let material of packagingThirdVersion.materials" >
                                            <td>{{material.name}}</td>
                                            <td>{{material.quantity}}</td>
                                            <td>{{material.cost | number:'1.0':'en-US'}}</td>
                                        </tr>
                                        <tr style="height: 12px;"></tr>
                                        <tr>
                                            <td colspan="2">TOTAL COST</td>
                                            <td>{{packagingThirdVersion.total | number:'1.0':'en-US'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="container__sales-price">
                    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
                        <p-accordionTab iconPos="end">
                            <ng-template pTemplate="header" >
                                <table class="table__accordion-header">
                                    <tr>
                                        <td>
                                            Sales Price
                                        </td>
                                        <td>
                                            {{salesPriceThirdVersion.sale_price | number:'1.0':'en-US'}}
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <p-divider styleClass="divider-style" ></p-divider>
                            <div class="container__sales-price-body">
                                <table id="sales-price">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Sale Price
                                                </th>
                                                <th>
                                                    Total Cost
                                                </th>
                                                <th>
                                                    Margin
                                                </th>
                                                <th>
                                                    Margin(%)
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{salesPriceThirdVersion.sale_price|number:'1.0':'en-US'}}</td>
                                                <td>{{salesPriceThirdVersion.total_cost|number:'1.0':'en-US'}}</td>
                                                <td>{{salesPriceThirdVersion.margin|number:'1.0':'en-US'}}</td>
                                                <td>{{salesPriceThirdVersion.margin_precentage}}%</td>
                                            </tr>
                                        
                                        </tbody>
                                </table>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>
        </div>
    </div>
</div>