import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-view-material-info',
  templateUrl: './view-material-info.component.html',
  styleUrls: ['./view-material-info.component.scss']
})
export class ViewMaterialInfoComponent implements OnInit {

  materiaPrima2List = []
  constructor(private config: DynamicDialogConfig){
    this.materiaPrima2List = config.data
  }

  ngOnInit(): void {
    
  }

}
