import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
// import { getMacroeconomicsValuesOnLocal, saveMacroeconomicsValuesOnLocal } from '@core/utils/local-storage-data';
import { HttpClient } from '@angular/common/http';
import { getCostFeasibilityValuesOnLocal, getMateriaPrima2ListOnLocal, getRand_DValuesOnLocal, saveCostFeasibilityValuesOnLocal, saveMateriaPrima2ListOnLocal, saveRand_DValuesOnLocal } from '@core/utils/local-storage-data';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class CostFeasibilityService {
  constructor(private http: HttpClient,) {
    
  }
  saveCostFeasibility(data:any, params:any): Observable<any>  {
    return this.http.post(`${baseUrl}/api/cost_feasibility`, data, {params: params});
  }

  updateCostFeasibility(data:any, projectID:any, params:any): Observable<any>  {
    return this.http.put(`${baseUrl}/api/cost_feasibility/${projectID}`, data, {params: params});
  }
  
  getCostosInfo(): Observable<any>  {
    return this.http.get(`${baseUrl}/api/costos_pg`);
  }

  getMargenEbitdaInfo(scale:any): Observable<any>  {
    return this.http.get(`${baseUrl}/api/margen/escala?scale=${scale}`);
  }

  getCostFeasibilityOnLocalStorage(){
    return getCostFeasibilityValuesOnLocal();
  }
  saveCostFeasibilityValuesOnLocalStorage(costFeasibilityValue:any){
    return saveCostFeasibilityValuesOnLocal(costFeasibilityValue);
  }

  getCostFeasibilityInfo(projectId:any):  Observable<any> {
    return this.http.get(`${baseUrl}/api/cost_feasibility/${projectId}`);

    // let emptymock = new BehaviorSubject<any>(
    //   {}
    // )
    //   return emptymock.asObservable()
  }

  saveCostFeasibilityVersionInfo(versionId:any, data:any, params:any){
    return this.http.put(`${baseUrl}/api/project_brief_version/${versionId}`, data, {params: params});
  }

}
