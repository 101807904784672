<div class="container__view-mateial">
    <table id="white-header" class="no-color-table">
        <tbody>
            <tr>
                <th>Material</th>
                <th>Txt Materia</th>
                <th>Ref Info</th>
                <th>Net Price</th>
                <th>Currency</th>
                <th>Supplier Co</th>
                <th>Valid From</th>
                <th>Valid To</th>
                <th>MOQ</th>
                <th>Incoterms</th>
            </tr>
            <tr *ngFor="let materiaPrima2 of materiaPrima2List" class=".accordion-table-tr">
                <td >
                    {{materiaPrima2['sapCode'] || materiaPrima2['codigoSap']}}
                </td>
                <td >
                    {{materiaPrima2['nombreMaterial'] || materiaPrima2['rawMaterial'] || materiaPrima2['nombre'] || materiaPrima2['description']}}
                </td>
                <td >
                    {{materiaPrima2['reg_info']}}
                </td>
                <td >
                    {{(materiaPrima2['precio_neto'] || materiaPrima2['costUnd'])|number:'1.0':'en-US'}}
                </td>
                <td >
                    {{materiaPrima2['moneda']}}
                </td>
                <td >
                    {{materiaPrima2['nombre_proveedor'] || materiaPrima2['supplier']}}
                </td>
                <td >
                    {{materiaPrima2['valido_de']}}
                </td>
                <td >
                    {{materiaPrima2['valido_hasta']}}
                </td>
                <td >
                    {{materiaPrima2['cantidad_min_reg_info'] || materiaPrima2['moq']}}
                </td>
                <td >
                    {{materiaPrima2['inco1'] + " " + materiaPrima2['inco2']}}
                </td>
            </tr>
        </tbody>
    </table>
</div>