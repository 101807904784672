<div class="container__view-mateial">
    <p>Raw Material 2</p>
    <table id="white-header" class="no-color-table">
        <tbody>
            <tr>
                <th>Txt Material</th>
                <th>Consumption Quantity</th>
                <th>MOQ</th>
                <th>Excess</th>
                <th>Currency</th>
                <th>Total Scrap</th>
                
            </tr>
            <tr *ngFor="let material of rawMaterialList" class=".accordion-table-tr">
                <td >
                    {{material['nombreMaterial'] || material['rawMaterial'] || material['nombre'] || material['description']}}
                </td>
                <td >
                    {{material['consumption_qty_total']|number:'1.0-0':'en-US'}}
                </td>
                <td >
                    {{(material['cantidad_min_reg_info'] || material['moq'])|number:'1.0-0':'en-US'}}
                </td>
                <td >
                    {{(((material['cantidad_min_reg_info'] || material['moq']) - (material['consumption_qty_total']))>0?((material['cantidad_min_reg_info'] || material['moq']) - (material['consumption_qty_total'])):0)|number:'1.0-0':'en-US'}}
                </td>
                <td >
                    {{material["moneda"]}}
                </td>
                <td >
                    {{((((material['cantidad_min_reg_info'] || material['moq']) - (material['consumption_qty_total']))>0?((material['cantidad_min_reg_info'] || material['moq']) - (material['consumption_qty_total'])):0) * (material['precio_neto'] || material['costUnd']))|number:'1.0-0':'en-US'}}
                </td>
            </tr>
        </tbody>
    </table>
    <p>Packaging</p>
    <table id="white-header" class="no-color-table">
        <tbody>
            <tr>
                <th>Txt Material</th>
                <th>Consumption Quantity</th>
                <th>MOQ</th>
                <th>Excess</th>
                <th>Currency</th>
                <th>Total Scrap</th>
            </tr>
            <tr *ngFor="let material of packagingList" class=".accordion-table-tr">
                <td >
                    {{material['nombreMaterial'] || material['rawMaterial'] || material['nombre'] || material['description']}}
                </td>
                <td >
                    {{material['consumption_qty_total']|number:'1.0-0':'en-US'}}
                </td>
                <td >
                    {{(material['cantidad_min_reg_info'] || material['moq'])|number:'1.0-0':'en-US'}}
                </td>
                <td >
                    {{(((material['cantidad_min_reg_info'] || material['moq']) - (material['consumption_qty_total']))>0?((material['cantidad_min_reg_info'] || material['moq']) - (material['consumption_qty_total'])):0)|number:'1.0-0':'en-US'}}
                </td>
                <td >
                    {{material["moneda"]}}
                </td>
                <td >
                    {{((((material['cantidad_min_reg_info'] || material['moq']) - (material['consumption_qty_total']))>0?((material['cantidad_min_reg_info'] || material['moq']) - (material['consumption_qty_total'])):0) * (material['precio_neto'] || material['costUnd']))|number:'1.0-0':'en-US'}}
                </td>
            </tr>
        </tbody>
    </table>
</div>