<p-toast *ngIf="success" styleClass="toast-message-success" [life]="3000">
    <ng-template let-message pTemplate="message">
        <div class="flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_4245_3980)">
                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#42AC10"/>
                </g>
                <defs>
                <clipPath id="clip0_4245_3980">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
            </svg>

            {{message.detail}}
        </div>
    </ng-template>
</p-toast>

<p-toast *ngIf="!success" styleClass="toast-message-fail" [life]="3000">
    <ng-template let-message pTemplate="message">
        <div class="flex align-items-center">

            {{message.detail}}
        </div>
    </ng-template>
</p-toast>