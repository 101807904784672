import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
// import { getMacroeconomicsValuesOnLocal, saveMacroeconomicsValuesOnLocal } from '@core/utils/local-storage-data';
import { HttpClient } from '@angular/common/http';
import { getMateriaPrima2ListOnLocal, getRand_DValuesOnLocal, saveMateriaPrima2ListOnLocal, saveRand_DValuesOnLocal } from '@core/utils/local-storage-data';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class RandDService {
  constructor(private http: HttpClient,) {
    
  }
  saveRandD(data:any, params:any): Observable<any>  {
    return this.http.post(`${baseUrl}/api/r_and_d`, data, {params: params});
  }

  updateRandD(data:any, projectID:any, params:any): Observable<any>  {
    return this.http.put(`${baseUrl}/api/r_and_d/${projectID}`, data, {params: params});
  }
  //retrieve chocolates by filter
  filterChocolate(filter:string): Observable<any>  {
    return this.http.get(`${baseUrl}/api/filtro_chocolate?texto=${filter}`);
  }
  //retrieve materia prima1 by filter
  filterMateriaPrima1(filter:string): Observable<any>  {
    return this.http.get(`${baseUrl}/api/lista_material_filtrar/filtrar?nombreComponente=${filter}`);
  }
  //retrieve materia prima2 by filter
  filterMateriaPrima2(filter:string): Observable<any>  {
    return this.http.get(`${baseUrl}/api/registro_info_consult/filtrar?txt_material=${filter}`);
  }

  //retrieve chocolate info by sap code
  getChocholateBySapCode(sapCode:string): Observable<any>  {
    return this.http.get(`${baseUrl}/api/chocolate_filtrar/${sapCode}`);
  }

  //retrieve materia prima 1 info by sap code
  getMateriaPrima1BySapCode(sapCode:string): Observable<any>  {
    return this.http.get(`${baseUrl}/api//lista_material/componente/${sapCode}`);
  
  }

  //retrieve materia prima 2 info by sap code
  getMateriaPrima2BySapCode(sapCode:string): Observable<any>  {
    return this.http.get(`${baseUrl}/api/registros_info/material/${sapCode}`);
    
  }

  getPvofInfo(): Observable<any>  {
    return this.http.get(`${baseUrl}/api/pvof`);
    
  }

  getRecentRawMaterial(scales: any) {
    // eslint-disable-next-line prefer-spread
    let mostRecentValidSince = Math.max.apply(
      Math,
      scales?.map(function (emp: any) {
        return emp.valido_de||"";
      })
    );
    return mostRecentValidSince;
  }

  
  //retrieve list of materia prima 1 for chocolate's recipe by sap code
  getMateriaPrima1ForChocolate(sapCode:string): Observable<any>  {
    return this.http.get(`${baseUrl}/api/lista_material/raw_material_one?material=${sapCode}&inclusionPercentage=0`);
    
  }
  //retrieve list of materia prima 2 for chocolate's recipe by sap code
  getMateriaPrima2ForChocolate(sapCode:string): Observable<any>  {
    return this.http.get(`${baseUrl}/api/lista_material/raw_material_two?material=${sapCode}&scale=0&inclusionPercentage=0`);
  }

  filterRawMaterials(filter:string, materialType: string): Observable<any>{
    if(materialType=='Chocolate'){
      return this.http.get(`${baseUrl}/api/filtro_chocolate_material?texto=${filter}`);
    } else if (materialType=='Materia Prima 1'){
      return this.http.get(`${baseUrl}/api/lista_material_filtrar/filtrar?nombreComponente=${filter}`);
    }
    return this.http.get(`${baseUrl}/api/registro_info_consult/filtrar?txt_material=${filter}`);
  }

  getMateriaPrima2(){
    return getMateriaPrima2ListOnLocal();
  }
  saveMateriaPrima2OnLocalStorage(materialPrima2List:any){
    return saveMateriaPrima2ListOnLocal(materialPrima2List);
  }

  getRand_DValuesOnLocalStorage(){
    return getRand_DValuesOnLocal();
  }
  saveRand_DValuesOnLocalStorage(rand_dValue:any){
    return saveRand_DValuesOnLocal(rand_dValue);
  }

  getRandDInfo(projectId:any):  Observable<any> {
    return this.http.get(`${baseUrl}/api/r_and_d/${projectId}`);

    // let emptymock = new BehaviorSubject<any>(
    //   {}
    // )
    //   return emptymock.asObservable()
  }

  getUsers(){
    return this.http.get(`${baseUrl}/api/usertype?types=RYD_ADMIN_SUPERADMIN`);
    // return this.http.get(`${baseUrl}/api/users`);
  }

  saveR_and_dVersionInfo(versionId:any, data:any){
    return this.http.put(`${baseUrl}/api/project_brief_version/${versionId}`, data);
  }

}
