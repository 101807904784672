import { environment } from '@env/environment';

/**
 * List of local storage keys
 */
export enum LocalStorageKeys {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  USER_TYPE = 'user_type',
  USER_DETAILS = 'user_detail',
  USER_ID = 'user_id',
  PROJECT_BRIEF_DATA= 'project_brief',
  PROJECT_ID= 'project_id',
  PROJECT_BRIEF_ID= 'project_brief_id',
  MACROECONOMICS_DATA= 'macro_economics',
  MATERIA_PRIMA_2='materia_prima_2',
  R_AND_D='r_and_d',
  PACKAGING='packaging',
  PROCESSES='processes',
  ALLIES='allies',
  EXCESS='excess',
  COST_FEASIBILITY='cost_feasibility',
  FOLDER='folder',
  BUSINESS_UNIT='business_unit',
  VERSION='version',
  CURRENT_MODULE_INDEX='current_module_index',
  EDITED_STEP= 'edited_step'
}

const prefix = '@WebApp:';
const prodEnv = environment.production;
const secretKey = 'a-quick-brown-fox-jumps-over-the-lazy-dog';

export const GetToken = () =>
  getDataFromLocalStorage(LocalStorageKeys.ACCESS_TOKEN);
export const SetToken = (token: string) =>
  saveDataToLocalStorage(LocalStorageKeys.ACCESS_TOKEN, token);

export const GetRefreshToken = () =>
  getDataFromLocalStorage(LocalStorageKeys.REFRESH_TOKEN);
export const SetRefreshToken = (token: string) =>
  saveDataToLocalStorage(LocalStorageKeys.REFRESH_TOKEN, token);

export const GetUserType = () =>
  getDataFromLocalStorage(LocalStorageKeys.USER_TYPE);
export const SetUserType = (user_type: string) =>
  saveDataToLocalStorage(LocalStorageKeys.USER_TYPE, user_type);

export const GetUserId = () =>
  getDataFromLocalStorage(LocalStorageKeys.USER_ID);
export const SetUserId = (user_id: string) =>
  saveDataToLocalStorage(LocalStorageKeys.USER_ID, user_id);

export const GetUserDetail = () =>
  getDataFromLocalStorage(LocalStorageKeys.USER_DETAILS);
export const SetUserDetail = (userDetail: any) =>
  saveDataToLocalStorage(LocalStorageKeys.USER_DETAILS, userDetail);
export const ClearStorage = () => clearStorage();

export const RemoveAuthData = () => {
  for (const key in LocalStorageKeys) {
    removeDataFromLocalStorage(key);
  }
};

export const RemoveProjectData = () => {
  removeDataFromSessionlStorage(LocalStorageKeys.PROJECT_BRIEF_DATA)
  removeDataFromSessionlStorage(LocalStorageKeys.PROJECT_ID)
  removeDataFromSessionlStorage(LocalStorageKeys.PROJECT_BRIEF_ID)
  removeDataFromSessionlStorage(LocalStorageKeys.MACROECONOMICS_DATA)
  removeDataFromSessionlStorage(LocalStorageKeys.R_AND_D)
  removeDataFromSessionlStorage(LocalStorageKeys.PACKAGING)
  removeDataFromSessionlStorage(LocalStorageKeys.PROCESSES)
  removeDataFromSessionlStorage(LocalStorageKeys.ALLIES)
  removeDataFromSessionlStorage(LocalStorageKeys.EXCESS)
  removeDataFromSessionlStorage(LocalStorageKeys.COST_FEASIBILITY)
};

export const getProjectIdOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.PROJECT_ID);
export const saveProjectIdOnLocal = (projectId:any) =>
saveDataToSessionStorage(LocalStorageKeys.PROJECT_ID, projectId);

export const getProjectBriefIdOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.PROJECT_BRIEF_ID);
export const saveProjectBriefIdOnLocal = (projectBriefId:any) =>
saveDataToSessionStorage(LocalStorageKeys.PROJECT_BRIEF_ID, projectBriefId);

export const getProjectBriefValuesOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.PROJECT_BRIEF_DATA);
export const saveProjectBriefValuesOnLocal = (projectBriefValues:any) =>
saveDataToSessionStorage(LocalStorageKeys.PROJECT_BRIEF_DATA, projectBriefValues);

export const getMacroeconomicsValuesOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.MACROECONOMICS_DATA);
export const saveMacroeconomicsValuesOnLocal = (macroeconomicsValues:any) =>
saveDataToSessionStorage(LocalStorageKeys.MACROECONOMICS_DATA, macroeconomicsValues);

export const getMateriaPrima2ListOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.MATERIA_PRIMA_2);
export const saveMateriaPrima2ListOnLocal = (materiaPrima2List:any) =>
saveDataToSessionStorage(LocalStorageKeys.MATERIA_PRIMA_2, materiaPrima2List);

export const getRand_DValuesOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.R_AND_D);
export const saveRand_DValuesOnLocal = (rand_dValue:any) =>
saveDataToSessionStorage(LocalStorageKeys.R_AND_D, rand_dValue);

export const getPackagingValuesOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.PACKAGING);
export const savePackagingValuesOnLocal = (packagingValue:any) =>
saveDataToSessionStorage(LocalStorageKeys.PACKAGING, packagingValue);

export const getProcessesValuesOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.PROCESSES);
export const saveProcessesValuesOnLocal = (processesValue:any) =>
saveDataToSessionStorage(LocalStorageKeys.PROCESSES, processesValue);

export const getAlliesValuesOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.ALLIES);
export const saveAlliesValuesOnLocal = (alliesValue:any) =>
saveDataToSessionStorage(LocalStorageKeys.ALLIES, alliesValue);

export const getExcessValuesOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.EXCESS);
export const saveExcessValuesOnLocal = (excessValue:any) =>
saveDataToSessionStorage(LocalStorageKeys.EXCESS, excessValue);

export const getCostFeasibilityValuesOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.COST_FEASIBILITY);
export const saveCostFeasibilityValuesOnLocal = (CostFeasibilityValue:any) =>
saveDataToSessionStorage(LocalStorageKeys.COST_FEASIBILITY, CostFeasibilityValue);

export const getFolderValuesOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.FOLDER);
export const saveFolderValuesOnLocal = (fodlersValue:any) =>
saveDataToSessionStorage(LocalStorageKeys.FOLDER, fodlersValue);

export const getBusinessUnitValuesOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.BUSINESS_UNIT);
export const saveBusinessUnitValuesOnLocal = (businessUnitValues:any) =>
saveDataToSessionStorage(LocalStorageKeys.BUSINESS_UNIT, businessUnitValues);

export const getCurrentVersionOnLocal = () =>
    getDataFromSessionStorage(LocalStorageKeys.VERSION);
export const saveCurrentVersionOnLocal = (versionValues:any) =>
saveDataToSessionStorage(LocalStorageKeys.VERSION, versionValues);

export const getCurrentModuleIndex = () =>
    getDataFromSessionStorage(LocalStorageKeys.CURRENT_MODULE_INDEX);
export const saveCurrentModuleIndex = (moduleIndex:any) =>
saveDataToSessionStorage(LocalStorageKeys.CURRENT_MODULE_INDEX, moduleIndex);

export const getEditedStep = () =>
    getDataFromSessionStorage(LocalStorageKeys.EDITED_STEP);
export const updateEditedStep = (editedStep:boolean) =>
saveDataToSessionStorage(LocalStorageKeys.EDITED_STEP, editedStep);

const xorWithSecretKey = (text: string, secret: string): string => {
  let result = '';
  for (let i = 0; i < text.length; i++) {
    result += String.fromCharCode(
      text.charCodeAt(i) ^ secret.charCodeAt(i % secret.length)
    );
  }
  return result;
};

const saveDataToLocalStorage = (key: any, value: any) => {
  // if (prodEnv) {
  //   key = btoa(prefix + key.toString()); // Encode key as base64
  //   key = xorWithSecretKey(key, secretKey); // XOR the key with the secret key
  //   value = btoa(JSON.stringify(value)); // Encode value as base64
  //   value = xorWithSecretKey(value, secretKey); // XOR the value with the secret key
  // }
  localStorage.setItem(key, JSON.stringify(value));
};

const getDataFromLocalStorage = (key: any) => {
  // if (prodEnv) {
  //   key = btoa(prefix + key.toString()); // Encode key as base64
  //   key = xorWithSecretKey(key, secretKey); // XOR the key with the secret key
  //   const data = localStorage.getItem(key);
  //   const decodedValue = xorWithSecretKey(data!, secretKey); // XOR the value with the secret key
  //     return JSON.parse(atob(decodedValue)); // Decode base64 value
  // }
  const data = localStorage.getItem(key);
  return JSON.parse(data!);
};

const removeDataFromLocalStorage = (key: any) => {
  // if (prodEnv) {
  //   key = btoa(prefix + key.toString()); // Encode key as base64
  //   key = xorWithSecretKey(key, secretKey); // XOR the key with the secret key
  // }
  localStorage.removeItem(key);
};

const saveDataToSessionStorage = (key: any, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

const getDataFromSessionStorage = (key: any) => {
  const data = sessionStorage.getItem(key);
  return JSON.parse(data!);
};

const removeDataFromSessionlStorage = (key: any) => {
  sessionStorage.removeItem(key);
};

const clearStorage = () => localStorage.clear();
