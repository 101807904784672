import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss']
})
export class VersionInfoComponent implements OnInit{

  @Input()
  manufacturing:any = {
    total:0,
    energy:0,
    mmtto:0,
    mod:0,
    depreciation:0,
    gas:0,
    cif:0,
    external_manufacturing:0
  }


  

  constructor() {
  }

  ngOnInit(): void {
  }

}
