<div>

  <p-table [value]="tableData" [tableStyle]="{ 'min-width': 'auto' }" styleClass="p-datatable-sm">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" *ngFor="let header of columns">{{ header.name }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
    <tr *ngIf="forMacroeconomics">
      <td *ngFor="let rowProperty of getRowDataProperties(row)">{{ dataValue(row[rowProperty]) | number:'1.0':'en-US' }}</td>
    </tr>
    <tr *ngIf="!forMacroeconomics">
      <td >{{ row.categoria }}</td>
      <td >{{ row.codigo }}</td>
      <td >{{ row.texto }}</td>
      <td >{{ row.valor }}</td>
    </tr>
  </ng-template>
</p-table>

</div>