<div class="container">
    <header class="flex justify-between container__title">
        <p class="flex title">Project Summary</p>
        <div *ngIf="isViewMorePress==false" class="flex items-center container__view-more-button-right" (click)="collapseProjectSummaryContainer()">
            <span class="view-text" >View more</span>
            <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.203 16.5141L4.82803 10.1391C4.3874 9.69844 4.3874 8.98594 4.82803 8.55L5.8874 7.49063C6.32803 7.05 7.04053 7.05 7.47646 7.49063L11.9952 12.0094L16.514 7.49063C16.9546 7.05 17.6671 7.05 18.103 7.49063L19.1624 8.55C19.603 8.99062 19.603 9.70313 19.1624 10.1391L12.7874 16.5141C12.3562 16.9547 11.6437 16.9547 11.203 16.5141Z" fill="#4D5154"/>
            </svg> 
        </div>
    </header>
    <main class="container__main">
        <div class="container__info viewless" #ContainerInfo>
            <section class="container__section-basic-info">
                <p class="basic-info">Basic Info</p>
                <div class="table-desktop">
                    <table>
                        <thead>
                            <tr>
                                <th>Project</th>
                                <th>Sales Volume (Kg)</th>
                                <th>Client</th>
                                <!-- <th>Format</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{projectBriefValues.basic_info.project_name}}</td>
                                <td>{{projectBriefValues.basic_info.sales_volume | number:'1.0':'en-US'}}</td>
                                <td>{{projectBriefValues.basic_info.client_name}}</td>
                                <!-- <td>Bar</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-mobile">
                    <table>
                        <thead>
                            <tr>
                                <th>Project</th>
                                <th>Sales Volume (Kg)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{projectBriefValues.basic_info.project_name}}</td>
                                <td>{{projectBriefValues.basic_info.sales_volume | number:'1.0':'en-US'}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="height: 10px;"></div>
                    <table>
                        <thead>
                            <tr>
                                <th>Client</th>
                                <!-- <th>Format</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{projectBriefValues.basic_info.client_name}}</td>
                                <!-- <td>Bar</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section><!--Basic info-->
            <section class="container__section-thenical-info">
                <p>Thechnical info</p>
                <div class="table-desktop">
                    <table>
                        <thead>
                            <tr>
                                <th>Format</th>
                                <th>Presentation</th>
                                <th>Packaging</th>
                                <!-- <th>Client</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{projectBriefValues.technical_info.format}}</td>
                                <td>{{projectBriefValues.technical_info.presentation}}</td>
                                <td>{{projectBriefValues.technical_info.packaging}}</td>
                                <!-- <td>Imagine</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-mobile">
                    <table>
                        <thead>
                            <tr>
                                <th>Format</th>
                                <th>Presentation</th>
                                <!-- <th>Client</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{projectBriefValues.technical_info.format}}</td>
                                <td>{{projectBriefValues.technical_info.presentation}}</td>
                                <!-- <td>Imagine</td> -->
                            </tr>
                        </tbody>
                    </table>
                    <div style="height: 10px;"></div>
                    <table>
                        <thead>
                            <tr>
                                <th>Packaging</th>
                                <!-- <th>Client</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{projectBriefValues.technical_info.packaging}}</td>
                                <!-- <td>Imagine</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section><!--Thenical Info-->
            <section class="container__section-delivery">
                <p>Delivery</p>
                <div class="table-desktop">
                    <table>
                        <thead>
                            <tr>
                                <th>Inconterm</th>
                                <th>Container Size</th>
                                <th>Number of containers</th>
                                <th>Container type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{projectBriefValues.delivery.incoterm}}</td>
                                <td>{{projectBriefValues.delivery.container_size}}</td>
                                <td>{{projectBriefValues.delivery.containers_qty}}</td>
                                <td>{{projectBriefValues.delivery.container_type}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="height: 15px;"></div>
                    <table >
                        <thead>
                            <tr>
                                <th>Destination</th>
                                <th>City</th>
                                <th>Delivery Port</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{projectBriefValues.delivery.destination_country}}</td>
                                <td>{{projectBriefValues.delivery.city}}</td>
                                <td>{{projectBriefValues.delivery.delivery_port}}</td>
                                <td>{{projectBriefValues.delivery.address}} &nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-mobile">
                    <table>
                        <thead>
                            <tr>
                                <th>Inconterm</th>
                                <th>Container Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{projectBriefValues.delivery.incoterm}}</td>
                                <td>{{projectBriefValues.delivery.container_size}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="height: 10px;"></div>
                    <table>
                        <thead>
                            <tr>
                                <th>Number of containers</th>
                                <th>Container type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{projectBriefValues.delivery.containers_qty}}</td>
                                <td>{{projectBriefValues.delivery.container_type}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="height: 10px;"></div>
                    <table >
                        <thead>
                            <tr>
                                <th>Destination</th>
                                <th>City</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{projectBriefValues.delivery.destination_country}}</td>
                                <td>{{projectBriefValues.delivery.city}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="height: 10px;"></div>
                    <table >
                        <thead>
                            <tr>
                                <th>Delivery Port</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{projectBriefValues.delivery.delivery_port}}</td>
                                <td>{{projectBriefValues.delivery.address}} &nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section><!--Delivery-->
        </div>
        </main>
            <div *ngIf="isViewMorePress==true" class="flex items-cente container__view-more-button" (click)="collapseProjectSummaryContainer()">
                <span class="view-text" >View less</span>
                <!-- <img class="view-more" *ngIf="isViewMorePress==false" src="../../../../assets/images/project-summary/arrowdownicon.svg" alt="arrow-down-icon"> -->
                <svg style="cursor:pointer" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 15.1111C5.5 14.8704 5.58659 14.662 5.75977 14.4861L11.8848 8.26389C12.0579 8.08796 12.263 8 12.5 8C12.737 8 12.9421 8.08796 13.1152 8.26389L19.2402 14.4861C19.4134 14.662 19.5 14.8704 19.5 15.1111C19.5 15.3519 19.4134 15.5602 19.2402 15.7361C19.0671 15.912 18.862 16 18.625 16L6.375 16C6.13802 16 5.93294 15.912 5.75977 15.7361C5.58659 15.5602 5.5 15.3519 5.5 15.1111Z" fill="#4D5154"/>
                </svg> 
                <!-- <svg *ngIf="isViewMorePress==true" width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.53086 4.99068L12.7809 9.24068C13.0746 9.53443 13.0746 10.0094 12.7809 10.3001L12.0746 11.0063C11.7809 11.3001 11.3059 11.3001 11.0152 11.0063L7.99961 7.99693L4.98711 11.0094C4.69336 11.3032 4.21836 11.3032 3.92773 11.0094L3.21836 10.3032C2.92461 10.0094 2.92461 9.53443 3.21836 9.2438L7.46836 4.9938C7.76211 4.69693 8.23711 4.69693 8.53086 4.99068Z" fill="#4D5154"/>
                </svg>  -->
                <!-- <img class="view-less" *ngIf="isViewMorePress==true" src="../../../../assets/images/project-summary/arrowUpIcon.svg" alt="arrow-down-icon"> -->
            </div>
</div>