<div *ngIf="selected" class="container__selected">

    <div class="text-center align-middle container__title-selected">
        {{title}}
        
    </div>
    <div class="flex items-center container__image">
        <img src="{{image}}" alt="alternatetext">
    </div>
    
</div>
<div *ngIf="!selected" class="container__notselected">

    <div class="text-center align-middle container__title-notselected">
        {{title}}
        
    </div>
    <div class="flex items-center container__image">
            <img src="{{image}}" alt="{{image}}">

    </div>
    
</div>