import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AlliesService } from '@core/services/home/allies/allies.service';
import { CostFeasibilityService } from '@core/services/home/costFeasibility/costFeasibility.service';
import { FoldersService } from '@core/services/home/folders/folders.service';
import { MacroeconomicsService } from '@core/services/home/macroeconomics/macroeconomics.service';
import { PackagingService } from '@core/services/home/packaging/packaging.service';
import { ProcessesService } from '@core/services/home/processes/processes.service';
import { RandDService } from '@core/services/home/rand-d/rand-d.service';
import { getFolderValuesOnLocal } from '@core/utils';
import { lastValueFrom, tap } from 'rxjs';

@Component({
  selector: 'app-compare-versions',
  templateUrl: './compare-versions.component.html',
  styleUrls: ['./compare-versions.component.scss']
})
export class CompareVersionsComponent implements OnInit {

  constructor(
    private _location: Location,
    private foldersService: FoldersService,
    private processesService:ProcessesService,
    private alliesService:AlliesService,
    private rand_dService:RandDService,
    private packagingService:PackagingService,
    private costFeasibilityService:CostFeasibilityService,
    private macroeconomicsService:MacroeconomicsService
    ){
  }

  folder_id=""

  selectedProject:any=""

  projects:any=[]
  versions:any=[]
  firstVersionSelected=""
  secondVersionSelected=""
  thirdVersionSelected=""
  dolarTRM=0

  manufacturingFirstVersion= {
    total:0,
    energy:0,
    mmtto:0,
    mod:0,
    depreciation:0,
    gas:0,
    cif:0,
    external_manufacturing:0
  }

  rawMaterialFirstVersion:any = {
    material_1: [],
    total_material_1:0,
    material_2: [],
    total_material_2:0

  }

  packagingFirstVersion:any = {
    materials:[],
    total:0
  }

  salesPriceFirstVersion = {
    sale_price:0,
    total_cost:0,
    margin:0,
    margin_precentage:0
  }

  firstVersionData={
    manufacturing: {
      total:0,
      energy:0,
      mmtto:0,
      mod:0,
      depreciation:0,
      gas:0,
      cif:0,
      external_manufacturing:0
    },
    raw_materials: {},
    packaging:{},
    sales_price:{}
  }

  manufacturingSecondVersion= {
    total:0,
    energy:0,
    mmtto:0,
    mod:0,
    depreciation:0,
    gas:0,
    cif:0,
    external_manufacturing:0
  }

  rawMaterialSecondVersion:any = {
    material_1: [],
    total_material_1:0,
    material_2: [],
    total_material_2:0

  }

  packagingSecondVersion:any = {
    materials:[],
    total:0
  }

  salesPriceSecondVersion = {
    sale_price:0,
    total_cost:0,
    margin:0,
    margin_precentage:0
  }

  secondVersionData={
    manufacturing: {
      total:0,
      energy:0,
      mmtto:0,
      mod:0,
      depreciation:0,
      gas:0,
      cif:0,
      external_manufacturing:0
    },
    raw_materials: {},
    packaging:{},
    sales_price:{}
  }

  manufacturingThirdVersion= {
    total:0,
    energy:0,
    mmtto:0,
    mod:0,
    depreciation:0,
    gas:0,
    cif:0,
    external_manufacturing:0
  }

  rawMaterialThirdVersion:any = {
    material_1: [],
    total_material_1:0,
    material_2: [],
    total_material_2:0

  }

  packagingThirdVersion:any = {
    materials:[],
    total:0
  }

  salesPriceThirdVersion = {
    sale_price:0,
    total_cost:0,
    margin:0,
    margin_precentage:0
  }

  thirdVersionData={
    manufacturing: {
      total:0,
      energy:0,
      mmtto:0,
      mod:0,
      depreciation:0,
      gas:0,
      cif:0,
      external_manufacturing:0
    },
    raw_materials: {},
    packaging:{},
    sales_price:{}
  }
  

  async ngOnInit() {
    this.getFolderId()
    this.setProjects()
  }

  async getMacroeconomicsInfo(projectId:any){
    return await lastValueFrom(this.macroeconomicsService.getMacroeconomicsInfo(projectId).pipe(
      (tap( response => {

      }

      ))
        ))
  }

  async getDollarTRM(projectId:any){
    let macroeconomicsInfoFromApi = await this.getMacroeconomicsInfo(projectId)
    if (macroeconomicsInfoFromApi!=null) {
      this.dolarTRM = macroeconomicsInfoFromApi.dolar
    } 
  }

  getFolderId(){
    let {_id} = getFolderValuesOnLocal()
    this.folder_id = _id
  }


  goBackToFolder(){
    this._location.back();
  }

  async setProjects(){
    let projectsFromAPI = await this.getProjectsByFolderId()
    if(projectsFromAPI!=null){
      this.projects=projectsFromAPI
    }
  }
  
  async getProjectsByFolderId(){
    return await lastValueFrom(this.foldersService.getProjectsInsideFolder(this.folder_id).pipe(
      (tap( response => {

      }))

      ))
    }
    
  async getVersions(){
    let versionsFromAPI = await this.getVersionsByProjectBriefId(this.selectedProject)
    if(versionsFromAPI!=null){
      this.versions=versionsFromAPI
    }
    this.resetVersionsInfo()
  }

  async getVersionsByProjectBriefId(projectBriefId:any){
    return await lastValueFrom(this.foldersService.getVersionPerProject(projectBriefId).pipe(
      (tap( response => {

      }))

    ))
  }

  async getProcessesData(projectId:any){
    return await lastValueFrom(this.processesService.getProcessesInfo(projectId).pipe(
      (tap( response => {

      }

      ))
        ))

  }

  async getAlliesData(projectId:any){
    return await lastValueFrom(this.alliesService.getAlliesInfo(projectId).pipe(
      (tap( response => {

      }

      ))
        ))

  }

  async getRand_DInfo(projectId:any){
    return await lastValueFrom(this.rand_dService.getRandDInfo(projectId).pipe(
      (tap( response => {
        
      }

      ))
        ))

  }

  async getPackagingData(projectId:any){
    return await lastValueFrom(this.packagingService.getPackagingInfo(projectId).pipe(
      (tap( response => {

      }

      ))
        ))

  }

  async getCostFeasibility(projectId:any){
    return await lastValueFrom(this.costFeasibilityService.getCostFeasibilityInfo(projectId).pipe(
      (tap( response => {

      }

      ))
        ))
  }

  async updateFirstVersion(){
    let manufacturingData:any = {
      total:0,
      energy:0,
      mmtto:0,
      mod:0,
      depreciation:0,
      gas:0,
      cif:0,
      external_manufacturing:0
    }
    let processesInfoFromAPI = await this.getProcessesData(this.firstVersionSelected)
    if (processesInfoFromAPI!=null) {
      if (processesInfoFromAPI.switch_table) {
        manufacturingData = await this.calculateProcessesSubtotal(processesInfoFromAPI.calculate_processes_switch_table, true)
      } else {
        manufacturingData = await this.calculateProcessesSubtotal(processesInfoFromAPI.calculate_processes, false)
      }
    } 
    
    let alliesInfoFromAPI = await this.getAlliesData(this.firstVersionSelected)
    if (alliesInfoFromAPI!=null) {
      manufacturingData.external_manufacturing = await this.calculateAlliesSubtotal(alliesInfoFromAPI)
      manufacturingData.total=manufacturingData.total+manufacturingData.external_manufacturing
    } 
    this.manufacturingFirstVersion=manufacturingData

    let rAndDInfoFromAPI = await this.getRand_DInfo(this.firstVersionSelected)
    
    if (rAndDInfoFromAPI!=null) {
      let materialsList = rAndDInfoFromAPI.materials_list
      let rawmaterialInfo = await this.getRawMaterialInfo(materialsList)
      this.rawMaterialFirstVersion = rawmaterialInfo
    } else {
      this.rawMaterialFirstVersion = {
        material_1: [],
        total_material_1:0,
        material_2: [],
        total_material_2:0
      }
    }

    let packagingInfoFromAPI = await this.getPackagingData(this.firstVersionSelected)
    if (packagingInfoFromAPI!=null) {
      let packagingMaterialsList = packagingInfoFromAPI.create_packaging
      let packagingBulkingMaterialsList = packagingInfoFromAPI.for_bulk_preparation
      let packagingInfo = await this.getPackagingInfo(packagingMaterialsList, packagingBulkingMaterialsList)
      this.packagingFirstVersion = packagingInfo
    } else {
      this.packagingFirstVersion = {
        materials:[],
        total:0
      }
    }
    await this.getDollarTRM(this.firstVersionSelected)
    let costFeasibilityInfoFromAPI = await this.getCostFeasibility(this.firstVersionSelected)

    if (costFeasibilityInfoFromAPI!=null) {
      let gross_margin = (+costFeasibilityInfoFromAPI.ebitda.gross_margin.value)??0
      let sale_price = (+costFeasibilityInfoFromAPI.p_and_l.price_usd_kg)??0
      let total_cost = (+costFeasibilityInfoFromAPI.manufacturing_cost.manufacturing_cost_total.value)??0
      this.salesPriceFirstVersion = {
        sale_price:Math.round(sale_price*this.dolarTRM),
        total_cost:total_cost,
        margin:gross_margin,
        margin_precentage:sale_price==0?0:Math.round((100*gross_margin)/(sale_price*this.dolarTRM))
      }
    } else {
      this.salesPriceFirstVersion = {
        sale_price:0,
        total_cost:0,
        margin:0,
        margin_precentage:0
      }
    }
  }

  async updateSecondVersion(){

    let manufacturingData:any = {
      total:0,
      energy:0,
      mmtto:0,
      mod:0,
      depreciation:0,
      gas:0,
      cif:0,
      external_manufacturing:0
    }
    let processesInfoFromAPI = await this.getProcessesData(this.secondVersionSelected)
    if (processesInfoFromAPI!=null) {
      if (processesInfoFromAPI.switch_table) {
        manufacturingData = await this.calculateProcessesSubtotal(processesInfoFromAPI.calculate_processes_switch_table, true)
      } else {
        manufacturingData = await this.calculateProcessesSubtotal(processesInfoFromAPI.calculate_processes, false)
      }
    } 
    
    let alliesInfoFromAPI = await this.getAlliesData(this.secondVersionSelected)
    if (alliesInfoFromAPI!=null) {
      manufacturingData.external_manufacturing = await this.calculateAlliesSubtotal(alliesInfoFromAPI)
      manufacturingData.total=manufacturingData.total+manufacturingData.external_manufacturing
    } 
    this.manufacturingSecondVersion=manufacturingData

    let rAndDInfoFromAPI = await this.getRand_DInfo(this.secondVersionSelected)
    
    if (rAndDInfoFromAPI!=null) {
      let materialsList = rAndDInfoFromAPI.materials_list
      let rawmaterialInfo = await this.getRawMaterialInfo(materialsList)
      this.rawMaterialSecondVersion = rawmaterialInfo
    } else {
      this.rawMaterialSecondVersion = {
        material_1: [],
        total_material_1:0,
        material_2: [],
        total_material_2:0
      }
    }

    let packagingInfoFromAPI = await this.getPackagingData(this.secondVersionSelected)
    if (packagingInfoFromAPI!=null) {
      let packagingMaterialsList = packagingInfoFromAPI.create_packaging
      let packagingBulkingMaterialsList = packagingInfoFromAPI.for_bulk_preparation
      let packagingInfo = await this.getPackagingInfo(packagingMaterialsList, packagingBulkingMaterialsList)
      this.packagingSecondVersion = packagingInfo
    } else {
      this.packagingSecondVersion = {
        materials:[],
        total:0
      }
    }
    await this.getDollarTRM(this.secondVersionSelected)
    let costFeasibilityInfoFromAPI = await this.getCostFeasibility(this.secondVersionSelected)

    if (costFeasibilityInfoFromAPI!=null) {
      let gross_margin = (+costFeasibilityInfoFromAPI.ebitda.gross_margin.value)??0
      let sale_price = (+costFeasibilityInfoFromAPI.p_and_l.price_usd_kg)??0
      let total_cost = (+costFeasibilityInfoFromAPI.manufacturing_cost.manufacturing_cost_total.value)??0
      this.salesPriceSecondVersion = {
        sale_price:Math.round(sale_price*this.dolarTRM),
        total_cost:total_cost,
        margin:gross_margin,
        margin_precentage:sale_price==0?0:Math.round((100*gross_margin)/(sale_price*this.dolarTRM))
      }
    } else {
      this.salesPriceSecondVersion = {
        sale_price:0,
        total_cost:0,
        margin:0,
        margin_precentage:0
      }
    }

  }
  async updateThirdVersion(){

    let manufacturingData:any = {
      total:0,
      energy:0,
      mmtto:0,
      mod:0,
      depreciation:0,
      gas:0,
      cif:0,
      external_manufacturing:0
    }
    let processesInfoFromAPI = await this.getProcessesData(this.thirdVersionSelected)
    if (processesInfoFromAPI!=null) {
      if (processesInfoFromAPI.switch_table) {
        manufacturingData = await this.calculateProcessesSubtotal(processesInfoFromAPI.calculate_processes_switch_table, true)
      } else {
        manufacturingData = await this.calculateProcessesSubtotal(processesInfoFromAPI.calculate_processes, false)
      }
    } 
    
    let alliesInfoFromAPI = await this.getAlliesData(this.thirdVersionSelected)
    if (alliesInfoFromAPI!=null) {
      manufacturingData.external_manufacturing = await this.calculateAlliesSubtotal(alliesInfoFromAPI)
      manufacturingData.total=manufacturingData.total+manufacturingData.external_manufacturing
    } 
    this.manufacturingThirdVersion=manufacturingData

    let rAndDInfoFromAPI = await this.getRand_DInfo(this.thirdVersionSelected)
    
    if (rAndDInfoFromAPI!=null) {
      let materialsList = rAndDInfoFromAPI.materials_list
      let rawmaterialInfo = await this.getRawMaterialInfo(materialsList)
      this.rawMaterialThirdVersion = rawmaterialInfo
    } else {
      this.rawMaterialThirdVersion = {
        material_1: [],
        total_material_1:0,
        material_2: [],
        total_material_2:0
      }
    }

    let packagingInfoFromAPI = await this.getPackagingData(this.thirdVersionSelected)
    if (packagingInfoFromAPI!=null) {
      let packagingMaterialsList = packagingInfoFromAPI.create_packaging
      let packagingBulkingMaterialsList = packagingInfoFromAPI.for_bulk_preparation
      let packagingInfo = await this.getPackagingInfo(packagingMaterialsList, packagingBulkingMaterialsList)
      this.packagingThirdVersion = packagingInfo
    } else {
      this.packagingThirdVersion = {
        materials:[],
        total:0
      }
    }
    await this.getDollarTRM(this.thirdVersionSelected)
    let costFeasibilityInfoFromAPI = await this.getCostFeasibility(this.thirdVersionSelected)

    if (costFeasibilityInfoFromAPI!=null) {
      let gross_margin = (+costFeasibilityInfoFromAPI.ebitda.gross_margin.value)??0
      let sale_price = (+costFeasibilityInfoFromAPI.p_and_l.price_usd_kg)??0
      let total_cost = (+costFeasibilityInfoFromAPI.manufacturing_cost.manufacturing_cost_total.value)??0
      this.salesPriceThirdVersion = {
        sale_price:Math.round(sale_price*this.dolarTRM),
        total_cost:total_cost,
        margin:gross_margin,
        margin_precentage:sale_price==0?0:Math.round((100*gross_margin)/(sale_price*this.dolarTRM))
      }
    } else {
      this.salesPriceThirdVersion = {
        sale_price:0,
        total_cost:0,
        margin:0,
        margin_precentage:0
      }
    }

  }

  async getPackagingInfo(createPackagingList:any, forBulkPreparationList:any){
    
    let packagingList = []
    let packaging_total = 0
    for (let material of createPackagingList) {
        const materialInfo = {
          name: material.rawMaterial, 
          cost:material.costKg||0, 
          quantity:material.formula||0
        }
        packagingList.push(materialInfo)
        packaging_total=packaging_total+ (material.costKg||0)
    }
    // for (let material of forBulkPreparationList) {
    //     packagingList.push(

    //         {name: material.rawMaterial, cost:material.costKg, quantity:formula}
         
    //     )
    //     packaging_total=packaging_total+ material.costKg
    // }

    return {
      materials:packagingList,
      total:packaging_total
    }
  }

  async getRawMaterialInfo(materialsList:any){
    let material1List = []
    let material2List = []
    let totalMaterial1 = 0
    let totalMaterial2 = 0
    for (let material of materialsList) {

      if (material.typeRawMaterial!="Chocolate") {
        let extraOverweight = material.precio_extra_overweight??0
        if (material.typeRawMaterial=="Materia Prima 1") {
          const materialInfo = {
            name: material.nombreMaterial??material.nombre,
            cost: ((material.costKgFormula+extraOverweight)||0),
            percentage: (material.percentage??0)||0
          }
          material1List.push(materialInfo)
          totalMaterial1=totalMaterial1+((material.costKgFormula+extraOverweight)||0)
        }
        if (material.typeRawMaterial=="Materia Prima 2") {
          const materialInfo = {
            name: material.nombreMaterial??material.nombre,
            cost: ((material.costKgFormula+extraOverweight)||0),
            percentage: (material.percentage??0)||0
          }
          material2List.push(materialInfo)
          totalMaterial2=totalMaterial2+((material.costKgFormula+extraOverweight)||0)
        }
      }
    }
    return {
      material_1: material1List,
      total_material_1:totalMaterial1,
      material_2: material2List,
      total_material_2:totalMaterial2
    }
  }

  async calculateProcessesSubtotal(processesList:any, switch_table:boolean){
    let procesesTotal=0
    let processesEnergyTotal = 0
    let processesMmttoTotal = 0
    let processesModTotal = 0
    let processesDepreciationTotal =0
    let processesGasTotal =0
    let processesCifTotal = 0
    if (switch_table) {
      processesEnergyTotal = processesList.energy
      processesMmttoTotal = processesList.mtto
      processesModTotal = processesList.mod
      processesDepreciationTotal = processesList.depreciation
      processesGasTotal = processesList.gas
      processesCifTotal = processesList.cif
      procesesTotal= processesEnergyTotal + processesMmttoTotal + processesModTotal + processesDepreciationTotal + processesGasTotal + processesCifTotal
    } else {
      for (let material of processesList) {
        procesesTotal=procesesTotal+ (material.subtotal||0)
        processesEnergyTotal = processesEnergyTotal+(material.energy||0)
        processesMmttoTotal = processesMmttoTotal + (material.mmtto||0)
        processesModTotal = processesModTotal + (material.mod||0)
        processesDepreciationTotal = processesDepreciationTotal + (material.depreciation||0)
        processesGasTotal = processesGasTotal + (material.gas||0)
        processesCifTotal = processesCifTotal + (material.cif||0)
    }
    }
  return {
    total:procesesTotal,
    energy:processesEnergyTotal,
    mmtto:processesMmttoTotal,
    mod:processesModTotal,
    depreciation:processesDepreciationTotal,
    gas:processesGasTotal,
    cif:processesCifTotal
  }
  }

  async calculateAlliesSubtotal(alliesInfo:any){
    let alliesTotal = (+alliesInfo.manufacturingCost + 
      +alliesInfo.transportationCostOutgoing + 
      +alliesInfo.transportationCostreturn + 
      +alliesInfo.machineTransportation )
    return alliesTotal
  }

  resetVersionsInfo(){
    this.manufacturingFirstVersion= {
      total:0,
      energy:0,
      mmtto:0,
      mod:0,
      depreciation:0,
      gas:0,
      cif:0,
      external_manufacturing:0
    }
  
    this.rawMaterialFirstVersion = {
      material_1: [],
      total_material_1:0,
      material_2: [],
      total_material_2:0
  
    }
  
    this.packagingFirstVersion = {
      materials:[],
      total:0
    }
  
    this.salesPriceFirstVersion = {
      sale_price:0,
      total_cost:0,
      margin:0,
      margin_precentage:0
    }
  
    this.firstVersionData={
      manufacturing: {
        total:0,
        energy:0,
        mmtto:0,
        mod:0,
        depreciation:0,
        gas:0,
        cif:0,
        external_manufacturing:0
      },
      raw_materials: {},
      packaging:{},
      sales_price:{}
    }
  
    this.manufacturingSecondVersion= {
      total:0,
      energy:0,
      mmtto:0,
      mod:0,
      depreciation:0,
      gas:0,
      cif:0,
      external_manufacturing:0
    }
  
    this.rawMaterialSecondVersion = {
      material_1: [],
      total_material_1:0,
      material_2: [],
      total_material_2:0
  
    }
  
    this.packagingSecondVersion = {
      materials:[],
      total:0
    }
  
    this.salesPriceSecondVersion = {
      sale_price:0,
      total_cost:0,
      margin:0,
      margin_precentage:0
    }
  
    this.secondVersionData={
      manufacturing: {
        total:0,
        energy:0,
        mmtto:0,
        mod:0,
        depreciation:0,
        gas:0,
        cif:0,
        external_manufacturing:0
      },
      raw_materials: {},
      packaging:{},
      sales_price:{}
    }
  
    this.manufacturingThirdVersion= {
      total:0,
      energy:0,
      mmtto:0,
      mod:0,
      depreciation:0,
      gas:0,
      cif:0,
      external_manufacturing:0
    }
  
    this.rawMaterialThirdVersion = {
      material_1: [],
      total_material_1:0,
      material_2: [],
      total_material_2:0
  
    }
  
    this.packagingThirdVersion = {
      materials:[],
      total:0
    }
  
    this.salesPriceThirdVersion = {
      sale_price:0,
      total_cost:0,
      margin:0,
      margin_precentage:0
    }
  
    this.thirdVersionData={
      manufacturing: {
        total:0,
        energy:0,
        mmtto:0,
        mod:0,
        depreciation:0,
        gas:0,
        cif:0,
        external_manufacturing:0
      },
      raw_materials: {},
      packaging:{},
      sales_price:{}
    }
  }

  
}
