<div class="container__task-report">
    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
        <p-accordionTab [selected]="true" iconPos="end">
            <ng-template pTemplate="header" >
                {{title}}<span *ngIf="quantity>=0" class="quantity">{{quantity}}</span>
            </ng-template>
            <div class="container__table">
                <table class="project-table">
                    <thead>
                        <tr>
                            <th *ngFor="let header of headers">
                                {{header}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let task of content">
                            <td>{{task.name}}</td>
                            <td>{{task.date | date:'MMM dd, yyyy'}}</td>
                            <td>
                                <div class="flex" style="align-items: center;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <circle *ngIf="!task.completed" cx="7" cy="7" r="7" fill="#EBC745"/>
                                    <circle *ngIf="task.completed" cx="7" cy="7" r="7" fill-opacity="0.7" fill="#468E1B"/>
                                    </svg>
                                    <span style="margin-left: 8px;">
                                        {{task.completed?"Completed":"Incomplete"}}
                                    </span>
                                </div>
                            </td>
                            <td>{{task.user||task.project}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>