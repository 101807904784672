<!-- <p-button> -->
<p-card (click)="goToBusinessUnit()">
    <ng-template #header pTemplate="header">
        <div class="flex items-start container__header justify-content-between">
            <div class="font-bold w-full title-header">
                {{ name }}
            </div>
            <div>
                <div class="flex flex-nowrap items-center container__actions-folder-content">
                    <div class="container__action-buttons">
                        <div class="container__circle">
                            <svg *ngIf="is_active" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7.66675" cy="7.5" r="7" fill="#468E1B" fill-opacity="0.7"/>
                            </svg>
                            <svg *ngIf="!is_active" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7.66675" cy="7.5" r="7" fill="#c02d2d" fill-opacity="0.7"/>
                            </svg>
                        </div>
                    </div>
                    <div class="container__action-buttons">
                    </div>
                    <div class="container__action-buttons">
                        
                    <p-button pTooltip="Copy Link" tooltipPosition="top" stop-event-propagation (click)="op.toggle($event)">
                        <svg #targetEl width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3901_929)">
                        <path d="M24.0202 8.72702L18.0201 14.3931C17.3877 14.9904 16.3335 14.5475 16.3335 13.6661V10.6676C10.3095 10.708 7.76825 12.1306 9.46787 17.8073C9.65466 18.4312 8.93262 18.9143 8.42595 18.5294C6.80233 17.2961 5.3335 14.937 5.3335 12.5558C5.3335 6.55844 10.2335 5.36831 16.3335 5.33448V2.33385C16.3335 1.4516 17.3884 1.01019 18.0201 1.60685L24.0202 7.27302C24.4377 7.66731 24.4381 8.3324 24.0202 8.72702ZM16.3335 17.1304V20H3.00016V6.66669H5.12166C5.18899 6.66664 5.25562 6.65299 5.31754 6.62655C5.37947 6.60011 5.4354 6.56142 5.482 6.51281C6.10504 5.86414 6.8252 5.35073 7.60758 4.94298C8.07133 4.70127 7.8995 4.00002 7.37654 4.00002H2.3335C1.22891 4.00002 0.333496 4.89544 0.333496 6.00002V20.6667C0.333496 21.7713 1.22891 22.6667 2.3335 22.6667H17.0002C18.1047 22.6667 19.0002 21.7713 19.0002 20.6667V16.9664C19.0002 16.6211 18.6586 16.3804 18.333 16.4955C17.8766 16.657 17.3877 16.7052 16.9085 16.6362C16.6056 16.5926 16.3335 16.8243 16.3335 17.1304Z" fill="#4D5154"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3901_929">
                        <rect width="24" height="24" fill="white" transform="translate(0.333496)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </p-button>
                        
                    </div>
                </div>
            </div>
        </div>

    </ng-template>
    <ng-template pTemplate="footer">
        {{createdAt}}
    </ng-template>
</p-card>
<!-- </p-button> -->

<p-overlayPanel styleClass="dialog-link" [dismissable]="true" #op>
    <ng-template pTemplate="content">
        <app-copy-link [linkType]="'client'" [_id]="_id" [_id_2]="business_unit"></app-copy-link>
    </ng-template>
</p-overlayPanel>