<nav>
    <!--Bara superior-->
    <div class="container__logo">
        <a href="selection-groups">
            <img class="logo" src="../../../../assets/images/layout/luker-logo.png" alt="logo" height="75.48px">
        </a>
        
    </div>
    <div class="container__userinfo">
            <img (click)="redirectV5()" class="logo-home" src="../../../../assets/images/layout/home-icon.svg" alt="logo" height="75.48px">
        <span>{{name}}</span>
        <img class="profile-pic" src={{profilePic}} alt="Thumbnail" height="48px">
    </div>
</nav>