<div class="container">
    <p-accordion styleClass="accordion-status" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
        <p-accordionTab iconPos="end">
            <ng-template pTemplate="header" >
                Status
            </ng-template>
            <div class="flex align-items-center container__status-checkbox">
                <div class="flex justify-content-center container__checkbox">
                    <p-checkbox styleClass="checkbox-filter" [(ngModel)]="filterValues.status.active" [binary]="true" inputId="binary"></p-checkbox>
                </div>
                Active
            </div>
            <div class="flex align-items-center container__status-checkbox">
                <div class="flex justify-content-center container__checkbox">
                    <p-checkbox styleClass="checkbox-filter" [(ngModel)]="filterValues.status.inactive" [binary]="true" inputId="binary"></p-checkbox>
                </div>
                Inactive
            </div>
            <!-- <div class="flex align-items-center container__status-checkbox">
                <div class="flex justify-content-center container__checkbox">
                    <p-checkbox styleClass="checkbox-filter" [(ngModel)]="filterValues.status.completed" [binary]="true" inputId="binary"></p-checkbox>
                </div>
                Completed
            </div>
            <div class="flex align-items-center container__status-checkbox">
                <div class="flex justify-content-center container__checkbox">
                    <p-checkbox styleClass="checkbox-filter" [(ngModel)]="filterValues.status.in_progress" [binary]="true" inputId="binary"></p-checkbox>
                </div>
                In Progress
            </div> -->
        </p-accordionTab>
    </p-accordion>
    <p-accordion *ngIf="useFor!='all-folders'" styleClass="accordion-status" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
        <p-accordionTab iconPos="end">
            <ng-template pTemplate="header" >
                Steps
            </ng-template>
            <div class="flex align-items-center container__status-checkbox">
                <div class="flex justify-content-center container__checkbox">
                    <p-checkbox styleClass="checkbox-filter" [(ngModel)]="filterValues.steps.macroeconomics" [binary]="true" inputId="binary"></p-checkbox>
                </div>
                Macroeconomics
            </div>
            <div class="flex align-items-center container__status-checkbox">
                <div class="flex justify-content-center container__checkbox">
                    <p-checkbox styleClass="checkbox-filter" [(ngModel)]="filterValues.steps.r_and_d" [binary]="true" inputId="binary"></p-checkbox>
                </div>
                R&D
            </div>
            <div class="flex align-items-center container__status-checkbox">
                <div class="flex justify-content-center container__checkbox">
                    <p-checkbox styleClass="checkbox-filter" [(ngModel)]="filterValues.steps.packaging" [binary]="true" inputId="binary"></p-checkbox>
                </div>
                Packaging
            </div>
            <div class="flex align-items-center container__status-checkbox">
                <div class="flex justify-content-center container__checkbox">
                    <p-checkbox styleClass="checkbox-filter" [(ngModel)]="filterValues.steps.processes" [binary]="true" inputId="binary"></p-checkbox>
                </div>
                Process
            </div>
            <div class="flex align-items-center container__status-checkbox">
                <div class="flex justify-content-center container__checkbox">
                    <p-checkbox styleClass="checkbox-filter" [(ngModel)]="filterValues.steps.allies" [binary]="true" inputId="binary"></p-checkbox>
                </div>
                Allies
            </div>
            <div class="flex align-items-center container__status-checkbox">
                <div class="flex justify-content-center container__checkbox">
                    <p-checkbox styleClass="checkbox-filter" [(ngModel)]="filterValues.steps.excess" [binary]="true" inputId="binary"></p-checkbox>
                </div>
                Excess
            </div>
            <div class="flex align-items-center container__status-checkbox">
                <div class="flex justify-content-center container__checkbox">
                    <p-checkbox styleClass="checkbox-filter" [(ngModel)]="filterValues.steps.cost_feasibility" [binary]="true" inputId="binary"></p-checkbox>
                </div>
                Cost Feasibility
            </div>
        </p-accordionTab>
    </p-accordion>
    <div class="container__date-form mt-3">
        <div class="container__datepicker-title">
            Date Created
        </div>
            <p-calendar placeholder="Select date" styleClass="calendar-form" appendTo="body" [(ngModel)]="filterValues.date_created" selectionMode="range" [readonlyInput]="true" dateFormat="dd-mm-yy"></p-calendar>
    </div>
    <div class="container__date-form">
        <div class="container__datepicker-title">
            Deadline
        </div>
        <p-calendar placeholder="Select date" styleClass="calendar-form" appendTo="body" [(ngModel)]="filterValues.dead_line" selectionMode="range" [readonlyInput]="true" dateFormat="dd-mm-yy" [style]="{top:'0px'}"></p-calendar>
    </div>
    <div class="container__dropdown-form">
        <p-dropdown [(ngModel)]="filterValues.executive" (ngModelChange)="updateExecutive()" styleClass="dropdown-form" optionValue="_id" appendTo="body" placeholder="Executive" [options]="executiveOptions">
            <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2">
                    <div>{{ selectedExecutive }}</div>
                </div>
            </ng-template>
            <ng-template let-executive pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <div>{{ executive.nombre +" "+executive.apellido}}</div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
    <!-- <div class="container__dropdown-form">
        <p-dropdown styleClass="dropdown-form" placeholder="Business Unit" appendTo="body" [options]="businessUnitOptions" [(ngModel)]="filterValues.bussines_unit" ></p-dropdown>
    </div> -->
    <div class="flex justify-content-end conatainer__buttons">
            <p-button styleClass="reset" (onClick)="resetFilters()" label="Reset"></p-button>
            <p-button styleClass="apply-filters" (onClick)="applyFilters()" label="Apply Filters"></p-button>
    </div>
</div>