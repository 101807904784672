import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-packaging-card',
  templateUrl: './packaging-card.component.html',
  styleUrls: ['./packaging-card.component.scss'],
})
export class PackagingCardComponent implements OnInit {
  @Input()
  weight: string = '';
  @Input()
  selected: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
  }
}
