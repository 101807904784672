<div class="container">
    <div class="flex align-items-center container__title">
        Copy Link
    </div>
    <div *ngIf="linkCopied" class="flex justify-content-center container__link-copied">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M6.07573 12.7727L2.17573 8.87269C1.94142 8.63838 1.94142 8.25848 2.17573 8.02416L3.02424 7.17562C3.25854 6.9413 3.63846 6.9413 3.87277 7.17562L6.5 9.80283L12.1272 4.17562C12.3615 3.94132 12.7415 3.94132 12.9758 4.17562L13.8243 5.02415C14.0586 5.25846 14.0586 5.63836 13.8243 5.87269L6.92426 12.7727C6.68994 13.007 6.31004 13.007 6.07573 12.7727Z" fill="#4D5154"/>
        </svg>
        <div class="container__link-copied-succesfully">
            The Link Has Been Copied Succesfully
        </div>
    </div>
    <div class=" container__link">
        <input [(ngModel)]="link" [readOnly]="true" type="text" pInputText />
    </div>
    <div class="flex justify-content-end container__button">
        <p-button (click)="copyLinkToClipboard()" label="Copy Link"></p-button>
    </div>
</div>
