import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-form-table',
  templateUrl: './form-table.component.html',
  styleUrls: ['./form-table.component.scss']
})
export class FormTableComponent {
  /**
   * The table headers list.
   */
  @Input() public columns: any[];

  /**
   * The row with the form.
   */
  public tableForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.columns = [];
    this.tableForm = this.formBuilder.group({
      form_array: this.formBuilder.array([
        this.formBuilder.group(this.generateFormGroupFields())
      ])
    });
  }

  /**
   * Generates the form fields to be added to the form array.
   *
   * @private
   */
  public generateFormGroupFields(): Object {
    return {
      code: [''],
      view_material_info: [''],
      description: [''],
      formula: [''],
      weight: [''],
      area: [''],
      cost_per_kg: [''],
      category: [''],
      umb: [''],
      cost_per_umb: [''],
      currency: [''],
      scrap: [''],
      additional_scrap: [''],
      observations: ['']
    };
  }

  /**
   * Gets the form array.
   */
  public getFormArray(): FormArray {
    return this.tableForm.get('form_array') as FormArray;
  }

  /**
   * Gets the list of form arrays.
   *
   * @param row
   */
  public getFormArrayControls(row: any): string[] {
    return Object.keys(row.controls);
  }

  /**
   * Adds a new row to the table.
   */
  public addNewRow(): void {
    const formGroup = this.formBuilder.group(this.generateFormGroupFields());

    this.getFormArray().push(formGroup);
  }

  /**
   * Calculates the subtotal.
   */
  // TODO: Calculate this.
  public calculateSubtotal(): number {
    return 110;
  }

  /**
   * Removes the given index row from the table.
   *
   * @param index
   */
  public removeRow(index: number): void {
    this.getFormArray().controls.splice(index, 1);
  }
}
