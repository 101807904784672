import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@core/guards/auth.guard';
import { NoAuthGuard } from '@core/guards/noAuth.guard';
import { LogoutGuard } from '@core/guards/logout.guard';

import { Redirectv6Component } from '@pages/auth/redirectv6/redirectv6.component';
import { ProjectRedirectComponent } from '@shared/components/project-redirect/project-redirect.component';
import { CompareVersionsComponent } from '@pages/compare-versions/compare-versions.component';

const routes: Routes = [

  //La ruta a la que redirige no existe
  // {
  //   path: '',
  //   redirectTo: 'under-construction',
  //   pathMatch: 'full',
  // },

  // -------------------- Auth Routes --------------------

  {
    path: 'auth',
    canMatch: [NoAuthGuard],
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  //Volver esta ruta LazyLoading
  {
    path: 'register',
    canMatch: [NoAuthGuard],
    redirectTo: 'auth/register',
  },
  {
    path: 'logout',
    canMatch: [LogoutGuard],
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  // -------------------- Home Routes --------------------
  //Esta ruta debe ser LazyLoading
  {
    path: '',
    canActivate: [AuthGuard],
    // component: DashboardPage,
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  //ruta para hacer redict con el token
  {  path: 'redirect-v6/:token/:userId', component: Redirectv6Component },
  {  
    path: 'project/:projectId', component: ProjectRedirectComponent,
    canActivate: [AuthGuard]
  },
  {  
    path: 'compare-versions', component: CompareVersionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'selection-groups',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/selection-groups/selection-groups.module').then((m) => m.SelectionGroupsModule),
  },
  {
    path: 'business-units',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/business-units/business-units.module').then((m) => m.BusinessUnitsModule),
  },
  {
    path: 'all-folders/:client/:business_unit',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/all-folders/all-folders.module').then((m) => m.AllFoldersModule),
  },
  {
    path: 'inside-folder',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/inside-folder/inside-folder.module').then((m) => m.InsideFolderModule),
  },

  // -------------------- Auxiliary Routes --------------------
  {
    path: '**',
    redirectTo: 'auth',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
