<div class="container">
<!-- 
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="28" height="28" rx="14" [attr.fill]="state == 'Completed'?'#468E1B':state == 'In Progress'?'#E0BF44':state == 'Incomplete'?'#6A6D70':''"/>
        <path [attr.d]="svgPath" fill="#FEFEFE"/>
        <path *ngIf="this.id=='simulator'" d="M9.33332 18.6667H13.3333V20H10.6667C10.4898 20 10.3203 20.0702 10.1952 20.1953C10.0702 20.3203 9.99998 20.4899 9.99998 20.6667C9.99998 20.8435 10.0702 21.013 10.1952 21.1381C10.3203 21.2631 10.4898 21.3333 10.6667 21.3333H17.3333C17.5101 21.3333 17.6797 21.2631 17.8047 21.1381C17.9297 21.013 18 20.8435 18 20.6667C18 20.4899 17.9297 20.3203 17.8047 20.1953C17.6797 20.0702 17.5101 20 17.3333 20H14.6667V18.6667H18.6667C19.4348 18.6657 20.1791 18.3999 20.7742 17.9142C21.3692 17.4284 21.7786 16.7524 21.9333 16H6.06665C6.22138 16.7524 6.63077 17.4284 7.22582 17.9142C7.82086 18.3999 8.56518 18.6657 9.33332 18.6667Z" fill="#FEFEFE"/>
    </svg> -->
        
    <div class="containar__stage {{state == 'Completed'?'card__complete':state == 'In Progress'?'card__progess':state == 'Incomplete'?'card__incomplete':''}}">
        
        <div class="contailer__little__and__icon">
            {{stageName}}
        </div>
        <div
            class="container__state__stage {{id == 'simulator'?'incomplete':state == 'Completed'?'complete':state == 'In Progress'?'progess':state == 'Incomplete'?'incomplete':''}}">
            {{this.id=="simulator"?"":state}}
        </div>
    </div>

    <!-- <div class="containar__stage {{state == 'Completed'?'card__complete':state == 'In Progress'?'card__progess':state == 'Incomplete'?'card__incomplete':''}}">

    </div> -->

</div>