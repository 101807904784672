import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';
import { GetUserDetail, GetUserId, SetUserDetail } from '@core/utils';
import { lastValueFrom, tap } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{

  constructor(
    private authService:AuthService
  ){}

  // @Input()
  name: any = "";

  @Input()
  profilePic: String = "";

  ngOnInit(): void {
    this.setUserName()
  }

  redirectV5() {
    window.location.href = `${environment.baseUrl}`;
  }

  async setUserName(){
    let userDetails:any = GetUserDetail()
    if(userDetails!=null && JSON.stringify(userDetails) != '{}'){
      this.name=((userDetails.nombre)??"Anonymous")+" "+((userDetails.apellido)??"User")
    } else {
      let userId = GetUserId()
      let userInfoFromAPI = await this.getUserInfo(userId)
      if(userInfoFromAPI!=null){
        let nombre = userInfoFromAPI.nombre 
        let apellido = userInfoFromAPI.apellido 
        let tipo = userInfoFromAPI.tipo 
        let _id = userInfoFromAPI._id 
        let userDetailObject = {
          nombre: nombre,
          apellido: apellido,
          tipo: tipo,
          _id: _id
        }
        this.name=((userDetailObject.nombre)??"Anonymous")+" "+((userDetailObject.apellido)??"User")
        
        SetUserDetail(userDetailObject)
      } else {
        this.name="Anonymous User"
      }
    }
    
    
  }

  async getUserInfo(userId:any){
    return await lastValueFrom(this.authService.getUserInfo(userId).pipe(
      (tap( response => {
      }))

    ))
  }
}
