import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
// import { getMacroeconomicsValuesOnLocal, saveMacroeconomicsValuesOnLocal } from '@core/utils/local-storage-data';
import { HttpClient } from '@angular/common/http';
import { getProcessesValuesOnLocal, saveProcessesValuesOnLocal } from '@core/utils/local-storage-data';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class ProcessesService {
  constructor(private http: HttpClient,) {
    
  }

  saveProcesses(data:any, params:any): Observable<any>  {
    return this.http.post(`${baseUrl}/api/processes`, data, {params: params});
  }

  updateProcesses(data:any, projectID:any, params:any): Observable<any>  {
    return this.http.put(`${baseUrl}/api/processes/${projectID}`, data, {params: params});
  }


  getProcessesValuesOnLocalStorage(){
    return getProcessesValuesOnLocal();
  }
  saveProcessesValuesOnLocalStorage(processesValue:any){
    return saveProcessesValuesOnLocal(processesValue);
  }


  getRecursos() {
    return this.http.get(`${baseUrl}/api/recurso`);

    
  }
  
  getOEEById(recurso_id:number){
    return this.http.get(`${baseUrl}/api/eficiencia_operativa/arbid?arbid=${recurso_id}`);

  }

  getManufacturingCombination(queryParams:any){
    return this.http.get(`${baseUrl}/api/combinacion_manufactura/seleccion`, {params: queryParams });
  }

  getTarifasByCeco(ceco:any){
    return this.http.get(`${baseUrl}/api/tarifa/ceco/${ceco}`);
  }

  getTarifas(){
    return this.http.get(`${baseUrl}/api/tarifa`);


  }

  

  getProcessesInfo(projectId:any):  Observable<any> {
    return this.http.get(`${baseUrl}/api/processes/${projectId}`);

    // let emptymock = new BehaviorSubject<any>(
    //   {}
    // )
    //   return emptymock.asObservable()
  }

  getUsers(){
    return this.http.get(`${baseUrl}/api/usertype?types=PROCESOS_ADMIN_SUPERADMIN`);
    // return this.http.get(`${baseUrl}/api/users`);
  }

  saveProcessesVersionInfo(versionId:any, data:any){
    return this.http.put(`${baseUrl}/api/project_brief_version/${versionId}`, data);
  }

}
