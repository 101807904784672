import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { getBusinessUnitValuesOnLocal, saveBusinessUnitValuesOnLocal } from '@core/utils';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class BusinessUnitsService {
  constructor(private http: HttpClient,) {
    
  }
  
  getAllBusinessUnits(){
    return this.http.get(`${baseUrl}/api/business_units`);
  }

  getBusinessUnitsByFilters(queryParams:String){
    return this.http.post(`${baseUrl}/api/business_units/filter${queryParams}`,"");
  }

  getBusinessUnitById(businessUnitId:any){
    return this.http.get(`${baseUrl}/api/business_units/${businessUnitId}`);
  }
  
  createBusinessUnit(data:any, params:any): Observable<any>  {
    return this.http.post(`${baseUrl}/api/business_units`, data, {params: params});
  }

  updateBusinessUnit(data:any, businessUnitId:any, params:any): Observable<any>  {
    return this.http.put(`${baseUrl}/api/business_units/${businessUnitId}`, data, {params: params});
  }

  deleteBusinessUnit(businessUnitId:any, params:any){
    return this.http.delete(`${baseUrl}/api/business_units/${businessUnitId}`, {params: params});
  }



  getBusinessUnitValuesOnLocalStorage(){
    return getBusinessUnitValuesOnLocal();
  }
  saveBusinessUnitValuesOnLocalStorage(businessUnitValues:any){
    return saveBusinessUnitValuesOnLocal(businessUnitValues);
  }


}
