import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FoldersService } from '@core/services/home/folders/folders.service';
import { GetUserDetail, GetUserId } from '@core/utils';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel'; 
import { lastValueFrom, tap } from 'rxjs';

@Component({
  selector: 'app-folder-card',
  templateUrl: './folder-card.component.html',
  styleUrls: ['./folder-card.component.scss'],
  providers: [MessageService, ConfirmationService]

})
export class FolderCardComponent {

  folder_id=""
  constructor(
    private router: Router,
    private foldersService: FoldersService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
    ){
      this.checkUserPermissions()
  }
  @Input()
  _id: string = '';
  @Input()
  is_active: boolean = true;
  @Input()
  created_at: string = '';
  @Input()
  name: string = '';
  @Input()
  projects: number = 0;
  @Input()
  total_sales_volume: number = 0;

  canDeleteFolders=["ADMIN", "SUPERADMIN"]
  allowedToDeleFolder=false
  user_details:any={}
  success=true
  
  async deleteFolder(){
    try {
      await lastValueFrom(this.foldersService.deleteFolder(this._id, {user: this.user_details._id}).pipe(
        (tap( response => {
        }, error => {
          this.success=false
        }
        ))
          ))
    } catch (error) {
      this.success=false
    }

  }

  checkUserPermissions(){
    this.user_details = GetUserDetail()
    if (this.canDeleteFolders.includes(this.user_details.tipo)) {
      this.allowedToDeleFolder=true
    }
  }

  clickTrash(){
    this.confirmationService.confirm({
      accept: async () => {
        this.success=true
        await this.deleteFolder()
        this.changesUpdatesPopUp()
        
      },
  });
  }
  changesUpdatesPopUp(){
    setTimeout( () => {
      if (this.success) {
        window.location.reload();
      } else {
        this.messageService.add({ severity: 'success', summary: 'Fail', detail: 'Folder could not be deleted' });
      }
    }, 300);
  }

  goToFolder(){
    let folderValues = {
      _id: this._id,
      name: this.name
    }
    this.foldersService.saveFolderValuesOnLocalStorage(folderValues)
    this.router.navigate([`inside-folder`,this._id]);
    
  }
}
