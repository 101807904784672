import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shape-card',
  templateUrl: './shape-card.component.html',
  styleUrls: ['./shape-card.component.scss'],
})
export class ShapeCardComponent implements OnInit {
  @Input()
  title: string = '';
  @Input()
  image: string = '';
  @Input()
  selected: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
  }
}
