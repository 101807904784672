<p>Version 1</p>

<div class="container__manufacturing">
    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
        <p-accordionTab iconPos="end">
            <ng-template pTemplate="header" >
                Manufacturing
            </ng-template>
            <p-divider styleClass="divider-style" ></p-divider>
            <table id="manufacturing">
                <thead>
                    <tr>
                        <th>
                            Item
                        </th>
                        <th>
                            Cost
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Depreciation</td>
                        <td>{{manufacturing.depreciation}}</td>
                    </tr>
                    <tr>
                        <td>Energy</td>
                        <td>{{manufacturing.energy}}</td>
                    </tr>
                    <tr>
                        <td>Gas</td>
                        <td>{{manufacturing.gas}}</td>
                    </tr>
                    <tr>
                        <td>Maintance</td>
                        <td>{{manufacturing.mmtto}}</td>
                    </tr>
                    <tr>
                        <td>Direct Labour</td>
                        <td>{{manufacturing.mod}}</td>
                    </tr>
                    <tr>
                        <td>MOI and other CIF</td>
                        <td>{{manufacturing.cif}}</td>
                    </tr>
                    <tr>
                        <td>External Manufacturing</td>
                        <td>{{manufacturing.external_manufacturing}}</td>
                    </tr>
                </tbody>
            </table>

            <table id="total">
                <thead>
                    <tr>
                        <th>TOTAL COST</th>
                        <th>0</th>
                    </tr>
                </thead>
            </table>
        </p-accordionTab>
    </p-accordion>
</div>
<div class="container__raw-materials">
    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
        <p-accordionTab iconPos="end">
            <ng-template pTemplate="header" >
                Raw Material
            </ng-template>
            <p-divider styleClass="divider-style" ></p-divider>
            <table id="raw-materials">
                <thead>
                    <tr>
                        <th>
                            Material 1
                        </th>
                        <th>
                            Porcentage
                        </th>
                        <th>
                            Cost
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>PS Licor</td>
                        <td>20.32%</td>
                        <td>10,000</td>
                    </tr>
                    <tr>
                        <td>PS Manteca de cacao</td>
                        <td>41.87%</td>
                        <td>18,000</td>
                    </tr>
                </tbody>
            </table>
            <table id="total-material">
                <thead>
                    <tr>
                        <th colspan="2">TOTAL COST</th>
                        <th>28,000</th>
                    </tr>
                </thead>
            </table>
            <table id="raw-materials">
                <thead>
                    <tr>
                        <th>
                            Material 2
                        </th>
                        <th>
                            Porcentage
                        </th>
                        <th>
                            Cost
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>PS Licor</td>
                        <td>20.32%</td>
                        <td>10,000</td>
                    </tr>
                    <tr>
                        <td>PS Manteca de cacao</td>
                        <td>41.87%</td>
                        <td>18,000</td>
                    </tr>
                    <tr>
                        <td>PS Manteca de cacao</td>
                        <td>41.87%</td>
                        <td>18,000</td>
                    </tr>
                </tbody>
            </table>
            <table id="total-material">
                <thead>
                        <tr>
                            <th colspan="2">TOTAL COST</th>
                            <th>28,000</th>
                        </tr>
                </thead>
            </table>
        </p-accordionTab>
    </p-accordion>
</div>
<div class="container__packaging">
    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
        <p-accordionTab iconPos="end">
            <ng-template pTemplate="header" >
                Packaging
            </ng-template>
            <p-divider styleClass="divider-style" ></p-divider>
            <table id="packaging">
                    <thead>
                        <tr>
                            <th>
                                Item
                            </th>
                            <th>
                                Qty
                            </th>
                            <th>
                                Cost
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Envoltura</td>
                            <td>1</td>
                            <td>10,000</td>
                        </tr>
                        <tr>
                            <td>Caja</td>
                            <td>5</td>
                            <td>18,000</td>
                        </tr>
                    </tbody>
                </table>
                <table id="total-material">
                    <thead>
                        <tr>
                            <th colspan="2">TOTAL COST</th>
                            <th>28,000</th>
                        </tr>
                    </thead>
                </table>
        </p-accordionTab>
    </p-accordion>
</div>
<div class="container__sales-price">
    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
        <p-accordionTab iconPos="end">
            <ng-template pTemplate="header" >
                Sales Price
            </ng-template>
            <p-divider styleClass="divider-style" ></p-divider>
            <table id="sales-price">
                    <thead>
                        <tr>
                            <th>
                                Sale Price
                            </th>
                            <th>
                                Total Cost
                            </th>
                            <th>
                                Margin
                            </th>
                            <th>
                                Margin(%)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>586</td>
                            <td>400</td>
                            <td>186</td>
                            <td>83%</td>
                        </tr>
                       
                    </tbody>
                </table>
        </p-accordionTab>
    </p-accordion>
</div>