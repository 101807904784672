import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FoldersService } from '@core/services/home/folders/folders.service';
import { GetUserDetail, GetUserId } from '@core/utils';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, tap } from 'rxjs';

@Component({
  selector: 'app-create-version-popup',
  templateUrl: './create-version-popup.component.html',
  styleUrls: ['./create-version-popup.component.scss']
})
export class CreateVersionPopupComponent {

  projectBriefId=""
  constructor(
    private foldersService: FoldersService,
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.projectBriefId = this.config.data.projectBriefId
    this.setUserDetails()
    // console.log(this.projectBriefId)
  }

  user_details:any={}
  
  basicInfoForm: FormGroup = new FormGroup({
    project_name: new FormControl(""),
  });

  commentsForm:FormGroup = new FormGroup({
    comments: new FormControl("", []),
  });

  async createNewVersionButton(){
    try {
      let createNewVersion = await this.createNewVersion()
      // console.log(createNewVersion)
      if(createNewVersion){
        let result = {
          success: true
        }
        this.ref.close(result)
      } 
    } catch (error:any) {
      let result = {
        success: false,
        message: error.error.message
      }
      this.ref.close(result)
    }
  }

  setUserDetails(){
    this.user_details = GetUserDetail()
  }

  async createNewVersion(){
    let newVersionInfo = {
      project_name: this.basicInfoForm.value.project_name,
      comments: this.commentsForm.value.comments,
      user_id: GetUserId()
    }
    return await lastValueFrom(this.foldersService.createNewVersion(newVersionInfo, this.projectBriefId, {user:this.user_details._id}).pipe(
      (tap( response => {

      }, error => {
      }))

    ))
  }
  
}
