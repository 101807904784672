import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import { AuthUtils } from '@core/helpers';
import jwtDecode from 'jwt-decode';
import { getProjectBriefValuesOnLocal, saveProjectBriefValuesOnLocal } from '@core/utils/local-storage-data';
import { HttpClient } from '@angular/common/http';
import { UserType } from '@core/constants/users.constant';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class ProjectBriefService {
  constructor(private http: HttpClient,) {
    
  }

  getExecutiveUsers(){
    return this.http.get(`${baseUrl}/api/usertype?types=Comercial_EjecutivoSr_admin_superadmin`);
  }


  saveProject(data:any, params:any): Observable<any>  {
    return this.http.post(`${baseUrl}/api/project_brief/`, data, {params: params});
  }

  updateProject(data:any, projectId:any, params:any): Observable<any>  {
    return this.http.put(`${baseUrl}/api/project_brief/${projectId}`, data, {params: params});
  }

  getProjectBrief(projectId:any): Observable<any>  {
    return this.http.get(`${baseUrl}/api/project_brief/${projectId}`);
  }
  getProjectBriefByProjectBriefIdAndVersion(projectBriefId:any, version:any): Observable<any>  {
    return this.http.get(`${baseUrl}/api/project_brief/${projectBriefId}/${version}`);
  }

  getCurrentProjectBriefValues(){
    return getProjectBriefValuesOnLocal();
  }

  saveCurrentProjectBriefValues(values:any){
    return saveProjectBriefValuesOnLocal(values);
  }
}
