import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
// import { getMacroeconomicsValuesOnLocal, saveMacroeconomicsValuesOnLocal } from '@core/utils/local-storage-data';
import { HttpClient } from '@angular/common/http';
import { getAlliesValuesOnLocal, getFolderValuesOnLocal, saveAlliesValuesOnLocal, saveFolderValuesOnLocal } from '@core/utils/local-storage-data';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class FoldersService {
  constructor(private http: HttpClient,) {
    
  }

  getExecutiveUsers(){
    return this.http.get(`${baseUrl}/api/usertype?types=Comercial_EjecutivoSr`);
  }
  
  getClientsByBusinessunit(businessUnit:string, params?:any){
    return this.http.get(`${baseUrl}/api/clients/business-unit/${businessUnit}`, {params: params});
  }
  getClientById(clientId:string){
    return this.http.get(`${baseUrl}/api/clients/${clientId}`);
  }
  getAllFolders(){
    return this.http.get(`${baseUrl}/api/all_folders`);
  }
  getFolderById(folderId:any){
    return this.http.get(`${baseUrl}/api/all_folders/${folderId}`);
  }
  getVersionPerProject(projectBriefId:any){
    return this.http.get(`${baseUrl}/api/project_brief_versions/${projectBriefId}`);
  }

  getFoldersByFilters(queryParams:String){
    return this.http.post(`${baseUrl}/api/all_folders/filter${queryParams}`,"");
  }
  getProjectsByFilters(folderId:any, queryParams:String){
    return this.http.post(`${baseUrl}/api/inside_folder/filter/${folderId}${queryParams}`,"");
  }
  
  createFolder(data:any, params:any): Observable<any>  {
    return this.http.post(`${baseUrl}/api/all_folders`, data, {params: params});
  }

  updateFolder(data:any, folderID:any, params:any): Observable<any>  {
    return this.http.put(`${baseUrl}/api/all_folders/${folderID}`, data, {params: params});
  }

  getProjectsInsideFolder(folderId:any){
    return this.http.get(`${baseUrl}/api/inside_folder/${folderId}`);
  }

  getExcessesProjectsInsideFolder(folderId:any){
    return this.http.get(`${baseUrl}/api/excess_inside_folder/${folderId}`);
  }

  deleteFolder(folderId:any, params:any){
    return this.http.delete(`${baseUrl}/api/all_folders/${folderId}`, {params: params});
  }

  deleteProject(projectBriefId:any, params:any){
    return this.http.delete(`${baseUrl}/api/inside_folder/${projectBriefId}`, {params: params});
  }

  duplicateProject(projectId:any, params:any){
    return this.http.post(`${baseUrl}/api/inside_folder/duplicate-project/${projectId}`,{}, {params: params});
  }


  getFolderValuesOnLocalStorage(){
    return getFolderValuesOnLocal();
  }
  saveFolderValuesOnLocalStorage(FolderValues:any){
    return saveFolderValuesOnLocal(FolderValues);
  }

  createNewVersion(data:any, projectBriefId:any, params:any): Observable<any>  {
    return this.http.post(`${baseUrl}/api/inside_folder/${projectBriefId}`, data, {params: params});
  }


}
