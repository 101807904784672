import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ProjectBriefService } from '@core/services/home/project-brief/projectBrief.service';
import { ProjectBrief } from '@pages/home/project-brief/models/projectBrief.model';

@Component({
  selector: 'app-project-summary',
  templateUrl: './project-summary.component.html',
  styleUrls: ['./project-summary.component.scss'],
})
export class ProjectSummaryComponent implements OnInit {
  isViewMorePress: boolean = false;

  @ViewChild('ContainerInfo', { static: false })
  ContainerInfo!: ElementRef;

  projectBriefValues:ProjectBrief = {
    basic_info: {
      project_name:'',
      client_name: '',
      client: '',
      executive: '',
      sales_volume:'',
      bussines_unit:'',
      conceptualization:false
    },
    technical_info: {
      presentation:'',
      format: '',
      packaging: ''
    },
    delivery: {
      destination_country:'',
      incoterm: '',
      delivery_port: '',
      address: '',
      city: '',
      containers_qty: 0,
      container_size: '',
      container_type: ''
    }
  }

  constructor(private renderer: Renderer2,
    private projectBriefService: ProjectBriefService ) {}

  ngOnInit(): void {
    // this.isViewMorePress = false;
    this.getProjectBriefInfoOnLocalStorage()
  }

  getProjectBriefInfoOnLocalStorage(){
    this. projectBriefValues = this.projectBriefService.getCurrentProjectBriefValues()??{
      basic_info: {
        project_name:'',
        client_name: '',
        executive: '',
        sales_volume:'',
        bussines_unit:''
      },
      technical_info: {
        presentation:'',
        format: '',
        packaging: ''
      },
      delivery: {
        destination_country:'',
        incoterm: '',
        delivery_port: '',
        address: '',
        city: '',
        containers_qty: '',
        container_size: '',
        container_type: ''
      }
    };
  }

  collapseProjectSummaryContainer() {
    if (this.isViewMorePress == false) {
      //Click on View more
      this.renderer.removeClass(this.ContainerInfo.nativeElement, 'viewless');
    }
    if (this.isViewMorePress == true) {
      //Click on View less
      this.renderer.addClass(this.ContainerInfo.nativeElement, 'viewless');
    }
    this.isViewMorePress = !this.isViewMorePress;
  }
}
