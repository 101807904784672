<div>
  <h4>Search sapcode by description</h4>
</div>
<label class="modal-description">Search raw material</label>
<div class="container-row" style="text-align: left;">
  <input type="text" [(ngModel)]="description" (change)="filterRawMaterials()"/>
  <div *ngIf="typeConsult=='Ingrediente'" class="gap"></div>
  <p-dropdown *ngIf="typeConsult=='Ingrediente'" [(ngModel)]="typeRawMaterial" (ngModelChange)="filterRawMaterials()" [options]="typeMaterials">
    <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2">
            <div>{{ typeRawMaterial }}</div>
        </div>
    </ng-template>
    <ng-template let-type pTemplate="item">
        <div class="flex align-items-center gap-2">
            <div>{{ type.label }}</div>
        </div>
    </ng-template>
  </p-dropdown>
</div>
<div class="results">
  <div class="result-row justify-content-between" *ngFor="let item of results; let i = index" (click)="selectResult(i)" [ngClass]="{'selected': item.selected}">
    <p>{{item.txt_gr_articulo || item.codigoSap || item.componente || item.material  || item.productoTerminado || item.materialSap}}</p>
    <P *ngIf="this.typeConsult === 'Ingrediente'" style="max-width: 200px; text-align: right">{{item.txt_material || item.nombreComponente || item.texto}}</P>
    <P *ngIf="this.typeConsult === 'Empaque'">{{item.descripcion}}</P>
  </div>
  <p *ngIf="!results.length && !showSpinner" class="text-warning text-center mt-2">No hay resultados</p>
  <div *ngIf="showSpinner" class="flex h-full justify-content-center align-items-center">
    <div>
      <p-progressSpinner 
        styleClass="spinner" 
        strokeWidth="5" 
        animationDuration="1s" >
      </p-progressSpinner>
      <p class="text-warning text-center mt-2">Cargando...</p>

    </div>
  </div>
</div>
<div class="container-row">
  <div class="container-child">
    <button type="button" class="submit" (click)="confirm()">Submit</button>
  </div>
  <div class="gap"></div>
  <div class="container-child">
    <button type="button" class="cancel mt-3" (click)="closeModal()">Cancel</button>
  </div>

</div>
  
  