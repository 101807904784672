import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
// import { getMacroeconomicsValuesOnLocal, saveMacroeconomicsValuesOnLocal } from '@core/utils/local-storage-data';
import { HttpClient } from '@angular/common/http';
import { getExcessValuesOnLocal, saveExcessValuesOnLocal } from '@core/utils/local-storage-data';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class ExcessService {
  constructor(private http: HttpClient,) {
    
  }

  total:number=0
  packaging:number=0
  rawMaterial:number=0

  private total_observable = new BehaviorSubject<number>(this.total);
  private packaging_observable = new BehaviorSubject<number>(this.packaging);
  private rawMaterial_observable = new BehaviorSubject<number>(this.rawMaterial);

  // increasePackaging(quantity: number) {
  //     this.packaging_observable.next(this.packaging-quantity);
  // }
  // decreasePackaging(quantity: number) {
  //   this.packaging_observable.next(this.packaging-quantity);
  // }
  // // decreasePackaging(message: string) {
  // //   this.packaging_observable.next(this.packaging-quantity);
  // // }

  saveExcess(data:any, params:any): Observable<any>  {
    return this.http.post(`${baseUrl}/api/excess`, data, {params: params});
  }

  updateExcess(data:any, projectID:any, params:any): Observable<any>  {
    return this.http.put(`${baseUrl}/api/excess/${projectID}`, data, {params: params});
  }


  getExcessValuesOnLocalStorage(){
    return getExcessValuesOnLocal();
  }
  saveExcessValuesOnLocalStorage(ExcessValue:any){
    return saveExcessValuesOnLocal(ExcessValue);
  }

  getExcessInfo(projectId:any):  Observable<any> {
    return this.http.get(`${baseUrl}/api/excess/${projectId}`);


  }

  saveExcessVersionInfo(versionId:any, data:any){
    return this.http.put(`${baseUrl}/api/project_brief_version/${versionId}`, data);
  }

}
