<!-- Native table -->
<div class="form-table-container">
  <small *ngIf="showPackagingError"
            class="input-error">
        There must be at least i items in table.
    </small>
    <div class="form-table">
      <table aria-describedby="form table">
        <thead>
          <tr>
            <!-- <th scope="col" *ngFor="let header of columns">{{ header.name }}</th> -->
            <th scope="col">{{ "Actions" }}</th>
            <th scope="col">{{ "Code" }}</th>
            <th scope="col">{{ "Description" }}</th>
            <th scope="col" pTooltip="Number of units from the previous packaging that fit into the packaging.
            Example: 2 bars per fold and 9 folds per display.
            Example: Bars 1, Fold 2, Display 9." [escape]="false" tooltipPosition="top"><i class="pi pi-info-circle" style="font-size: 13px;"></i>{{ " Q" }}</th>
            <th scope="col">{{ "Weight" }}</th>
            <th scope="col">{{ "Area" }}</th>
            <th scope="col">{{ "Cost/KG" }}</th>
            <th scope="col">{{ "Category" }}</th>
            <th scope="col">{{ "UMB" }}</th>
            <th scope="col">{{ "Cost/UMB" }}</th>
            <th scope="col">{{ "MOQ" }}</th>
            <th scope="col">{{ "Currency" }}</th>
            <th scope="col">{{ "Scrap (%)" }}</th>
            <th scope="col">{{ "Aditional Scrap ($)" }}</th>
            <th scope="col">{{ "Observations" }}</th>
            <th scope="col">{{ "Consumption Quantity"  }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container [formGroup]="tableForm">
            <ng-container formArrayName="form_array">
              <tr [formGroupName]="iForm" *ngFor="let form of getFormArray().controls; let iForm = index;" id="row-{{ iForm }}">
                <td>
                  <div class="row-actions">
                    <img src="assets/icons/trash-can.svg" width="17" height="17" alt="Remove" (click)="removeRow(iForm)">
                    <!-- &nbsp; -->
                    <img src="assets/icons/magnifier.svg" width="27" height="27" alt="Search" (click)="showFilterPopUp(iForm)">
                  </div>
                </td>
                <td *ngFor="let property of getFormArrayControls(form); let i = index;">
                  <div *ngIf="materialsList.length>0" >
                    <input *ngIf="property!='formula' && 
                    property != 'code' && 
                    property != 'view_material_info' && 
                    property != 'description' && 
                    property !='weight' && 
                    property !='area' && 
                    property != 'category' &&
                    property != 'umb' &&
                    property !='cost_per_umb' && 
                    property !='moq' && 
                    property != 'currency' && 
                    property !='scrap' && 
                    property != 'observations'" type="text" [readonly]="true" pInputText placeholder="" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="( property == 'view_material_info' ||
                    property == 'description' ||
                    property == 'category' ||
                    property == 'umb' ||
                    property =='cost_per_umb' ||
                    property == 'currency') && !materialsList[iForm].newRow" type="text" [readonly]="true" pInputText [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='formula'" type="text" class="white-background" appTwoDigitDecimaNumber (ngModelChange)="calculatePrices(iForm)" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='code' && materialsList[iForm].newRow" type="text" class="white-background" (blur)="searchMaterialOnLostFocus(iForm)" (ngModelChange)="refreshTypeRawMaterial(iForm)" (keypress)="keyPressNumbers($event)" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='code' && !materialsList[iForm].newRow" type="text" [readonly]="true" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='view_material_info' && materialsList[iForm].newRow" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='description' && materialsList[iForm].newRow" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='weight'" class="white-background"  (ngModelChange)="refreshTypeRawMaterial(iForm)" (ngModelChange)="calculatePrices(iForm); updateWeight(iForm)" appTwoDigitDecimaNumber type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='area'" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" (ngModelChange)="calculatePrices(iForm); updateArea(iForm)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='category' && materialsList[iForm].newRow" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='umb' && materialsList[iForm].newRow" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='cost_per_umb' && materialsList[iForm].newRow" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" (ngModelChange)="calculatePrices(iForm)" (keypress)="keyPressNumbers($event)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='moq'" class="white-background" appOnlyNumber (ngModelChange)="updateMOQ(iForm)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='currency' && materialsList[iForm].newRow" class="white-background"  (ngModelChange)="refreshTypeRawMaterial(iForm)" (ngModelChange)="calculatePrices(iForm)" type="text" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='scrap'" type="text" class="white-background" (ngModelChange)="refreshTypeRawMaterial(iForm)" pInputText placeholder="Enter info" (ngModelChange)="addScrap(iForm)" (keypress)="keyPressNumbers($event)" [formControlName]="property" id="input-{{ property + '' + i }}">
                    <input *ngIf="property=='observations'" type="text" class="white-background" (ngModelChange)="refreshObservations(iForm)" pInputText placeholder="Enter info" [formControlName]="property" id="input-{{ property + '' + i }}">
                    
                  </div>
                  <div *ngIf="materialsList.length==0" >
                    <input [readonly]="true" type="text" pInputText placeholder="" [formControlName]="property" id="input-{{ property + '' + i }}">
                  </div>
                </td>
              </tr>
              <tr></tr>
              <tr>
                <td><strong>Subtotal</strong></td>
                <td> </td>
                <td> </td>
                <td><div style="max-width: 39px;">{{ totalFormula }}</div></td>
                <td> </td>
                <td> </td>
                <td><div>{{ subtotal|number:'1.0':'en-US' }}</div></td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div style="margin-top: 7px;"> <Span>* To enter a material without SAP code, please write “0” in the Code column</Span></div>
  <button class="add-row-button" (click)="addNewRow()">Add New Row</button>
  