<div class="form-table-container">
  <div class="form-table">
    <table aria-describedby="form table">
      <thead>
        <tr>
          <th scope="col" *ngFor="let header of columns">{{ header.name }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container [formGroup]="tableForm">
          <ng-container formArrayName="form_array">
            <tr [formGroupName]="iForm" *ngFor="let form of getFormArray().controls; let iForm = index;" id="row-{{ iForm }}">
              <td *ngFor="let property of getFormArrayControls(form); let i = index;">
                <div>
                  <p-dropdown
                    *ngIf="property === 'will_use'"
                    [options]="psList"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select"
                    [formControlName]="property"
                    [overlayOptions]="{ mode: 'overlay', appendTo: 'body' }"
                    (onChange)="changeTotalScrapsTotal(iForm)"
                  ></p-dropdown>
                  <input
                    *ngIf="!(property === 'will_use')"
                    type="text"
                    pInputText
                    [readonly]="true"
                    placeholder=""
                    [formControlName]="property"
                    id="input-{{ property + '-' + i }}"
                  >
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>