import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from '@core/helpers/auth-interceptor';

import { AppComponent } from './app.component';
import { SharedModule } from '@shared/shared.module';
import { DecimalPipe } from '@angular/common';
import { AuthGuard } from '@core/guards/auth.guard';
import { NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';

@NgModule({
  declarations: [AppComponent,],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule, 
  ],
  providers: [
    DecimalPipe,
    HttpClient,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideEnvironmentNgxCurrency({
      align: "left",
      allowNegative: false,
      allowZero: true,
      decimal: ".",
      precision: 0,
      prefix: "",
      suffix: "",
      thousands: ",",
      nullable: true,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
