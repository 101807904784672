<!-- <p-button> -->
<p-card (click)="goToFolder()">
    <ng-template #header pTemplate="header">
        <div class="flex items-start container__header justify-content-between">
            <div class="font-bold w-full title-header">
                {{ name }}
            </div>
            <div>
                <div class="flex flex-nowrap items-center container__actions-folder-content">
                    <div class="container__action-buttons">
                        <div class="container__circle">
                            <svg *ngIf="is_active" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7.66675" cy="7.5" r="7" fill="#468E1B" fill-opacity="0.7"/>
                            </svg>
                            <svg *ngIf="!is_active" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7.66675" cy="7.5" r="7" fill="#c02d2d" fill-opacity="0.7"/>
                            </svg>
                        </div>
                    </div>
                    <div class="container__action-buttons">
    
                        <p-button *ngIf="allowedToDeleFolder" pTooltip="Delete Folder" tooltipPosition="top" stop-event-propagation (click)="clickTrash()">
                        <svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3901_1757)">
                        <path d="M4.32725 21.8906C4.36302 22.4619 4.61513 22.998 5.03227 23.3899C5.44941 23.7817 6.00021 23.9999 6.57256 24H18.0944C18.6668 23.9999 19.2176 23.7817 19.6347 23.3899C20.0519 22.998 20.304 22.4619 20.3397 21.8906L21.3335 6.00001H3.3335L4.32725 21.8906ZM7.61365 13.65L11.8033 9.23157C11.871 9.15849 11.953 9.10019 12.0442 9.06031C12.1354 9.02044 12.2339 8.99985 12.3335 8.99985C12.4331 8.99985 12.5316 9.02044 12.6228 9.06031C12.714 9.10019 12.796 9.15849 12.8637 9.23157L17.0533 13.65C17.5258 14.1478 17.1912 15 16.5227 15H13.8335V20.25C13.8335 20.4489 13.7545 20.6397 13.6138 20.7803C13.4732 20.921 13.2824 21 13.0835 21H11.5835C11.3846 21 11.1938 20.921 11.0532 20.7803C10.9125 20.6397 10.8335 20.4489 10.8335 20.25V15H8.14428C7.47584 15 7.14115 14.1478 7.61365 13.65ZM22.0835 1.50001H16.4585L16.0179 0.623446C15.9245 0.436046 15.7808 0.27841 15.6027 0.16827C15.4247 0.0581305 15.2194 -0.000141936 15.0101 8.21846e-06H9.65225C9.44336 -0.000794775 9.23847 0.0572604 9.06106 0.167522C8.88365 0.277784 8.74088 0.435793 8.64912 0.623446L8.2085 1.50001H2.5835C2.38458 1.50001 2.19382 1.57903 2.05317 1.71968C1.91251 1.86033 1.8335 2.0511 1.8335 2.25001L1.8335 3.75001C1.8335 3.94892 1.91251 4.13969 2.05317 4.28034C2.19382 4.42099 2.38458 4.50001 2.5835 4.50001H22.0835C22.2824 4.50001 22.4732 4.42099 22.6138 4.28034C22.7545 4.13969 22.8335 3.94892 22.8335 3.75001V2.25001C22.8335 2.0511 22.7545 1.86033 22.6138 1.71968C22.4732 1.57903 22.2824 1.50001 22.0835 1.50001Z" fill="#4D5154"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3901_1757">
                        <rect width="24" height="24" fill="white" transform="translate(0.333496)"/>
                        </clipPath>
                        </defs>
                        </svg>
                        </p-button>
                    </div>
                    <div class="container__action-buttons">
                        
                        <p-button pTooltip="Copy Link" tooltipPosition="top" stop-event-propagation (click)="op.toggle($event)">
                            <svg #targetEl width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_3901_929)">
                            <path d="M24.0202 8.72702L18.0201 14.3931C17.3877 14.9904 16.3335 14.5475 16.3335 13.6661V10.6676C10.3095 10.708 7.76825 12.1306 9.46787 17.8073C9.65466 18.4312 8.93262 18.9143 8.42595 18.5294C6.80233 17.2961 5.3335 14.937 5.3335 12.5558C5.3335 6.55844 10.2335 5.36831 16.3335 5.33448V2.33385C16.3335 1.4516 17.3884 1.01019 18.0201 1.60685L24.0202 7.27302C24.4377 7.66731 24.4381 8.3324 24.0202 8.72702ZM16.3335 17.1304V20H3.00016V6.66669H5.12166C5.18899 6.66664 5.25562 6.65299 5.31754 6.62655C5.37947 6.60011 5.4354 6.56142 5.482 6.51281C6.10504 5.86414 6.8252 5.35073 7.60758 4.94298C8.07133 4.70127 7.8995 4.00002 7.37654 4.00002H2.3335C1.22891 4.00002 0.333496 4.89544 0.333496 6.00002V20.6667C0.333496 21.7713 1.22891 22.6667 2.3335 22.6667H17.0002C18.1047 22.6667 19.0002 21.7713 19.0002 20.6667V16.9664C19.0002 16.6211 18.6586 16.3804 18.333 16.4955C17.8766 16.657 17.3877 16.7052 16.9085 16.6362C16.6056 16.5926 16.3335 16.8243 16.3335 17.1304Z" fill="#4D5154"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_3901_929">
                            <rect width="24" height="24" fill="white" transform="translate(0.333496)"/>
                            </clipPath>
                            </defs>
                            </svg>
                        </p-button>
                        
                    </div>
                </div>
            </div>
        </div>

    </ng-template>
    <div class="mt-1">
        {{projects}} Projects
    </div>
    <div class="mt-1">
        Total Qty(Kg): {{total_sales_volume}} Kg
    </div>
    <ng-template pTemplate="footer">
        {{created_at}}
    </ng-template>
</p-card>
<!-- </p-button> -->

<p-confirmDialog styleClass="dialog-confirm" #cd [style]="{ width: '50vw' }">
    <ng-template pTemplate="header">
        <h3>Delete Folder</h3>
    </ng-template>
    <!-- <ng-template pTemplate="icon">
        <i class="pi pi-user"></i>
    </ng-template> -->
    <ng-template pTemplate="message">
        <p>Are you sure you want to delete this folder?.</p>
    </ng-template>
    <ng-template pTemplate="footer">
        <button class="button-no" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
        <button class="button-yes" type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-overlayPanel styleClass="dialog-link" [dismissable]="true" #op>
    <ng-template pTemplate="content">
        <app-copy-link [linkType]="'folder'" [_id]="_id"></app-copy-link>
    </ng-template>
</p-overlayPanel>