<p class="description">Please, fill out the following information in order to create the new version.</p>
        <div class="container__basic-info">
            <p class="container__sesion-title">Basic Info</p>
            <form [formGroup]="basicInfoForm" id="container__form-base-info" action="">
              
                                    <div class="flex flex-column gap-2">
                                        <label htmlFor="projectname">Project Name</label>
                                        <input placeholder="Write here" pInputText [formControlName]="'project_name'" id="projectname" aria-describedby="project-name-error" />
                                        <small id="project-name-error" *ngIf="basicInfoForm.controls['project_name'].dirty && basicInfoForm.controls['project_name'].invalid"
                                                class="input-error">
                                            Please enter a project name
                                        </small>
                                        <small *ngIf="basicInfoForm.controls['project_name'].dirty && basicInfoForm.controls['project_name'].invalid">;
                                        </small>
                                    </div>
            </form>
            <p class="container__sesion-title">Comments</p>
    <form [formGroup]="commentsForm" id="container__form-base-info" action="">
        <div class="card">
            <div class="">
                <!-- <label htmlFor="euro">Type Comment</label> -->
                <textarea [formControlName]="'comments'" placeholder="Input label" maxlength="230" id="packaging" rows="5" cols="30" pInputTextarea></textarea>
                <div class="container__feed-comment">
                    <small>230 characters max.</small>
                </div>
            </div>
        </div>
    </form>
            <div class="flex justify-content-end container__button-create-version">
                <p-button (onClick)="createNewVersionButton()" label="Create Version"></p-button>
            </div>
            
        </div>