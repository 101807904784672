import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FoldersService } from '@core/services/home/folders/folders.service';
import { GetUserDetail, GetUserId } from '@core/utils';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel'; 
import { lastValueFrom, tap } from 'rxjs';

@Component({
  selector: 'app-task-report-data',
  templateUrl: './task-report-data.component.html',
  styleUrls: ['./task-report-data.component.scss'],
  providers: [MessageService, ConfirmationService]

})
export class TaskReportDataComponent {

  constructor(

    ){
  }
  @Input()
  title: string = 'Report';
  @Input()
  quantity: number = 0;
  @Input()
  content: any = [];
  @Input()
  headers: any = ["Task", "Start date", "Status", "Assignated to"];

  canDeleteFolders=["ADMIN", "SUPERADMIN"]
  allowedToDeleFolder=false
  user_details:any={}
  success=true
  
}
