import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessUnitsService } from '@core/services/home/business_units/business_units.service';
import { FoldersService } from '@core/services/home/folders/folders.service';
import { GetUserDetail, GetUserId } from '@core/utils';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel'; 
import { lastValueFrom, tap } from 'rxjs';

@Component({
  selector: 'app-business-unit-card',
  templateUrl: './business-unit-card.component.html',
  styleUrls: ['./business-unit-card.component.scss'],
  providers: [MessageService, ConfirmationService]

})
export class BusinessUnitCardComponent {

  folder_id=""
  constructor(
    private router: Router,
    private businessUnitsService: BusinessUnitsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
    ){
      this.checkUserPermissions()
  }
  @Input()
  _id: string = '';
  @Input()
  is_active: boolean = true;
  @Input()
  created_at: string = '';
  @Input()
  name: string = '';

  canDeleteBusinessUnits=["nadie"]
  // canDeleteBusinessUnits=["ADMIN", "SUPERADMIN"]
  allowedToDeleBusinessUnit=false
  user_details:any={}
  success=true
  
  async deleteFolder(){
    try {
      await lastValueFrom(this.businessUnitsService.deleteBusinessUnit(this._id, {user: this.user_details._id}).pipe(
        (tap( response => {
        }, error => {
          this.success=false
        }
        ))
          ))
    } catch (error) {
      this.success=false
    }

  }

  checkUserPermissions(){
    this.user_details = GetUserDetail()
    if (this.canDeleteBusinessUnits.includes(this.user_details.tipo)) {
      this.allowedToDeleBusinessUnit=true
    }
  }

  clickTrash(){
    this.confirmationService.confirm({
      accept: async () => {
        this.success=true
        await this.deleteFolder()
        this.changesUpdatesPopUp()
        
      },
  });
  }
  changesUpdatesPopUp(){
    setTimeout( () => {
      if (this.success) {
        window.location.reload();
      } else {
        this.messageService.add({ severity: 'success', summary: 'Fail', detail: 'Folder could not be deleted' });
      }
    }, 300);
  }

  goToBusinessUnit(){
    let businessUnitValues = {
      _id: this._id,
      name: this.name
    }
    this.businessUnitsService.saveBusinessUnitValuesOnLocalStorage(businessUnitValues)
    this.router.navigate([`clients`,this._id]);
    
  }
}
