import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SetToken, SetUserDetail, SetUserId } from '@core/utils';

@Component({
  selector: 'app-redirectv6',
  templateUrl: './redirectv6.component.html',
  styleUrls: ['./redirectv6.component.scss']
})
export class Redirectv6Component implements OnInit {
  token=""
  userId=""
  constructor(private route:ActivatedRoute, private router: Router,){
    this.token=this.route.snapshot.paramMap.get("token")||""
    this.userId=this.route.snapshot.paramMap.get("userId")||""
  }

  fixToken (urlToken:string){
    const splittedToken= urlToken.split('PUNTO');
      let token = ""
      for (let index = 0; index < splittedToken.length; index++) {
        if ((splittedToken.length-1)==index) {
          
          token += splittedToken[index];
        } else {
          token += splittedToken[index]+".";
        }
        
      }
    return token;
  }

  ngOnInit(): void {
    SetToken(this.fixToken(this.token))
    SetUserId(this.userId)
    SetUserDetail({})
    this.router.navigate([`selection-groups`]);
  }
}
  //http://localhost:4200/redirect-v6/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InRpcG8iOiJBZG1pbmlzdHJhZG9yIiwiX2lkIjoiNjJlOWZiYzBiMTNkOTIwMDExODI1YzkzIiwibm9tYnJlIjoiSW1hZ2luZSIsImFwZWxsaWRvIjoiQXBwcyIsImVtYWlsIjoianVhbi5hcmRpbGFAaW1hZ2luZWFwcHMuY28iLCJwYXNzd29yZCI6IiQyYSQxMCRFMkFad1pQNGJtd2E2Z09iTnN3eEh1OFVtSm45b09vZ3hONkVldFdCdThFUEdCUldaZUtzeSIsImNyZWF0ZWRBdCI6IjIwMjItMDgtMDNUMDQ6Mzg6MjQuMTg4WiIsIl9fdiI6MH0sImlhdCI6MTY5OTg0MzgyNX0.7OoDc8iLkPzAVDTTVgvVtoa1o-TubUpLGgIJKjHISUE/62e9fbc0b13d920011825c93
