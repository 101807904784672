import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DatePipe } from '@angular/common';
import { lastValueFrom, tap } from 'rxjs';
import { ProjectBriefService } from '@core/services/home/project-brief/projectBrief.service';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss'],
})
export class FilterPopupComponent implements OnInit {

  useFor="all-folders"

  datepipe: DatePipe = new DatePipe('en-US')

  filterValues = {
    status: {
      active:true,
      inactive:true,
      // completed:true,
      // in_progress:true
    },
    steps:{
      macroeconomics:true,
      r_and_d:true,
      packaging:true,
      processes:true,
      allies:true,
      excess:true,
      cost_feasibility:true
    },
    date_created:"",
    dead_line:"",
    executive:"",
    bussines_unit:""
  }
  selectedExecutive=""

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private projectBriefService: ProjectBriefService
  ) {
      this.useFor = this.config.data.useFor
      if(this.config.data.usedFilters!=undefined){
        this.filterValues = this.config.data.usedFilters
      }
      // console.log(this.useFor)
      // console.log(this.config.data.usedFilters)
  }

  

  executiveOptions:any = []

  businessUnitOptions=[
    "New clients", 
    "Actual clients", 
    "Luker Colombia", 
    "Portfolio"
  ]
  
ngOnInit(): void {
  
  this.getExecutiveUsersFromAPI()
}

  async getExecutiveUsersFromAPI(){
    return await lastValueFrom(this.projectBriefService.getExecutiveUsers().pipe(
      (tap( response => {
        let foundUser=false
        this.executiveOptions = response
        
        for (let index = 0; index < this.executiveOptions.length; index++) {
          const element = this.executiveOptions[index];
          if(element._id==this.filterValues.executive){
            this.selectedExecutive=element.nombre+" "+element.apellido
            foundUser=true
          }
          if(!foundUser){
            this.selectedExecutive = ""
          }
      }
    }

      ))
        ))

  }

  applyFilters(){
    let dateParam=""
    let deadlineParam=""
    let isActiveParam=""
    let executiveParam=""
    let bussinesUnitParam=""
    let queryParamsString=""

    isActiveParam=(this.filterValues.status.active==true&&this.filterValues.status.inactive==false)?"true":
    (this.filterValues.status.active==false&&this.filterValues.status.inactive==true)?"false":
    "both"

    queryParamsString="?is_active="+isActiveParam
    
    if(this.filterValues.dead_line[0]!=undefined){
      let startDeadline = this.datepipe.transform(this.filterValues.dead_line[0], 'YYYY-MM-dd')
      let endDeadline = this.filterValues.dead_line[1]==null?this.datepipe.transform(new Date(), 'YYYY-MM-dd'):this.datepipe.transform(this.filterValues.dead_line[1], 'YYYY-MM-dd')
      deadlineParam=startDeadline+(endDeadline==""?"":("_"+endDeadline))
      queryParamsString=queryParamsString+"&deadline="+deadlineParam
    }

    if(this.filterValues.date_created[0]!=undefined){
      let startDateCreated = this.datepipe.transform(this.filterValues.date_created[0], 'YYYY-MM-dd')
      let endDateCreated = this.filterValues.date_created[1]==null?this.datepipe.transform(new Date(), 'YYYY-MM-dd'):this.datepipe.transform(this.filterValues.date_created[1], 'YYYY-MM-dd')
      dateParam=startDateCreated+(endDateCreated==""?"":("_"+endDateCreated))
      queryParamsString=queryParamsString+"&date="+dateParam
    }


    executiveParam=this.filterValues.executive
    if (executiveParam!="") {
      queryParamsString=queryParamsString+"&executive="+executiveParam
    }
    
    bussinesUnitParam=this.filterValues.bussines_unit
    if (bussinesUnitParam!="") {
      queryParamsString=queryParamsString+"&bussines_unit="+bussinesUnitParam
    }

    if (this.useFor=="inside-folder") {
      if (this.filterValues.steps.macroeconomics==false) {
        queryParamsString=queryParamsString+"&macroeconomics=false"
      }
      if (this.filterValues.steps.r_and_d==false) {
        queryParamsString=queryParamsString+"&rnd=false"
      }
      if (this.filterValues.steps.packaging==false) {
        queryParamsString=queryParamsString+"&packaging=false"
      }
      if (this.filterValues.steps.processes==false) {
        queryParamsString=queryParamsString+"&process=false"
      }
      if (this.filterValues.steps.allies==false) {
        queryParamsString=queryParamsString+"&allies=false"
      }
      if (this.filterValues.steps.excess==false) {
        queryParamsString=queryParamsString+"&excess=false"
      }
      if (this.filterValues.steps.cost_feasibility==false) {
        queryParamsString=queryParamsString+"&cost_feasibility=false"
      }
    }
      // console.log(queryParamsString, "params")

      this.ref.close({queryParams: queryParamsString, usedFilters: this.filterValues})
  }

  updateExecutive(){
    let foundUser=false
    for (let index = 0; index < this.executiveOptions.length; index++) {
      const element = this.executiveOptions[index];
      if(element._id==this.filterValues.executive){
        this.selectedExecutive=element.nombre+" "+element.apellido
        foundUser=true
      }
    }
      if(!foundUser){
        this.selectedExecutive = ""
    }
  }

  resetFilters(){
    this.filterValues = {
      status: {
        active:true,
        inactive:true,
        // completed:true,
        // in_progress:true
      },
      steps:{
        macroeconomics:true,
        r_and_d:true,
        packaging:true,
        processes:true,
        allies:true,
        excess:true,
        cost_feasibility:true
      },
      date_created:"",
      dead_line:"",
      executive:"",
      bussines_unit:""
    }
  }

}
