import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
// import { getMacroeconomicsValuesOnLocal, saveMacroeconomicsValuesOnLocal } from '@core/utils/local-storage-data';
import { HttpClient } from '@angular/common/http';
import { getPackagingValuesOnLocal, savePackagingValuesOnLocal } from '@core/utils/local-storage-data';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class PackagingService {
  constructor(private http: HttpClient,) {
    
  }

  savePackaging(data:any, params:any): Observable<any>  {
    return this.http.post(`${baseUrl}/api/packaging`, data, {params: params});
  }

  updatePackaging(data:any, projectID:any, params:any): Observable<any>  {
    return this.http.put(`${baseUrl}/api/packaging/${projectID}`, data, {params: params});
  }

  getShapes(): Observable<any>  {
    return this.http.get(`${baseUrl}/api/forma`);
  }

  getCombinacionEmpaque(): Observable<any>  {
    return this.http.get(`${baseUrl}/api/combinacion_empaque`);
  }

  //retrieve packaging by filter
  filterPackaging(filter:string): Observable<any>  {
    return this.http.get(`${baseUrl}/api/registros_info_empaque/filter?txt_material=${filter}`);
  }

  //retrieve packaging info by sap code
  getMaterialgBySapCode(sapCode:string): Observable<any>  {
    return this.http.get(`${baseUrl}/api/registros_info_empaque/material/${sapCode}`);
  }
  getExtraMaterialInfoBySapCode(material_reg:string): Observable<any>  {
    return this.http.get(`${baseUrl}/api/registros_info/material_reg/${material_reg}`);
  }

  getBySapCode(sapCode:String){
    return this.http.get(`${baseUrl}/api/empaque/sap?codigoSap=${sapCode}`);
  
  }


  getPackagingValuesOnLocalStorage(){
    return getPackagingValuesOnLocal();
  }
  savePackagingValuesOnLocalStorage(packagingValue:any){
    return savePackagingValuesOnLocal(packagingValue);
  }

  getPackagingInfo(projectId:any):  Observable<any> {
    return this.http.get(`${baseUrl}/api/packaging/${projectId}`);

    // let emptymock = new BehaviorSubject<any>(
    //   {}
    // )
    //   return emptymock.asObservable()
  }

  getUsers(){
    return this.http.get(`${baseUrl}/api/usertype?types=EMPAQUES_ADMIN_SUPERADMIN`);
    // return this.http.get(`${baseUrl}/api/users`);
  }

  savePackagingVersionInfo(versionId:any, data:any){
    return this.http.put(`${baseUrl}/api/project_brief_version/${versionId}`, data);
  }

}
