import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StageCardComponent } from './components/stage-card/stage-card.component';
import { ProjectSummaryComponent } from './components/project-summary/project-summary.component';
import { HeaderContentComponent } from './components/header-content/header-content.component';
import { TableComponent } from './components/table/table.component';
import { TableModule } from 'primeng/table';
import { FormTableComponent } from './components/form-table/form-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormSelectTableComponent } from './components/form-select-table/form-select-table.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormSelectTableExcessComponent } from './components/form-select-table-excess/form-select-table.component';
import { HeaderComponent } from './components/header/header.component';
import { RandDTableComponent } from './components/rand-d-table/rand-d-table.component';
import { FilterMaterialsComponent } from './components/filter-materials/filter-materials.component';
import { ViewMaterialInfoComponent } from './components/view-material-info/view-material-info.component';
import { FormTableForBulkingComponent } from './components/form-table-for-bulking/form-table-for-bulking.component';
import { CreatePackagingTableComponent } from './components/create-packaging-table/create-packaging-table.component';
import { OnlyNumberDirective } from './directives/onlyNumbers.directive';
import { FolderCardComponent } from './components/folder-card/folder-card.component';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { StopEventPropagation } from './directives/stopEventPropagation.directive';
import { FilterPopupComponent } from './components/filter-popup/filter-popup.component';
import { CreateFolderPopupComponent } from './components/create-folder-popup/create-folder-popup.component';
import { InputTextModule } from 'primeng/inputtext';
import { ProjectCardComponent } from './components/project-card/project-card.component';
import { DividerModule } from 'primeng/divider';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CopyLinkComponent } from './components/copy-link/copy-link.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ProjectRedirectComponent } from './components/project-redirect/project-redirect.component';
import { CreateVersionPopupComponent } from './components/create-version-popup/create-version-popup.component';
import { VersionInfoComponent } from './components/version-info/version-info.component';
import { TwoDigitDecimaNumberDirective } from './directives/onlyTwoDecimalNumbers.directive';
import { ThreeDigitDecimaNumberDirective } from './directives/onlyThreeDecimalNumbers.directive';
import { NotificationPopupComponent } from './components/notification-popup/notification-popup.component';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { StageCardMobileComponent } from './components/stage-card-mobile/stage-card-mobile.component';
import { ShapeCardComponent } from './components/shape-card/shape-card.component';
import { PackagingCardComponent } from './components/packaging-card/packaging-card.component';
import { ViewProjectsExcessesComponent } from './components/excesses-projects/view-projects-excesses.component';
import { BusinessUnitCardComponent } from './components/business-unit-card/business-unit-card.component';
import { GroupCardComponent } from './components/group-card/group-card.component';
import { TaskReportDataComponent } from './components/task-report-data/task-report-data.component';
import { ClientCardComponent } from './components/client-card/client-card.component';
import { ClientsModule } from '@pages/clients/clients.module';
import { MacroeconomicsAccordionComponent } from './components/macroeconomics-accordion/macroeconomics-accordion.component';

@NgModule({
  declarations: [
    StageCardComponent,
    StageCardMobileComponent,
    ProjectSummaryComponent,
    HeaderContentComponent,
    TableComponent,
    FormTableComponent,
    FormSelectTableComponent,
    FormSelectTableExcessComponent,
    HeaderComponent,
    RandDTableComponent,
    FilterMaterialsComponent,
    ViewMaterialInfoComponent,
    FormTableForBulkingComponent,
    CreatePackagingTableComponent,
    TwoDigitDecimaNumberDirective,
    ThreeDigitDecimaNumberDirective,
    OnlyNumberDirective,
    FolderCardComponent,
    StopEventPropagation,
    FilterPopupComponent,
    CreateFolderPopupComponent,
    ProjectCardComponent,
    CopyLinkComponent,
    ProjectRedirectComponent,
    CreateVersionPopupComponent,
    VersionInfoComponent,
    NotificationPopupComponent,
    ShapeCardComponent,
    PackagingCardComponent,
    ViewProjectsExcessesComponent,
    BusinessUnitCardComponent,
    GroupCardComponent,
    TaskReportDataComponent,
    ClientCardComponent,
    MacroeconomicsAccordionComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,
    RouterModule,
    DropdownModule,
    FormsModule,
    CardModule,
    ButtonModule,
    InputTextModule,
    DividerModule,
    AccordionModule,
    CheckboxModule,
    CalendarModule,
    OverlayPanelModule,
    ClipboardModule,
    DialogModule,
    ToastModule,
    ConfirmDialogModule,
    TooltipModule,
    ProgressSpinnerModule

  ],
  exports: [
    StageCardComponent,
    StageCardMobileComponent,
    ProjectSummaryComponent,
    HeaderContentComponent,
    TableComponent,
    FormTableComponent,
    FormSelectTableComponent,
    FormSelectTableExcessComponent,
    HeaderComponent,
    RandDTableComponent,
    FilterMaterialsComponent,
    ViewMaterialInfoComponent,
    FormTableForBulkingComponent,
    CreatePackagingTableComponent,
    ThreeDigitDecimaNumberDirective,
    TwoDigitDecimaNumberDirective,
    OnlyNumberDirective,
    FolderCardComponent,
    ProjectCardComponent,
    CopyLinkComponent,
    VersionInfoComponent,
    FilterPopupComponent,
    NotificationPopupComponent,
    ShapeCardComponent,
    PackagingCardComponent,
    BusinessUnitCardComponent,
    GroupCardComponent,
    TaskReportDataComponent,
    ClientCardComponent,
    MacroeconomicsAccordionComponent,
  ],
})
export class SharedModule {}
