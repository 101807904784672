<div class="container__macroeconomics">
    <app-notification-popup *ngIf="this.success" [success]="true"></app-notification-popup>
    <app-notification-popup *ngIf="!this.success" [success]="false"></app-notification-popup>
    <p-accordion styleClass="accordion-info" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up" #accordion>
        <p-accordionTab [selected]="false" iconPos="end">
            <ng-template pTemplate="header" >
                Macroeconomics
            </ng-template>
            <div class="container__content">
                <p class="container__sesion-title">Currencies</p>
                <form [formGroup]="currenciesForm" id="container__form-base-info" action="">
                    <div class="flex flex-wrap justify-between lg:flex-nowrap">
                        <div class="w-full field-left mb-3">
                            <label class="currency" htmlFor="dolar">Dolar</label>
                            <p-dropdown *ngIf="!otherDollarPrice" formControlName="dolar" [placeholder]="'Select a dolar price'" optionLabel="label" optionValue="price" [options]="dolarValues">
                            </p-dropdown>
                            <input *ngIf="otherDollarPrice" appTwoDigitDecimaNumber type="text" min="0" [formControlName]="'dolar'" pInputText id="dolar" aria-describedby="dolar-help"
                            placeholder="Write here" />
                            <div (click)="changeOtherDollarPrice()" class="container__other-price">
                                <small>Enter Other Price</small>
                            </div>
                        </div>
                        <div class="w-full field-center mb-3">
                            <label htmlFor="euro">Euro</label>
                            <p-dropdown  *ngIf="!otherEuroPrice" formControlName="euro" placeholder="Select an euro price" optionLabel="label" optionValue="price" [options]="euroValues" >
                            </p-dropdown>
                            <input *ngIf="otherEuroPrice" appTwoDigitDecimaNumber type="text" min="0" [formControlName]="'euro'" pInputText id="euro" aria-describedby="dolar-help"
                            placeholder="Write here" />
                            <div (click)="changeOtherEuroPrice()" class="container__other-price">
                                <small>Enter Other Price</small>
                            </div>
                        </div>
                        <div class="w-full field-right">
                            <label htmlFor="pound">Pound</label>
                            <p-dropdown *ngIf="!otherPoundPrice" formControlName="pound" placeholder="Select a pound price" [options]="poundValues" optionLabel="label" optionValue="price">
                            </p-dropdown>
                            <input *ngIf="otherPoundPrice" appTwoDigitDecimaNumber type="text" min="0" [formControlName]="'pound'" pInputText id="clientname" aria-describedby="username-help"
                            placeholder="Write here" />
                            <div class="container__other-price">
                                <small>Enter Other Price</small>
                            </div>
                        </div>
                    </div>
                </form>
                <p class="container__sesion-title">Organic</p>
                <div class="container__radio-Buttons">
                    <input type="radio" id="yes" value="Yes" (click)="changeIsOrganicValue(true)" [checked]="macroeconomicsValues.organic"/>
                    <label for="yes">Yes</label>
                    <input type="radio" id="no" value="No" (click)="changeIsOrganicValue(false)" [checked]="!macroeconomicsValues.organic"/>
                    <label for="no">No</label>
                    <Span *ngIf="macroeconomicsValues.organic">
                        &nbsp;&nbsp;+${{cacaoOrganicoPrice}}.
                    </Span>
                </div>
                <p class="container__sesion-title">Cocoa Prices</p>
                <form class="form__cocoa-prices" [formGroup]="pricesForm" id="container__form-base-info" action="">
                    <div class="flex flex-wrap justify-between lg:flex-nowrap">
                        <div class="w-full field-left-2">
                            <label  htmlFor="cocoaprice">Cocoa Price USD/Ton</label>
                            <p-dropdown *ngIf="!otherCocoaPrice" formControlName="cocoaPriceUSD_TON" [autoDisplayFirst]="false" value (ngModelChange)="updateCocoaPrices()" optionValue="price" optionLabel="label" [placeholder]="'Select a cocoa price'" [options]="cocoaValues">
                            </p-dropdown>
                            <input *ngIf="otherCocoaPrice" appTwoDigitDecimaNumber type="text" min="0" (ngModelChange)="updateCocoaPrices()" [formControlName]="'cocoaPriceUSD_TON'" pInputText id="clientname" aria-describedby="username-help"
                            placeholder="Write here" />
                            <div (click)="changeOtherCocoaPrice()" class="container__other-price-cocoa">
                                <small>Enter Other Price</small>
                            </div>
                        </div>
                        <div class="w-full field-right-2 ">
                            <label htmlFor="cocapremium">Cocoa Premium USD/Ton</label>
                            <input  *ngIf="!initial_all" class="cocoa-price" appTwoDigitDecimaNumber type="text" min="0" (ngModelChange)="updateCocoaPrices()" [formControlName]="'cocoaPremiumUSD_TON'" pInputText id="clientname" aria-describedby="username-help"
                                placeholder="Write here" />
                            <input  *ngIf="initial_all" class="cocoa-price" appTwoDigitDecimaNumber readonly="true" type="text" min="0" [formControlName]="'cocoaPremiumUSD_TON'" pInputText id="clientname" aria-describedby="username-help"
                                placeholder="Write here" />
                        </div>
                    </div>
                </form>
                <p class="container__sesion-title variables">Variables</p>
                <app-table [columns]="tableHeaders" [tableData]="tableData"></app-table>
                <div  *ngIf="canUpdate" class="container__button-save">
                    <p-button (onClick)="saveMacroeconomics()" label="Save" [disabled]="!(!initial_all && allowedToFill)"></p-button>
                </div>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>